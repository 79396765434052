import {
  Box,
  Button,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Global } from "../Global";
import { EmailParseRequest } from "../services/models/EmailParseRequest";
import { Services } from "../Services";
import { Email } from "../services/models/Email";
import { DisplayEmail } from "./DisplayEmail";
import { EmailParseResult } from "../services/models/EmailParseResult";

const ParseTool: React.FC = () => {
  const [from, setFrom] = useState("");
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [result, resultsToShow] = useState("");
  const [resultsPage, setResultsPage] = useState<string>("email");
  const [resultData, setResultData] = useState<Email | undefined>();
  const [emailParseResults, setEmailParseResult] = useState<
    EmailParseResult | undefined
  >();
  const [productName, setProductName] = useState<string[] | undefined>();

  const handleTest = () => {
    let parseRequest: EmailParseRequest = {
      mailboxName: Global.getGlobal().CurrentMailbox!,
      email: {
        subject: subject,
        body: body,
        from: { address: from },
      },
    };
    Services.testService
      .parseEmail(Global.getJwtToken()!, parseRequest)
      .then((res) => {
        resultsToShow(JSON.stringify(res, null, 2));
        setResultData(res.email);
        setEmailParseResult(res);
      });
  };

  function handleProductNameFetch(): void {
    Services.testService
      .getRandomProductNames(
        Global.getJwtToken()!,
        Global.getGlobal().CurrentMailbox!,
      )
      .then((res) => {
        setProductName(res);
      });
  }

  let replyEmail = resultData?.reply;

  return (
    <Box
      marginTop={"10px"}
      marginLeft={"10px"}
      sx={{ display: "flex", flexDirection: "row" }}
    >
      <Box
        sx={{
          width: "50%",
          paddingRight: "10px",
          borderRight: "1px solid black",
        }}
      >
        <Box sx={{ mb: 2 }}>
          <TextField
            label="From"
            variant="filled"
            sx={{ width: "100%" }}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Subject"
            variant="filled"
            sx={{ width: "100%" }}
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
        </Box>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Body"
            variant="filled"
            sx={{ width: "100%", border: "none" }}
            multiline
            rows={10}
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </Box>

        <Button
          sx={{ marginTop: "10px" }}
          variant="contained"
          color="primary"
          onClick={handleTest}
        >
          Test
        </Button>
        <Button
          variant="text"
          color="primary"
          onClick={handleProductNameFetch}
          sx={{ marginLeft: 2 }}
        >
          Fetch Random Product Names
        </Button>

        {productName && (
          <Box>
            <Typography variant="h5" gutterBottom>
              Random Product Names:
            </Typography>
            <Box
              component="pre"
              sx={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}
            >
              {productName.join(", ")}
            </Box>
          </Box>
        )}
      </Box>

      <Box sx={{ width: "50%", paddingLeft: "10px" }}>
        <Typography variant="h5" gutterBottom>
          Results:
        </Typography>

        <ToggleButtonGroup
          value={resultsPage}
          exclusive
          onChange={(_event: any, newValue: any) => setResultsPage(newValue)}
          aria-label="result type"
          sx={{ marginBottom: 2 }}
        >
          <ToggleButton value="email" aria-label="email">
            Email
          </ToggleButton>
          <ToggleButton value="data" aria-label="data">
            Data
          </ToggleButton>
        </ToggleButtonGroup>

        {resultsPage === "email" &&
          (replyEmail?.body || replyEmail?.htmlBody) && (
            <Box>
              {emailParseResults && (
                <>
                  <Typography>
                    Time Taken:{" "}
                    {emailParseResults.email?.timeTakenInSeconds?.toFixed(3)}{" "}
                    seconds
                  </Typography>
                  <Typography>
                    Order Total: ${" "}
                    {emailParseResults.email?.totalValue?.toFixed(2)}
                  </Typography>
                  <Typography>
                    Action Proposed: {emailParseResults.email?.emailStatus}
                  </Typography>
                  {emailParseResults.email?.cannotReplyReason &&
                    emailParseResults.email?.cannotReplyReason?.length > 0 && (
                      <>
                        <Typography>Issues:</Typography>

                        <Typography
                          sx={{
                            color: "#ff0000",
                            border: "1px solid #ff0000",
                            backgroundColor: "#f8e7e7",
                            textAlign: "left",
                          }}
                        >
                          {emailParseResults.email?.cannotReplyReason?.map(
                            (reason) => (
                              <>
                                <span key={reason}>- {reason}</span>
                                <br></br>
                              </>
                            ),
                          )}
                        </Typography>
                      </>
                    )}
                </>
              )}

              <Box sx={{ mb: 2 }}></Box>

              <DisplayEmail email={replyEmail!} isReply={true} />
            </Box>
          )}

        {resultsPage === "data" && (
          <Box
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              overflow: "auto",
              maxHeight: "700px",
            }}
          >
            {result}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ParseTool;
