/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PortalInfo,
  User,
} from '../models/index';
import {
    PortalInfoFromJSON,
    PortalInfoToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models/index';

export interface ChangeActiveStatusRequest {
    authorization: string;
    emailAddress: string;
    isActive: boolean;
}

export interface ChangeCompanyRequest {
    authorization: string;
    company: string;
}

export interface ChangePasswordRequest {
    email: string;
    password: string;
    newPassword: string;
}

export interface CreateUserRequest {
    authorization: string;
    email: string;
    password: string;
    company?: string;
    createCompanyIfDoesNotExist?: boolean;
}

export interface CreateUserDataRequest {
    authorization: string;
}

export interface DeleteUserRequest {
    authorization: string;
    emailAddress: string;
}

export interface GetMeRequest {
    authorization: string;
}

export interface GetUserCompanyRequest {
    authorization: string;
}

export interface GetUsersRequest {
    authorization: string;
    forMyCompany: boolean;
}

export interface LoginRequest {
    email: string;
    password: string;
}

export interface LostPasswordRequest {
    email: string;
}

export interface SaveUserRequest {
    authorization: string;
    user: User;
}

export interface UserExistsByEmailRequest {
    authorization: string;
    email: string;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     */
    async changeActiveStatusRaw(requestParameters: ChangeActiveStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling changeActiveStatus().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling changeActiveStatus().'
            );
        }

        if (requestParameters['isActive'] == null) {
            throw new runtime.RequiredError(
                'isActive',
                'Required parameter "isActive" was null or undefined when calling changeActiveStatus().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        if (requestParameters['isActive'] != null) {
            queryParameters['isActive'] = requestParameters['isActive'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/saveUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changeActiveStatus(authorization: string, emailAddress: string, isActive: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changeActiveStatusRaw({ authorization: authorization, emailAddress: emailAddress, isActive: isActive }, initOverrides);
    }

    /**
     */
    async changeCompanyRaw(requestParameters: ChangeCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling changeCompany().'
            );
        }

        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling changeCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/changeCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async changeCompany(authorization: string, company: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.changeCompanyRaw({ authorization: authorization, company: company }, initOverrides);
        return await response.value();
    }

    /**
     */
    async changePasswordRaw(requestParameters: ChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling changePassword().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling changePassword().'
            );
        }

        if (requestParameters['newPassword'] == null) {
            throw new runtime.RequiredError(
                'newPassword',
                'Required parameter "newPassword" was null or undefined when calling changePassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['newPassword'] != null) {
            queryParameters['newPassword'] = requestParameters['newPassword'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/User/changePassword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async changePassword(email: string, password: string, newPassword: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.changePasswordRaw({ email: email, password: password, newPassword: newPassword }, initOverrides);
    }

    /**
     */
    async createUserRaw(requestParameters: CreateUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling createUser().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling createUser().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling createUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        if (requestParameters['company'] != null) {
            queryParameters['company'] = requestParameters['company'];
        }

        if (requestParameters['createCompanyIfDoesNotExist'] != null) {
            queryParameters['createCompanyIfDoesNotExist'] = requestParameters['createCompanyIfDoesNotExist'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/createUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async createUser(authorization: string, email: string, password: string, company?: string, createCompanyIfDoesNotExist?: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserRaw({ authorization: authorization, email: email, password: password, company: company, createCompanyIfDoesNotExist: createCompanyIfDoesNotExist }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createUserDataRaw(requestParameters: CreateUserDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling createUserData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/createUserData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async createUserData(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.createUserDataRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteUserRaw(requestParameters: DeleteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling deleteUser().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling deleteUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/deleteUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteUser(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUserRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
    }

    /**
     */
    async getMeRaw(requestParameters: GetMeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getMe().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/getMe`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async getMe(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getMeRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getPortalInfoRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PortalInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/User/getPortalInfo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PortalInfoFromJSON(jsonValue));
    }

    /**
     */
    async getPortalInfo(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PortalInfo> {
        const response = await this.getPortalInfoRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getUserCompanyRaw(requestParameters: GetUserCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getUserCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/getUserCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async getUserCompany(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.getUserCompanyRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getUsersRaw(requestParameters: GetUsersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<User>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getUsers().'
            );
        }

        if (requestParameters['forMyCompany'] == null) {
            throw new runtime.RequiredError(
                'forMyCompany',
                'Required parameter "forMyCompany" was null or undefined when calling getUsers().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['forMyCompany'] != null) {
            queryParameters['forMyCompany'] = requestParameters['forMyCompany'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/getUsers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async getUsers(authorization: string, forMyCompany: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<User>> {
        const response = await this.getUsersRaw({ authorization: authorization, forMyCompany: forMyCompany }, initOverrides);
        return await response.value();
    }

    /**
     */
    async loginRaw(requestParameters: LoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling login().'
            );
        }

        if (requestParameters['password'] == null) {
            throw new runtime.RequiredError(
                'password',
                'Required parameter "password" was null or undefined when calling login().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        if (requestParameters['password'] != null) {
            queryParameters['password'] = requestParameters['password'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/User/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async login(email: string, password: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.loginRaw({ email: email, password: password }, initOverrides);
        return await response.value();
    }

    /**
     */
    async lostPasswordRaw(requestParameters: LostPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling lostPassword().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/User/lostPassword`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async lostPassword(email: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.lostPasswordRaw({ email: email }, initOverrides);
    }

    /**
     */
    async saveUserRaw(requestParameters: SaveUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling saveUser().'
            );
        }

        if (requestParameters['user'] == null) {
            throw new runtime.RequiredError(
                'user',
                'Required parameter "user" was null or undefined when calling saveUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/saveUser`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters['user']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveUser(authorization: string, user: User, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveUserRaw({ authorization: authorization, user: user }, initOverrides);
    }

    /**
     */
    async userExistsByEmailRaw(requestParameters: UserExistsByEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling userExistsByEmail().'
            );
        }

        if (requestParameters['email'] == null) {
            throw new runtime.RequiredError(
                'email',
                'Required parameter "email" was null or undefined when calling userExistsByEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['email'] != null) {
            queryParameters['email'] = requestParameters['email'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/User/userExistsByEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async userExistsByEmail(authorization: string, email: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.userExistsByEmailRaw({ authorization: authorization, email: email }, initOverrides);
        return await response.value();
    }

}
