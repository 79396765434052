// src/components/Charts/LineChart.tsx
import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { TimePeriod } from "../../services/models/TimePeriod";
import { EmailVolume } from "../../services/models/EmailVolume";
import { Typography, CardContent, Grid2 as Grid } from "@mui/material";
import "./LineChart.css";

interface LineChartProps {
  data: EmailVolume[] | undefined;
  width: number;
  height: number;
  timePeriod: TimePeriod;
  quoteValue: number;
}

const LineChart: React.FC<LineChartProps> = ({
  data,
  width,
  height,
  timePeriod,
  quoteValue,
}) => {
  const ref = useRef<SVGSVGElement | null>(null);
  const margin = 20;

  const chageTimeLabel = (label: string) => {
    if (timePeriod === TimePeriod.Day) {
      let hr = parseInt(label.slice(0, label.indexOf(":")));
      if (hr > 12) {
        return `${hr - 12} pm`;
      } else if (hr === 12) {
        return "12 pm";
      } else if (hr === 0) {
        return "12 am";
      }
      return `${hr} am`;
    } else if (timePeriod === TimePeriod.Year) {
      let months = [
        "",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Oug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      return months[parseInt(label)];
    } else if (timePeriod === TimePeriod.Week) {
      let weeks = ["", "Sun", "Mon", "Tue", "Wed", "Thr", "Fri", "Sat"];
      return weeks[parseInt(label)];
    } else {
      return label;
    }
  };

  useEffect(() => {
    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height);
    svg.selectAll("*").remove(); // Clear previous renders

    // Prepare the filtered data
    const filteredData = (data || []).map((d) => ({
      quoteValue: d.quoteValue ?? 0,
      label: d.label,
    })) as { quoteValue: number; label: string }[];

    const tickValues = (data || [])
      .filter((d) => d.showLabel)
      .map((d) => d.label) as string[];

    // Set up the scales for x and y axes
    const xScale = d3
      .scaleBand()
      .domain(filteredData.map((d) => d.label))
      .range([margin, width - margin])
      .padding(0.2);

    const yScale = d3
      .scaleLinear()
      .domain([0, d3.max(filteredData, (d) => d.quoteValue) || 1]) // Adjusted scale to fit all values
      .range([height - margin, margin]); // Inverted range for proper orientation

    // Define the line generator function
    const line = d3
      .line<{ quoteValue: number; label: string }>()
      .x((d) => xScale(d.label)! + xScale.bandwidth() / 2) // Center points within bands
      .y((d) => yScale(d.quoteValue));
    // .curve(d3.curveMonotoneX); // Smooth line

    // Append the path element for the line with animation
    svg
      .append("path")
      .datum(filteredData)
      .attr("fill", "none")
      .attr("stroke", "#6FB391")
      .attr("stroke-width", 2)
      .attr("d", line)
      .attr("stroke-dasharray", function () {
        const length = (this as SVGPathElement).getTotalLength();
        return `${length} ${length}`;
      })
      .attr("stroke-dashoffset", function () {
        return (this as SVGPathElement).getTotalLength();
      })
      .transition()
      .duration(1500)
      .ease(d3.easeCubic)
      .attr("stroke-dashoffset", 0);

    // Create tooltip
    const tooltip = d3
      .select("body")
      .append("div")
      .attr("class", "tooltip-line")
      .style("opacity", 0)
      .style("position", "absolute")
      .style("background-color", "white")
      .style("width", "64px")
      .style("margin", "5px")
      //   .style('border', '1px solid #ccc')
      .style("pointer-events", "none");

    // Add circles for each data point with tooltip functionality
    svg
      .selectAll("circle")
      .data(filteredData)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.label)! + xScale.bandwidth() / 2)
      .attr("cy", (d) => yScale(d.quoteValue))
      .attr("r", 5)
      .style("cursor", "pointer")
      .attr("fill", "#6FB391")
      .style("opacity", 0)
      .style("transition", "opacity 0.2s ease")
      .on("mouseover", (event, d) => {
        d3.select(event.currentTarget).style("opacity", 1);
        tooltip
          .style("opacity", 1)
          .html(
            `<div class="time">${chageTimeLabel(d.label)}</div><div class="value">$${d.quoteValue.toLocaleString()}</div>`,
          )
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mousemove", (event) => {
        tooltip
          .style("left", `${event.pageX + 10}px`)
          .style("top", `${event.pageY - 20}px`);
      })
      .on("mouseout", (event) => {
        d3.select(event.currentTarget).style("opacity", 0);
        tooltip.style("opacity", 0);
      });

    // Create X-axis
    svg
      .append("g")
      .attr("transform", `translate(0,${height - margin})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickValues(tickValues)
          .tickFormat((d) => chageTimeLabel(d) as string)
          .tickSize(0),
      )
      .selectAll("text")
      .style("text-anchor", "middle")
      .style("font-size", "10px");

    // svg.selectAll('path').style('display', 'none'); // Hide axis line

    // Cleanup tooltip on component unmount
    return () => {
      tooltip.remove();
    };
  }, [data, width, height, margin]);

  return (
    <CardContent>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid>
          <Typography variant="h6" gutterBottom>
            Total Quote Volumes
          </Typography>
          <svg ref={ref} />
        </Grid>
        <Grid padding={2} alignSelf={"end"} paddingBottom={4}>
          <Typography variant="h3" align="right" color="success">
            $ {quoteValue?.toLocaleString()}
          </Typography>
          <Typography variant="body2" align="right">
            {timePeriod === "Day"
              ? "today"
              : `this ${timePeriod.toLowerCase()}`}
          </Typography>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export default LineChart;
