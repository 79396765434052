export interface IReactComponent {
  forceUpdate(): void;
}

class ListenerRegistration {
  components: {
    name?: string;
    component: IReactComponent;
    properties?: string[];
  }[] = [];
  functions: {
    name?: string;
    function: (observed: object, property: string[] | undefined) => void;
    properties?: string[];
  }[] = [];
}

// todo, keep copy of old object, only send out updates if actual changes were made
export class StateManager {
  static delaySend: boolean = false;

  static readonly OBSERVERS_VALUE_NAME = "_____observers";
  private static informing: { o: object; properties: string[] }[] = [];

  static addObserver(
    observable: object,
    observer:
      | IReactComponent
      | ((observed: object, property: string[] | undefined) => void),
    properties: string[] = [],
    name: string | undefined = undefined,
  ): void {
    let observers = Reflect.get(
      observable,
      this.OBSERVERS_VALUE_NAME,
    ) as ListenerRegistration;

    if (!observers) {
      observers = new ListenerRegistration();
      Reflect.set(observable, this.OBSERVERS_VALUE_NAME, observers);
    }

    if (observer instanceof Function) {
      observers.functions.push({
        name: name,
        function: observer,
        properties: properties,
      });
    } else {
      observers.components.push({
        name: name,
        component: observer,
        properties: properties,
      });
    }
  }

  static removeObserverByName(observable: object, name: string) {
    let observers = Reflect.get(
      observable,
      this.OBSERVERS_VALUE_NAME,
    ) as ListenerRegistration;

    if (observers) {
      observers.functions = observers.functions.filter((f) => {
        return !f.name || f.name !== name;
      });
      observers.components = observers.components.filter((f) => {
        return !f.name || f.name !== name;
      });
    }
  }

  static removeObserver(
    observable: object,
    observer:
      | React.Component
      | ((observed: object, property: string[] | undefined) => void),
  ): void {
    let observers = Reflect.get(
      observable,
      this.OBSERVERS_VALUE_NAME,
    ) as ListenerRegistration;

    if (observers) {
      if (observer instanceof Function) {
        let index = -1;
        for (let l of observers.functions) {
          index++;
          if (l.function === observer) break;
        }
        if (index !== -1) observers.functions.splice(index, 1);
      } else {
        let index = -1;
        for (let l of observers.components) {
          index++;
          if (l.component === observer) break;
        }
        if (index !== -1) observers.components.splice(index, 1);
      }
    }
  }

  // todo.  stop double forcing.

  static isListening(
    listeningToProperties: string[],
    changedProperties: string[],
  ): boolean {
    for (let prop of listeningToProperties) {
      if (changedProperties.indexOf(prop) !== -1) return true;
    }

    return false;
  }

  static internalTriggerUpdate(
    o: object,
    properties: string[] | undefined = undefined,
  ) {
    let observable = o;
    let forProperties = properties;

    if (this.isInforming(observable, forProperties)) {
      return;
    }

    if (this.delaySend) {
      setTimeout(() => {
        this.sendEvents(observable);
      }, 10);
    } else {
      this.sendEvents(observable);
    }
  }

  // eslint-disable-next-line
  static isChanged(observable: object): boolean {
    // let oldStates = Reflect.get(observable, this.OLD_STATES_VALUE_NAME) as object[] ;
    //
    // if (!oldStates || oldStates.length === 0) {
    //     return true;
    // }
    //
    // return DataUtilities.compare(oldStates[oldStates.length -1], observable);
    //
    //
    // return true;
    return true;
  }

  static forceChange(observable: object) {
    StateManager.internalTriggerUpdate(observable);
  }

  static changed(observable: object) {
    if (this.isChanged(observable))
      StateManager.internalTriggerUpdate(observable);
  }

  static propertyChanged(observable: object, property: string) {
    if (this.isChanged(observable))
      StateManager.internalTriggerUpdate(observable, [property]);
  }

  static propertiesChanged(observable: object, properties: string[]) {
    if (this.isChanged(observable)) {
      StateManager.internalTriggerUpdate(observable, properties);
    }
  }

  private static isInforming(
    o: object,
    properties: string[] | undefined,
  ): boolean {
    let adjusted: boolean = false;
    for (let i of this.informing) {
      if (i.o === o) {
        adjusted = true;
        if (properties) {
          for (let p of properties) {
            if (p.length === 1) console.trace();
            if (i.properties?.indexOf(p) === -1) i.properties.push(p);
          }
        }
      }
    }

    if (properties === undefined) properties = [];

    if (!adjusted) {
      this.informing.push({ o: o, properties: properties });
    }

    return adjusted;
  }

  private static doneInforming(o: object): { o: object; properties: string[] } {
    let working: { o: object; properties: string[] } | undefined;

    this.informing = this.informing.filter((i) => {
      if (i.o === o) {
        working = i;
      }

      return o !== i.o;
    });

    return working!;
  }

  private static sendEvents(observable: object) {
    let inform = this.doneInforming(observable);

    let observers = Reflect.get(
      inform.o,
      this.OBSERVERS_VALUE_NAME,
    ) as ListenerRegistration;

    if (observers) {
      for (let l of observers.components) {
        // checking property listeners.
        if (
          inform.properties.length !== 0 &&
          l.properties &&
          l.properties.length !== 0 &&
          this.isListening(l.properties, inform.properties)
        ) {
          l.component.forceUpdate();
        }

        // call general observers, always.
        if (!l.properties || l.properties.length === 0) {
          l.component.forceUpdate();
        }
      }

      for (let l of observers.functions) {
        // checking property listeners.
        if (
          inform.properties.length !== 0 &&
          l.properties &&
          l.properties.length !== 0 &&
          this.isListening(l.properties, inform.properties)
        ) {
          l.function(inform.o, inform.properties);
        }

        // call general observers, always.
        if (!l.properties || l.properties.length === 0) {
          l.function(inform.o, inform.properties);
        }
      }
    }
  }
}

export const action = (
  target: Object,
  propertyKey: string,
  descriptor: PropertyDescriptor,
) => {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args: any[]) {
    let retVal = originalMethod.apply(...args);
    StateManager.changed(target);
    return retVal;
  };
};
