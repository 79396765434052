import React from "react";
import { Email } from "../../../services/models/Email";
import EmailMainPart from "../../../components/EmailMainPart";
import { Box, Typography } from "@mui/material";
import "./layers.css";

interface IgnoredProps {
  emailData: Email;
  onClose: () => void;
}

function Ignored(props: IgnoredProps) {
  return (
    <Box className="layers-container">
      <EmailMainPart email={props.emailData} onClose={props.onClose} />

      <Box className="bottom-layer">
        <Typography className="bottom-title">Deleted</Typography>
        <Typography className="bottom-content">
          Deleted By: <span>{props.emailData.modifiedBy}</span>
        </Typography>
      </Box>
    </Box>
  );
}

export default Ignored;
