/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductData } from './ProductData';
import {
    ProductDataFromJSON,
    ProductDataFromJSONTyped,
    ProductDataToJSON,
} from './ProductData';
import type { ProductInfo } from './ProductInfo';
import {
    ProductInfoFromJSON,
    ProductInfoFromJSONTyped,
    ProductInfoToJSON,
} from './ProductInfo';

/**
 * 
 * @export
 * @interface EmailDebugInfo
 */
export interface EmailDebugInfo {
    /**
     * 
     * @type {Array<ProductData>}
     * @memberof EmailDebugInfo
     */
    erpLookupResult: Array<ProductData>;
    /**
     * 
     * @type {Array<ProductInfo>}
     * @memberof EmailDebugInfo
     */
    emailParseResults: Array<ProductInfo>;
}

/**
 * Check if a given object implements the EmailDebugInfo interface.
 */
export function instanceOfEmailDebugInfo(value: object): value is EmailDebugInfo {
    if (!('erpLookupResult' in value) || value['erpLookupResult'] === undefined) return false;
    if (!('emailParseResults' in value) || value['emailParseResults'] === undefined) return false;
    return true;
}

export function EmailDebugInfoFromJSON(json: any): EmailDebugInfo {
    return EmailDebugInfoFromJSONTyped(json, false);
}

export function EmailDebugInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailDebugInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'erpLookupResult': ((json['erpLookupResult'] as Array<any>).map(ProductDataFromJSON)),
        'emailParseResults': ((json['emailParseResults'] as Array<any>).map(ProductInfoFromJSON)),
    };
}

export function EmailDebugInfoToJSON(value?: EmailDebugInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'erpLookupResult': ((value['erpLookupResult'] as Array<any>).map(ProductDataToJSON)),
        'emailParseResults': ((value['emailParseResults'] as Array<any>).map(ProductInfoToJSON)),
    };
}

