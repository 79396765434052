/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Company,
} from '../models/index';
import {
    CompanyFromJSON,
    CompanyToJSON,
} from '../models/index';

export interface AddCompanyRequest {
    authorization: string;
    companyName: string;
}

export interface AddUserToCompanyRequest {
    authorization: string;
    emailAddress: string;
    companyName: string;
}

export interface CreateCompanyDataRequest {
    authorization: string;
}

export interface DeleteCompanyRequest {
    authorization: string;
    companyName: string;
}

export interface GetAllCompaniesRequest {
    authorization: string;
}

export interface GetCompaniesForUserRequest {
    authorization: string;
    emailAddress: string;
}

export interface GetMyCompaniesRequest {
    authorization: string;
}

export interface RemoveUserFromCompanyRequest {
    authorization: string;
    emailAddress: string;
    companyName: string;
}

export interface SaveCompanyRequest {
    authorization: string;
    company: Company;
}

/**
 * 
 */
export class CompanyApi extends runtime.BaseAPI {

    /**
     */
    async addCompanyRaw(requestParameters: AddCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling addCompany().'
            );
        }

        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling addCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyName'] != null) {
            queryParameters['companyName'] = requestParameters['companyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/addCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addCompany(authorization: string, companyName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addCompanyRaw({ authorization: authorization, companyName: companyName }, initOverrides);
    }

    /**
     */
    async addUserToCompanyRaw(requestParameters: AddUserToCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling addUserToCompany().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling addUserToCompany().'
            );
        }

        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling addUserToCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        if (requestParameters['companyName'] != null) {
            queryParameters['companyName'] = requestParameters['companyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/addUserToCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async addUserToCompany(authorization: string, emailAddress: string, companyName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.addUserToCompanyRaw({ authorization: authorization, emailAddress: emailAddress, companyName: companyName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createCompanyDataRaw(requestParameters: CreateCompanyDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Company>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling createCompanyData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/createCompanyData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CompanyFromJSON(jsonValue));
    }

    /**
     */
    async createCompanyData(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Company> {
        const response = await this.createCompanyDataRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCompanyRaw(requestParameters: DeleteCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling deleteCompany().'
            );
        }

        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling deleteCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['companyName'] != null) {
            queryParameters['companyName'] = requestParameters['companyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/deleteCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteCompany(authorization: string, companyName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCompanyRaw({ authorization: authorization, companyName: companyName }, initOverrides);
    }

    /**
     */
    async getAllCompaniesRaw(requestParameters: GetAllCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Company>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getAllCompanies().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/getAllCompanies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CompanyFromJSON));
    }

    /**
     */
    async getAllCompanies(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Company>> {
        const response = await this.getAllCompaniesRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getCompaniesForUserRaw(requestParameters: GetCompaniesForUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getCompaniesForUser().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling getCompaniesForUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/getCompaniesForUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getCompaniesForUser(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getCompaniesForUserRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMyCompaniesRaw(requestParameters: GetMyCompaniesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getMyCompanies().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/getMyCompanies`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getMyCompanies(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getMyCompaniesRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async removeUserFromCompanyRaw(requestParameters: RemoveUserFromCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling removeUserFromCompany().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling removeUserFromCompany().'
            );
        }

        if (requestParameters['companyName'] == null) {
            throw new runtime.RequiredError(
                'companyName',
                'Required parameter "companyName" was null or undefined when calling removeUserFromCompany().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        if (requestParameters['companyName'] != null) {
            queryParameters['companyName'] = requestParameters['companyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/removeUserFromCompany`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async removeUserFromCompany(authorization: string, emailAddress: string, companyName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.removeUserFromCompanyRaw({ authorization: authorization, emailAddress: emailAddress, companyName: companyName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveCompanyRaw(requestParameters: SaveCompanyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling saveCompany().'
            );
        }

        if (requestParameters['company'] == null) {
            throw new runtime.RequiredError(
                'company',
                'Required parameter "company" was null or undefined when calling saveCompany().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/Company/saveCompany`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompanyToJSON(requestParameters['company']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveCompany(authorization: string, company: Company, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveCompanyRaw({ authorization: authorization, company: company }, initOverrides);
    }

}
