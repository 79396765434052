/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { QuotePercentage } from './QuotePercentage';
import {
    QuotePercentageFromJSON,
    QuotePercentageFromJSONTyped,
    QuotePercentageToJSON,
} from './QuotePercentage';
import type { EmailVolume } from './EmailVolume';
import {
    EmailVolumeFromJSON,
    EmailVolumeFromJSONTyped,
    EmailVolumeToJSON,
} from './EmailVolume';

/**
 * 
 * @export
 * @interface DashboardResult
 */
export interface DashboardResult {
    /**
     * 
     * @type {number}
     * @memberof DashboardResult
     */
    numberOfEmails?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResult
     */
    quoteValue?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResult
     */
    autoRespondTime?: number;
    /**
     * 
     * @type {number}
     * @memberof DashboardResult
     */
    manualRespondTime?: number;
    /**
     * 
     * @type {Array<QuotePercentage>}
     * @memberof DashboardResult
     */
    quotePercentage?: Array<QuotePercentage>;
    /**
     * 
     * @type {Array<EmailVolume>}
     * @memberof DashboardResult
     */
    emailVolume?: Array<EmailVolume>;
}

/**
 * Check if a given object implements the DashboardResult interface.
 */
export function instanceOfDashboardResult(value: object): value is DashboardResult {
    return true;
}

export function DashboardResultFromJSON(json: any): DashboardResult {
    return DashboardResultFromJSONTyped(json, false);
}

export function DashboardResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): DashboardResult {
    if (json == null) {
        return json;
    }
    return {
        
        'numberOfEmails': json['numberOfEmails'] == null ? undefined : json['numberOfEmails'],
        'quoteValue': json['quoteValue'] == null ? undefined : json['quoteValue'],
        'autoRespondTime': json['autoRespondTime'] == null ? undefined : json['autoRespondTime'],
        'manualRespondTime': json['manualRespondTime'] == null ? undefined : json['manualRespondTime'],
        'quotePercentage': json['quotePercentage'] == null ? undefined : ((json['quotePercentage'] as Array<any>).map(QuotePercentageFromJSON)),
        'emailVolume': json['emailVolume'] == null ? undefined : ((json['emailVolume'] as Array<any>).map(EmailVolumeFromJSON)),
    };
}

export function DashboardResultToJSON(value?: DashboardResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'numberOfEmails': value['numberOfEmails'],
        'quoteValue': value['quoteValue'],
        'autoRespondTime': value['autoRespondTime'],
        'manualRespondTime': value['manualRespondTime'],
        'quotePercentage': value['quotePercentage'] == null ? undefined : ((value['quotePercentage'] as Array<any>).map(QuotePercentageToJSON)),
        'emailVolume': value['emailVolume'] == null ? undefined : ((value['emailVolume'] as Array<any>).map(EmailVolumeToJSON)),
    };
}

