/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductInfo
 */
export interface ProductInfo {
    /**
     * 
     * @type {string}
     * @memberof ProductInfo
     */
    productName: string;
    /**
     * 
     * @type {string}
     * @memberof ProductInfo
     */
    productId: string;
    /**
     * 
     * @type {number}
     * @memberof ProductInfo
     */
    qty?: number;
    /**
     * 
     * @type {number}
     * @memberof ProductInfo
     */
    targetPrice?: number;
}

/**
 * Check if a given object implements the ProductInfo interface.
 */
export function instanceOfProductInfo(value: object): value is ProductInfo {
    if (!('productName' in value) || value['productName'] === undefined) return false;
    if (!('productId' in value) || value['productId'] === undefined) return false;
    return true;
}

export function ProductInfoFromJSON(json: any): ProductInfo {
    return ProductInfoFromJSONTyped(json, false);
}

export function ProductInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'productName': json['productName'],
        'productId': json['productId'],
        'qty': json['qty'] == null ? undefined : json['qty'],
        'targetPrice': json['targetPrice'] == null ? undefined : json['targetPrice'],
    };
}

export function ProductInfoToJSON(value?: ProductInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'productName': value['productName'],
        'productId': value['productId'],
        'qty': value['qty'],
        'targetPrice': value['targetPrice'],
    };
}

