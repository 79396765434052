import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Divider,
  CardContent,
} from "@mui/material";
import React, { useEffect, useRef, useState, useCallback } from "react";
// import React from "react";
import { Services } from "../Services";
import { Global } from "../Global";

import MailIcon from "@mui/icons-material/Mail";

import BarChart from "../components/charts/BarChart";
import LineChart from "../components/charts/LineChart";
import GaugeChart from "../components/charts/GaugeChart";
import PieChart from "../components/charts/PieChart";
import { DashboardResult } from "../services/models/DashboardResult";
import { TimePeriod } from "../services/models/TimePeriod";
import { MailBox } from "../services/models/MailBox";

const Dashboard: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<
    DashboardResult | undefined
  >();
  const [timePeriod, setTimePeriod] = useState<TimePeriod>(TimePeriod.Day);
  const [mailbox, setMailbox] = useState<string>("");
  const [allMailboxes, setAllMailboxes] = useState<string[]>([]);
  const [chartDimensions, setChartDimensions] = useState({
    width: 0,
    height: 0,
  });

  const chartContainerRef = useRef<HTMLDivElement>(null);

  // Function to calculate and set chart dimensions dynamically
  const calculateChartDimensions = useCallback(() => {
    let width = Math.floor((window.innerWidth - 250) * 0.25);
    let height = Math.floor((window.innerHeight - 150) * 0.125);
    
    setChartDimensions({
      width: width > 300 ? width : 300,
      height: height > 100 ? height : 100,
    });
    // setChartDimensions({
    //   width: width,
    //   height: height,
    // });
  }, [window.innerWidth, window.innerHeight]);

  const token = Global.getJwtToken();
  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setAllMailboxes(mailboxes.map((mailbox) => mailbox.emailAddress!));
      });
  }, [token]);

  useEffect(() => {
    Services.dashboardService
      .getDashboardData(Global.getJwtToken()!, timePeriod, mailbox)
      .then((data) => {
        setDashboardData(data);
      });
  }, [timePeriod, mailbox]);

  useEffect(() => {
    calculateChartDimensions(); // Initial calculation
    window.addEventListener("resize", calculateChartDimensions); // Recalculate on window resize
    return () => window.removeEventListener("resize", calculateChartDimensions); // Cleanup listener
  }, [calculateChartDimensions]);

  return (
    <Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"start"}
        // minHeight={"1176px"}
        // minWidth={"1334px"}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box sx={{ minWidth: 200, display: "flex", alignItems: "center" }}>
            <MailIcon
              sx={{
                marginRight: 1,
                marginLeft: 5,
                color: "#3A4188",
                alignItems: "baseline",
              }}
            />
            <FormControl
              variant="standard"
              sx={{ minWidth: 300, color: "#3A4188" }}
            >
              <InputLabel id="mailbox-select-label">Mailbox</InputLabel>
              <Select
                labelId="mailbox-select-label"
                id="mailbox-select"
                value={mailbox}
                label="Mailbox"
                onChange={(event) => setMailbox(event.target.value)}
              >
                {allMailboxes.map((mailbox) => (
                  <MenuItem key={mailbox} value={mailbox}>
                    {mailbox}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>

          <ToggleButtonGroup
            value={timePeriod}
            color="primary"
            exclusive
            onChange={(event, newTimePeriod) => {
              if (newTimePeriod !== null) {
                setTimePeriod(newTimePeriod);
              }
            }}
            aria-label="time period"
            sx={{ minWidth: 500, justifyContent: "stretch" }}
          >
            {Object.values(TimePeriod).map((period) => (
              <ToggleButton
                key={period}
                value={period}
                aria-label={period}
                sx={{
                  minWidth: 150,
                  border: "none",
                  "&.Mui-selected": {
                    backgroundColor: "#3A4188", // Custom selected background color
                    color: "#fff", // White text when selected
                    "&:hover": {
                      backgroundColor: "#3A2178", // Darker blue on hover
                    },
                  },
                }}
              >
                {period}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>

        <Divider></Divider>

        {dashboardData && (
          <Box
            display="flex"
            flexDirection="row"
            flexWrap={"wrap"}
            // minWidth={"1334px"}
          >
            <Box
              display="flex"
              flex={4}
              flexDirection="column"
              height={chartDimensions.height * 8}
              ref={chartContainerRef}
            >
              <Box flexGrow={1} alignContent={"center"}>
                <BarChart
                  data={dashboardData.emailVolume}
                  timePeriod={timePeriod}
                  numberOfEmails={dashboardData.numberOfEmails ?? 0}
                  width={chartDimensions.width * 1.5}
                  height={chartDimensions.height}
                ></BarChart>
              </Box>
              <Divider></Divider>
              <Box flexGrow={1} alignContent={"center"}>
                <LineChart
                  data={dashboardData.emailVolume}
                  timePeriod={timePeriod}
                  quoteValue={dashboardData.quoteValue ?? 0}
                  width={chartDimensions.width * 1.5}
                  height={chartDimensions.height}
                ></LineChart>
              </Box>
              <Divider></Divider>
              <Box flexGrow={2}>
                <Typography
                  variant="h3"
                  marginTop={4}
                  marginBottom={2}
                  align="center"
                >
                  Processing Time
                </Typography>
                <CardContent
                  sx={{ backgroundColor: "#F7F8F8", padding: "0px" }}
                >
                  <Box
                    display="flex"
                    justifyContent="space-around"
                  >
                    <GaugeChart
                      value={dashboardData.autoRespondTime ?? 0}
                      maxValue={10}
                      unit="seconds"
                      color="#4caf50"
                      width={chartDimensions.width}
                      height={chartDimensions.height * 2}
                      title={"Auto Respond"}
                    />
                    <GaugeChart
                      value={dashboardData.manualRespondTime ?? 0}
                      maxValue={3600}
                      unit="seconds"
                      color="#f44336"
                      width={chartDimensions.width}
                      height={chartDimensions.height * 2}
                      title={"Manual Respond"}
                    />
                  </Box>
                  <Divider></Divider>
                  <Typography
                    variant="caption"
                    display={"flex"}
                    letterSpacing={2}
                    sx={{ fontSize: "12px" }}
                    mt={1}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3 7H6M3 11H5M9.02 8.801L8.42 14.801C8.3922 15.079 8.42296 15.3598 8.51031 15.6252C8.59766 15.8906 8.73966 16.1348 8.92715 16.342C9.11464 16.5492 9.34346 16.7148 9.59886 16.8281C9.85426 16.9414 10.1306 17 10.41 17H18.39C18.886 17 19.3642 16.8157 19.732 16.4829C20.0997 16.1501 20.3307 15.6925 20.38 15.199L20.98 9.199C21.0078 8.92097 20.977 8.64019 20.8897 8.37478C20.8023 8.10936 20.6603 7.86519 20.4729 7.65801C20.2854 7.45083 20.0565 7.28524 19.8011 7.17191C19.5457 7.05857 19.2694 7.00001 18.99 7H11.01C10.514 7.00002 10.0358 7.18432 9.66803 7.51712C9.3003 7.84993 9.06935 8.30749 9.02 8.801Z"
                        stroke="#3A4188"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M9.80005 7.5L12.782 10.78C13.047 11.0715 13.3669 11.308 13.7233 11.476C14.0797 11.6439 14.4657 11.74 14.8592 11.7588C15.2528 11.7775 15.6461 11.7186 16.0169 11.5853C16.3876 11.452 16.7285 11.247 17.02 10.982L20.3 8"
                        stroke="#3A4188"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <b
                      style={{
                        color: "#3A4188",
                        paddingTop: "3px",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      {dashboardData.numberOfEmails?.toLocaleString()}
                    </b>{" "}
                    <span style={{ paddingTop: "3px" }}>
                      emails to respond
                    </span>
                  </Typography>
                </CardContent>
              </Box>
            </Box>
            <Divider orientation="vertical" flexItem></Divider>
            <Box flex={3}>
              <PieChart
                data={dashboardData.quotePercentage}
                width={chartDimensions.width * 1.7}
                height={chartDimensions.height * 4}
              />
            </Box>
          </Box>
      )}
      </Box>
    </Box>
  );
};

export default Dashboard;
