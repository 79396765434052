/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortalInfo
 */
export interface PortalInfo {
    /**
     * 
     * @type {string}
     * @memberof PortalInfo
     */
    portalMessage?: string;
    /**
     * 
     * @type {string}
     * @memberof PortalInfo
     */
    portalMessageColor?: string;
    /**
     * 
     * @type {string}
     * @memberof PortalInfo
     */
    version?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PortalInfo
     */
    showPortalMessage?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PortalInfo
     */
    environmentName?: string;
}

/**
 * Check if a given object implements the PortalInfo interface.
 */
export function instanceOfPortalInfo(value: object): value is PortalInfo {
    return true;
}

export function PortalInfoFromJSON(json: any): PortalInfo {
    return PortalInfoFromJSONTyped(json, false);
}

export function PortalInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortalInfo {
    if (json == null) {
        return json;
    }
    return {
        
        'portalMessage': json['portalMessage'] == null ? undefined : json['portalMessage'],
        'portalMessageColor': json['portalMessageColor'] == null ? undefined : json['portalMessageColor'],
        'version': json['version'] == null ? undefined : json['version'],
        'showPortalMessage': json['showPortalMessage'] == null ? undefined : json['showPortalMessage'],
        'environmentName': json['environmentName'] == null ? undefined : json['environmentName'],
    };
}

export function PortalInfoToJSON(value?: PortalInfo | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'portalMessage': value['portalMessage'],
        'portalMessageColor': value['portalMessageColor'],
        'version': value['version'],
        'showPortalMessage': value['showPortalMessage'],
        'environmentName': value['environmentName'],
    };
}

