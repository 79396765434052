/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ProductData } from './ProductData';
import {
    ProductDataFromJSON,
    ProductDataFromJSONTyped,
    ProductDataToJSON,
} from './ProductData';
import type { ProductInfo } from './ProductInfo';
import {
    ProductInfoFromJSON,
    ProductInfoFromJSONTyped,
    ProductInfoToJSON,
} from './ProductInfo';
import type { Email } from './Email';
import {
    EmailFromJSON,
    EmailFromJSONTyped,
    EmailToJSON,
} from './Email';

/**
 * 
 * @export
 * @interface EmailParseResult
 */
export interface EmailParseResult {
    /**
     * 
     * @type {Email}
     * @memberof EmailParseResult
     */
    email?: Email;
    /**
     * 
     * @type {Array<ProductInfo>}
     * @memberof EmailParseResult
     */
    productInfo?: Array<ProductInfo>;
    /**
     * 
     * @type {Array<ProductData>}
     * @memberof EmailParseResult
     */
    erpLookupResult?: Array<ProductData>;
}

/**
 * Check if a given object implements the EmailParseResult interface.
 */
export function instanceOfEmailParseResult(value: object): value is EmailParseResult {
    return true;
}

export function EmailParseResultFromJSON(json: any): EmailParseResult {
    return EmailParseResultFromJSONTyped(json, false);
}

export function EmailParseResultFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailParseResult {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'] == null ? undefined : EmailFromJSON(json['email']),
        'productInfo': json['productInfo'] == null ? undefined : ((json['productInfo'] as Array<any>).map(ProductInfoFromJSON)),
        'erpLookupResult': json['erpLookupResult'] == null ? undefined : ((json['erpLookupResult'] as Array<any>).map(ProductDataFromJSON)),
    };
}

export function EmailParseResultToJSON(value?: EmailParseResult | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'email': EmailToJSON(value['email']),
        'productInfo': value['productInfo'] == null ? undefined : ((value['productInfo'] as Array<any>).map(ProductInfoToJSON)),
        'erpLookupResult': value['erpLookupResult'] == null ? undefined : ((value['erpLookupResult'] as Array<any>).map(ProductDataToJSON)),
    };
}

