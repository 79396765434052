// src/components/Charts/BarChart.tsx
import React, { useEffect, useRef } from 'react';
import {
    Typography,
    CardContent,
    Grid2 as Grid,
} from "@mui/material";
import * as d3 from 'd3';
import { EmailVolume } from '../../services/models/EmailVolume';
import './BarChart.css';
import { TimePeriod } from '../../services/models/TimePeriod';

interface IBarChartProps {
    data: EmailVolume[] | undefined;
    width: number;
    height: number;
    numberOfEmails: number;
    timePeriod: TimePeriod;
}

const BarChart: React.FC<IBarChartProps> = ({ data, width, height, numberOfEmails, timePeriod }) => {
    const ref = useRef<SVGSVGElement | null>(null);
    const margin = 20;
    const chageTimeLabel = (label: string) => {
        if (timePeriod === TimePeriod.Day) {
            let hr = parseInt(label.slice(0, label.indexOf(':')));
            if (hr > 12) {
                return `${hr - 12} pm`;
            } else if (hr === 12) {
                return "12 pm";
            } else if (hr === 0) {
                return "12 am";
            }
            return `${hr} am`;
        } else if (timePeriod === TimePeriod.Year) {
            let months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Oug', 'Sep', 'Oct', 'Nov', 'Dec'];
            return months[parseInt(label)];
        } else if (timePeriod === TimePeriod.Week) {
            let weeks = ['', 'Sun', 'Mon', 'Tue', 'Wed', 'Thr', 'Fri', 'Sat'];
            return weeks[parseInt(label)];
        } else {
            return label;
        }
    }

    useEffect(() => {
        const svg = d3
            .select(ref.current)
            .attr('width', width)
            .attr('height', height);

        svg.selectAll('*').remove(); // Clear previous renders

        const filteredData = (data || []).map((d) => ({
            count: d.count ?? 0,
            label: d.label,
        })) as { count: number, label: string }[];

        const tickValues = (data || [])
            .filter((d) => d.showLabel)
            .map((d) => d.label) as string[];

        // Set up scales
        const xScale = d3
            .scaleBand()
            .domain(filteredData.map((d) => d.label))
            .range([margin, width - margin])
            .padding(0.5);

        const yScale = d3
            .scaleLinear()
            .domain([0, d3.max(filteredData, (d) => d.count) || 0])
            .nice()
            .range([height - margin, margin]);

        // Create a tooltip
        const tooltip = d3
            .select('body')
            .append('div')
            // .select('.tooltip')
            .attr('class', 'tooltip-bar')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('background-color', 'white')
            .style('width', '64px')
            .style('margin', '5px')
            //   .style('border', '1px solid #ccc')
            .style('pointer-events', 'none');

        // Render bars with animation
        const bars = svg
            .selectAll('.bar')
            .data(filteredData)
            .enter()
            .append('rect')
            .attr('class', 'bar')
            .attr('x', (d) => xScale(d.label)!)
            .attr('y', height - margin ) // Start bars at the bottom (for animation)
            .attr('width', xScale.bandwidth())
            .attr('height', 0) // Initial height 0 for animation
            .attr('fill', '#3f51b5')
            .style('cursor', 'pointer')
            .style('border-radius', '2px')
            .on('mouseover', (event, d) => {
                tooltip
                    .style('opacity', 1)
                    .html(`<div class="time">${chageTimeLabel(d.label)}</div><div class="value">${d.count.toLocaleString()}</div>`)
                    .style('left', `${event.pageX + 10}px`)
                    .style('top', `${event.pageY - 20}px`);
            })
            .on('mousemove', (event) => {
                tooltip
                    .style('left', `${event.pageX + 10}px`)
                    .style('top', `${event.pageY - 20}px`);
            })
            .on('mouseout', () => {
                tooltip.style('opacity', 0);
            });

        // Animate the bars growing to their final height
        bars
            .transition()
            .duration(800)
            .attr('y', (d) => yScale(d.count))
            .attr('height', (d) => height - margin - yScale(d.count)) // Adjusted height
            .delay((d, i) => i * 100);

        // Create the X-axis labels
        svg
            .append('g')
            .attr('transform', `translate(0,${height - margin})`)
            .call(
                d3
                    .axisBottom(xScale)
                    .tickValues(tickValues)
                    .tickFormat((d) => chageTimeLabel(d) as string)
                    .tickSize(0)
            )
            .selectAll('text')
            .style('font-size', '10px')
            .style('text-anchor', 'middle');

        svg.selectAll('path').style('display', 'none');

        // Cleanup tooltip on component unmount
        return () => {
            tooltip.remove();
        };
    }, [data, width, height, margin]);

    return (
            <CardContent>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid>
                        <Typography variant="h6" gutterBottom>
                            Total Email Volumes
                        </Typography>
                        <svg ref={ref} />

                    </Grid>
                    <Grid padding={2} alignSelf={'end'} paddingBottom={4}>
                        <Typography variant="h3" align="right" color="primary">
                            {numberOfEmails?.toLocaleString()}
                        </Typography>
                        <Typography variant="body1" align="right" sx={{ color: '#636C78', fontSize: '14px' }}>
                            {timePeriod === "Day" ? "today   " : `this ${timePeriod.toLowerCase()}`}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        );
};

export default BarChart;
