import { Box, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./EmailState.css";
import { EmailStatus } from "../services/models/EmailStatus";

interface EmailStateProps {
  data: {
    id: number;
    title: string;
    displaytitle: string;
    icon: string;
    color: string;
    status: EmailStatus;
  };
  manualFilter: (status: EmailStatus) => void;
  activeStatus: string;
}

function EmailState({ data, manualFilter, activeStatus }: EmailStateProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);

  useEffect(() => {
    if (data.icon === "") return;
    import(`../assets/images/${data.icon}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [data.icon]);

  return (
    <>
      {data.title === "All" ? (
        <Box
          style={{
            borderBottom: "4px solid " + data.color,
            backgroundColor:
              activeStatus === "All" ? data.color : "transparent",
            cursor: "pointer",
          }}
          className="email-state-container"
          onClick={() => manualFilter("All")}
        >
          <Typography
            style={{ color: activeStatus === "All" ? "#FFFFFF" : data.color }}
            className="email-state-title"
          >
            All E-Mails
          </Typography>
        </Box>
      ) : (
        <Box
          className="email-state-container"
          style={{
            borderBottom: "4px solid " + data.color,
            cursor: "pointer",
            backgroundColor:
              activeStatus === data.status ? data.color : "transparent",
          }}
          onClick={() => manualFilter(data.status)}
        >
          {iconSrc && (
            <img
              src={iconSrc}
              alt={data.icon}
              style={{
                filter:
                  activeStatus === data.status
                    ? "brightness(0) invert(1)"
                    : "brightness(100%) saturate(100%) invert(0)",
              }}
            />
          )}
          <Typography
            className="email-state-title"
            style={{
              color: activeStatus === data.status ? "#FFFFFF" : data.color,
            }}
          >
            {data.displaytitle}
          </Typography>
        </Box>
      )}
    </>
  );
}

export default EmailState;
