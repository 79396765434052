import { Box, Typography } from "@mui/material";
import { EmailData } from "../services/models/EmailData";
import { Button } from "@mui/material";
import { useEffect, useState } from "react";

export function DisplayEmail(props: {
  email: EmailData;
  isReply: boolean;
  startCollapsed?: boolean;
}): JSX.Element {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    setExpanded(!props.startCollapsed);
  }, [props.email]);

  return (
    <Box border={"1px solid gray"} padding="5px">
      {(props.email.body || props.email.htmlBody) && (
        <>
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography variant="h6" color="black">
              {props.isReply ? "Reply Email" : "Inbound Email"}
            </Typography>

            <Button onClick={() => setExpanded(!expanded)}>
              {expanded ? "Collapse" : "Expand"}
            </Button>
          </Box>
          {expanded && (
            <>
              <hr />
              <Box sx={{ mb: 2 }}>
                <Typography
                  variant="subtitle1"
                  sx={{ textDecoration: "underline" }}
                >
                  Subject:
                </Typography>
                <Typography variant="subtitle1">
                  {props.email?.subject}
                </Typography>
              </Box>
              {!props.email?.htmlBody && (
                <Box sx={{ mb: 2 }}>
                  rendering as text
                  <Typography
                    variant="subtitle1"
                    sx={{ textDecoration: "underline" }}
                  >
                    Reply:
                  </Typography>
                  <br></br>
                  <Typography
                    variant="body1"
                    component="pre"
                    sx={{ whiteSpace: "pre-wrap" }}
                  >
                    {props.email?.body}
                  </Typography>
                </Box>
              )}
              {props.email?.htmlBody && (
                <Box sx={{ mb: 2 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ textDecoration: "underline" }}
                  >
                    Reply:
                  </Typography>
                  <Box
                    dangerouslySetInnerHTML={{ __html: props.email.htmlBody }}
                  />
                </Box>
              )}
            </>
          )}
        </>
      )}
    </Box>
  );
}
