export class Settings {
  static getSettings() {
    return new Settings();
  }

  _SERVER_URL: string | undefined;
  get SERVER_URL() {
    if (!this._SERVER_URL) {
      const envServerUrl = process.env.REACT_APP_SERVER_URL;
      if (envServerUrl) {
        this._SERVER_URL = envServerUrl;
        return envServerUrl;
      }

      this._SERVER_URL = "http://localhost:3002";
    }

    // add code here to get from env variable... if not, return other.
    return this._SERVER_URL;
  }
}
