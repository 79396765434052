import { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../assets/images/Logo.svg";
import { ReactComponent as FilterDownIcon } from "../assets/images/filter-down-icon.svg";
import "./Sidebar.css";
import { Services } from "../Services";

import { MailBox } from "../services/models/MailBox";
import { Global, Pages } from "../Global";
import { ObserveState } from "../StateManagement/ObserveState";
import { Box, Typography } from "@mui/material";

function Sidebar() {
  const [mailBoxes, setMailBoxes] = useState<MailBox[]>([]);
  const [arrowFlag, setArrowFlag] = useState(true);

  const mainMenus = [
    {
      key: Pages.Dashboard,
      icon: (
        <img
          src={require("../assets/images/dashboard.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Dashboard</Typography>,
    },

    {
      key: Pages.EmailList,
      icon: (
        <img
          src={require("../assets/images/inbox.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>E-Mail</Typography>,
      subMenus:
        mailBoxes.length > 0
          ? mailBoxes.map((mailbox, index) => ({
              key: `mailbox-${index}`,
              mailboxName: mailbox.emailAddress,
              label: (
                <Typography
                  paddingLeft={"20px"}
                >{`${"- " + mailbox.emailAddress?.substring(0, mailbox.emailAddress?.indexOf("@"))}`}</Typography>
              ),
            }))
          : [],
    },
    {
      key: Pages.UserList,
      icon: (
        <img
          src={require("../assets/images/users.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>User List</Typography>,
    },
    {
      key: Pages.Companies,
      icon: (
        <img
          src={require("../assets/images/companies.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Companies</Typography>,
    },
    {
      key: Pages.MailBoxList,
      icon: (
        <img
          src={require("../assets/images/mailboxes.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Mailboxes</Typography>,
    },
    {
      key: Pages.EmailAddressRules,
      icon: (
        <img
          src={require("../assets/images/blockedEmails.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Email Rules</Typography>,
    },
    {
      key: Pages.QuoteTool,
      icon: (
        <img
          src={require("../assets/images/quoteTool.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Quote Tool</Typography>,
    },
    {
      key: Pages.EmailParseTest,
      icon: (
        <img
          src={require("../assets/images/parseTool.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Email Parse Test Tool</Typography>,
    },
    // {
    //   key: Pages.Settings,
    //   icon: (
    //     <img src={require("../assets/images/setting.svg").default}
    //     alt="mySvgImage"/>
    //   ),
    //   label: <Typography>Settings</Typography>,
    // },
    {
      key: Pages.Support,
      icon: (
        <img
          src={require("../assets/images/support.svg").default}
          alt="mySvgImage"
        />
      ),
      label: <Typography>Support</Typography>,
    },
  ];

  let token = Global.getJwtToken();
  useEffect(() => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailBoxes: any) => {
        setMailBoxes(mailBoxes);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, [token]);

  const handleMenuItem = (menu: any) => {
    if (menu.subMenus && menu.subMenus.length > 0) {
      setArrowFlag(!arrowFlag);
    } else {
      setArrowFlag(false);
    }
    Global.getGlobal().CurrentPage = menu.key;
  };

  const handleSubMenuItem = (subMenu: any) => {
    Global.getGlobal().CurrentMailbox = subMenu.mailboxName;
  };

  // filter the list based on permissions
  let user = Global.getGlobal().user;

  let renderMenus = [...mainMenus];

  if (!user?.canAdministerCompanies) {
    renderMenus = renderMenus.filter((menu) => menu.key !== Pages.Companies);
  }

  if (!user?.canAdministerMailBoxes) {
    renderMenus = renderMenus.filter((menu) => menu.key !== Pages.MailBoxList);
  }

  if (!user?.canAdminsterEmailRules) {
    renderMenus = renderMenus.filter(
      (menu) => menu.key !== Pages.EmailAddressRules,
    );
  }

  if (!user?.canAdministerUsers) {
    renderMenus = renderMenus.filter((menu) => menu.key !== Pages.UserList);
  }

  return (
    <ObserveState
      listenTo={Global.getGlobal()}
      control={() => {
        return (
          <div className="sidebar-container">
            <div className="menu-top-container">
              <Logo className="logo" height={"90px"} />
              <Box
                display={"block"}
                flexDirection={"column"}
                alignItems={"start"}
                height={"100%"}
              >
                {renderMenus.map((menu) => (
                  <div className="menu-item-container" key={menu.key}>
                    <div
                      className={`menu-item ${
                        Global.getGlobal().CurrentPage === menu.key &&
                        "menu-item-active"
                      }`}
                      onClick={() => handleMenuItem(menu)}
                    >
                      <>
                        {menu.icon}
                        {menu.label}
                      </>
                      {menu.subMenus &&
                        menu.subMenus.length > 0 &&
                        (arrowFlag ? (
                          <FilterDownIcon cursor={"pointer"} />
                        ) : (
                          <FilterDownIcon
                            className="rotate"
                            cursor={"pointer"}
                          />
                        ))}
                    </div>
                    {menu.subMenus && menu.subMenus.length > 0 && arrowFlag && (
                      <div className="sub-menu-list">
                        {menu.subMenus.map((subMenu) => (
                          <div
                            className={`sub-menu-item ${
                              Global.getGlobal().CurrentMailbox ==
                                subMenu.mailboxName && "sub-menu-item-active"
                            }`}
                            key={subMenu.key}
                            onClick={() => handleSubMenuItem(subMenu)}
                          >
                            {subMenu.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </Box>
            </div>
          </div>
        );
      }}
    />
  );
}

export default Sidebar;
