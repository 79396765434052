import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Company, User } from "../../services/models";
import { useEffect, useState } from "react";
import { Services } from "../../Services";
import { Global } from "../../Global";

type UpdateUserModalProps = {
  open: boolean;
  onClose: () => void;
  user: User;
  title: string;
  currentlyChanged: "password" | "company";
};

export function UpdateUserModal(props: UpdateUserModalProps) {
  const [password, setPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [company, setCompany] = useState<string>();

  //  const [userCompanies, setUserCompanies] = useState<string[]>([]);

  const [companies, setCompanies] = useState<string[] | undefined>([]);

  let token = Global.getJwtToken();

  useEffect(() => {
    setCompany(Global.getGlobal().company);
  }, [token]);

  useEffect(() => {
    Global.getAllCompanies().then((companies) => {
      setCompanies(companies);
    });

    //    Global.getUser().then((u) => setCompany(Global.get))
  }, [props.user]);

  const updateUserCompany = () => {
    if (props.user.emailAddress && props.user.id && company) {
      Services.userService
        .changeCompany(Global.getJwtToken()!, company)
        .then((t) => {
          console.log("changign company");
          Global.setJwtToken(t);
          props.onClose();
        });
    }
  };

  const updateUserPassword = () => {
    if (props.user.emailAddress && props.user.id) {
      Services.userService
        .changePassword(props.user.emailAddress, password, newPassword)
        .then(() => {
          props.onClose();
        });
    }
  };

  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="update-user-modal-title"
      aria-describedby="update-user-modal-description"
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box className="update-user-modal-wrapper">
        <Box className="update-user-modal-header">
          <Typography
            id="update-user-modal-title"
            className="update-user-modal-title"
            component="h2"
          >
            {props.title}
          </Typography>
          <Typography
            id="update-user-modal-description"
            className="update-user-modal-description"
          >
            {props.user.firstName} {props.user.lastName}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", flexDirection: "column", gap: 2 }}
          className="update-user-modal-content"
        >
          {props.currentlyChanged === "password" && (
            <>
              <TextField
                label="Password"
                type="password"
                value={password}
                className="update-user-modal-input"
                onChange={(e: any) => setPassword(e.target.value)}
              />
              <TextField
                label="New Password"
                type="password"
                value={newPassword}
                onChange={(e: any) => setNewPassword(e.target.value)}
                className="update-user-modal-input"
              />
            </>
          )}

          {props.currentlyChanged === "company" && (
            <FormControl
              fullWidth
              margin="none"
              className="update-user-modal-input"
            >
              <InputLabel
                htmlFor="company"
                className="update-user-modal-input-label"
              >
                Company
              </InputLabel>
              <Select
                required
                id="company"
                name="company"
                className="update-user-modal-select"
                value={company || ""}
                onChange={(e: any) => setCompany(e.target.value)}
              >
                {(companies ?? []).map((company) => (
                  <MenuItem key={company} value={company}>
                    {company}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Button
            onClick={
              props.currentlyChanged === "password"
                ? updateUserPassword
                : updateUserCompany
            }
            variant="contained"
            color="primary"
            className="update-user-modal-button"
          >
            <Typography className="update-user-modal-button-text">
              Update User
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
