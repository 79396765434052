/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PluginConfigDataType } from './PluginConfigDataType';
import {
    PluginConfigDataTypeFromJSON,
    PluginConfigDataTypeFromJSONTyped,
    PluginConfigDataTypeToJSON,
} from './PluginConfigDataType';

/**
 * 
 * @export
 * @interface PluginConfigDataDef
 */
export interface PluginConfigDataDef {
    /**
     * 
     * @type {string}
     * @memberof PluginConfigDataDef
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof PluginConfigDataDef
     */
    description?: string;
    /**
     * 
     * @type {PluginConfigDataType}
     * @memberof PluginConfigDataDef
     */
    dataType?: PluginConfigDataType;
}



/**
 * Check if a given object implements the PluginConfigDataDef interface.
 */
export function instanceOfPluginConfigDataDef(value: object): value is PluginConfigDataDef {
    return true;
}

export function PluginConfigDataDefFromJSON(json: any): PluginConfigDataDef {
    return PluginConfigDataDefFromJSONTyped(json, false);
}

export function PluginConfigDataDefFromJSONTyped(json: any, ignoreDiscriminator: boolean): PluginConfigDataDef {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'] == null ? undefined : json['name'],
        'description': json['description'] == null ? undefined : json['description'],
        'dataType': json['dataType'] == null ? undefined : PluginConfigDataTypeFromJSON(json['dataType']),
    };
}

export function PluginConfigDataDefToJSON(value?: PluginConfigDataDef | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'name': value['name'],
        'description': value['description'],
        'dataType': PluginConfigDataTypeToJSON(value['dataType']),
    };
}

