// src/components/Charts/PieChart.tsx
import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';
import { QuotePercentage } from '../../services/models/QuotePercentage';
import "./PieChart.css";
import {
    CardContent,
    Table,
    TableContainer,
    TableBody,
    TableCell,
    TableRow,
    Paper,
    Box
} from "@mui/material";

interface IPieChartProps {
    data: QuotePercentage[] | undefined;
    width: number;
    height: number;
}

const PieChart: React.FC<IPieChartProps> = ({ data, width, height }) => {
    const ref = useRef<SVGSVGElement | null>(null);

    const matchLabel = (label: string) => {
        switch (label) {
            case "AutoProcessed":
                return "Auto";
            case "ManualProcessed":
                return "Manual";
            case "NotProcessed":
                return "Could Not Respond";
            case "Annotated":
                return "Annotated";
            case "Draft":
                return "Draft";
            case "Ignored":
                return "Ignored Deleted";
            default:
                return label;
        }
    };

    const matchStatus = (status: string) => {
        switch (status) {
            case "AutoProcessed":
                return "Emails auto processed";
            case "ManualProcessed":
                return "Emails manual processed";
            case "NotProcessed":
                return "Emails not processed";
            case "Annotated":
                return "Emails annotated";
            case "Draft":
                return "Emails draft";
            case "Ignored":
                return "Emails ignored";
            default:
                return status;
        }
    }

    // Utility function to lighten a hex color
    const lightenHexColor = (hex: string, percent: number) => {
        const rgb = hexToRgb(hex);
        const lighterRgb = lightenRgb(rgb, percent);
        return rgbToHex(lighterRgb.r, lighterRgb.g, lighterRgb.b);
    };

    const hexToRgb = (hex: string) => {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return { r, g, b };
    };

    const lightenRgb = ({ r, g, b }: { r: number; g: number; b: number }, percent: number) => {
        const newR = Math.min(255, Math.floor(r + (255 - r) * (percent / 100)));
        const newG = Math.min(255, Math.floor(g + (255 - g) * (percent / 100)));
        const newB = Math.min(255, Math.floor(b + (255 - b) * (percent / 100)));
        return { r: newR, g: newG, b: newB };
    };

    const rgbToHex = (r: number, g: number, b: number) => {
        return (
            '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1).toUpperCase()
        );
    };

    const color = d3.scaleOrdinal<string>().range([
        "#3f51b5", "#ff7043", "#a5d6a7", "#ffcc80", "#f8bbd0", "#cfd8dc"
    ]);

    useEffect(() => {
        const svg = d3.select(ref.current).attr('width', width).attr('height', height);
        const radius = Math.min(width, height) / 2;

        const filterData = data?.map(d => ({
            status: d.status,
            percentage: d.percentage,
            total: d.total,
        })) as { status: string; percentage: number; total: number }[];

        const minStatus = filterData.reduce((minObj, current) => current.percentage <= minObj.percentage ? current : minObj, filterData[0]).status;

        const pie = d3.pie<{ status: string; percentage: number; total: number }>().value(d => d.percentage);
        const arc = d3.arc<d3.PieArcDatum<{ status: string; percentage: number; total: number }>>()
            .innerRadius(0)
            .outerRadius(radius * 0.7);

        const outerArc = d3.arc<d3.PieArcDatum<{ status: string; percentage: number; total: number }>>()
            .innerRadius(radius * 0.7 + 10)
            .outerRadius(radius * 0.7 + 15);

        const backgroundArc = d3.arc<d3.PieArcDatum<{ status: string; percentage: number; total: number }>>()
            .innerRadius(radius * 0.7 + 15)
            .outerRadius(radius * 0.7 + 40);

        svg.selectAll('*').remove(); // Clear previous renders

        const g = svg.append('g').attr('transform', `translate(${width / 2 + 30}, ${height / 2})`);

        // Tooltip Setup
        const tooltip = d3.select('body').append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('background-color', 'white')
            .style('pointer-events', 'none')
            .style('box-shadow', '0 0 8px rgba(0,0,0,0.1)');

        // Background and Outer Arcs for Hover Effect
        const outerArcs = g.selectAll('.outerarc')
            .data(pie(filterData))
            .enter()
            .append('g')
            .style('pointer-events', 'none')
            .attr('class', 'outerarc');

        outerArcs.append('path')
            .attr('d', outerArc as any)
            .attr('id', (d) => {
                return `outerArc-${d.data.status}`;
            })
            // .style('pointer-events', 'none')
            .attr('fill', d => color(d.data.status))
            .style('opacity', 0); // Initially hidden

        const backgroundArcs = g.selectAll('.backgroundarc')
            .data(pie(filterData))
            .enter()
            .append('g')
            .style('pointer-events', 'none')
            .attr('class', 'backgroundarc');

        backgroundArcs.append('path')
            .attr('d', backgroundArc as any)
            .attr('id', (d) => {
                return `backgroundArc-${d.data.status}`;
            })
            // .style('pointer-events', 'none')
            .attr('fill', d => lightenHexColor(color(d.data.status), 70))
            .style('opacity', 0); // Initially hidden

        const arcs = g.selectAll('.arc')
            .data(pie(filterData))
            .enter()
            .append('g')
            .style('pointer-events', 'none')
            .attr('class', 'arc');

        arcs.append('path')
            .attr('fill', d => color(d.data.status))
            .transition()
            .duration(1000)
            .attrTween('d', function (d) {
                const i = d3.interpolate({ startAngle: 0, endAngle: 0 }, d);
                return (t) => arc(i(t))!;
            })
            .on('end', () => {
                arcs.style('pointer-events', 'auto');
                outerArcs.style('pointer-events', 'auto');
                backgroundArcs.style('pointer-events', 'auto');
                // arcs.selectAll("path").style('pointer-events', 'auto');
                // outerArcs.selectAll("path").style('pointer-events', 'auto');
                // backgroundArcs.selectAll("path").style('pointer-events', 'auto');
            });

        arcs.append('text')
            .attr('transform', (d) => {
                const [x, y] = arc.centroid(d);
                const offset = 2.5;
                // if (d.value < 5) {
                //     return `translate(${x * offset}, ${(y * offset < 0) ? y * offset - (3 - d.value) * 5 : y * offset + (3 - d.value) * 5})`;
                // }
                // const tranX = x < 0 ? x - radius * 0.35 - i * 5 : x + radius * 0.35;
                // const tranY = y < 0 ? y - radius * 0.35 - i * 5 : y + radius * 0.35;
                return `translate(${x * offset}, ${y * offset})`;
            })
            .attr('text-anchor', (d) => {
                if (d.data.status === minStatus)
                    return 'left';
                if (arc.centroid(d)[0] < 0)
                    return 'end';
                return 'left';
            })
            .attr('id', (d) => {
                return `pie-label-${d.data.status}`;
            })
            .attr('font-size', '14px')
            // .attr('fill', d => color(d.data.status))
            .text(d => {
                if (d.data.percentage !== 0)
                    return `${matchLabel(d.data.status)}`;
                else return ``;
            });

        arcs.append('text')
            .attr('transform', (d) => {
                const [x, y] = arc.centroid(d);
                const offset = 2.5;
                // if (d.value < 5) {
                //     return `translate(${x * offset}, ${(y * offset < 0) ? y * offset - (3 - d.value) * 5 + 20 : y * offset + (3 - d.value) * 5 + 20})`;
                // }
                // const tranX = x < 0 ? x - radius * 0.35 - i * 5 : x + radius * 0.35;
                // const tranY = y < 0 ? y - radius * 0.35 - i * 5 : y + radius * 0.35;
                return `translate(${x * offset}, ${y * offset + 20})`;
            })
            .attr('text-anchor', (d) => {
                if (d.data.status === minStatus)
                    return 'left';
                if (arc.centroid(d)[0] < 0)
                    return 'end';
                return 'left';
            })
            .attr('id', (d) => {
                return `pie-percentage-${d.data.status}`;
            })
            .attr('font-size', '14px')
            .text(d => {
                if (d.data.percentage !== 0)
                    return `${d.data.percentage}%`;
                else return ``;
            });

        // Hover Events
        arcs.on('mouseenter', function (event, d) {
            d3.select(this).select('path').transition().duration(200)
                .attr('transform', 'scale(1.05)'); // Slightly enlarge the slice

            // outerArcs.style('opacity', 1); // Show outer arcs
            d3.select(`#outerArc-${d.data.status}`).style('opacity', 0.7);

            // backgroundArcs.style('opacity', 1); // Show background arcs
            d3.select(`#backgroundArc-${d.data.status}`).style('opacity', 0.7);

            d3.select(`#pie-label-${d.data.status}`).transition().duration(200)
            .attr('fill', 'red')
                .attr('font-size', '16px');
            d3.select(`#pie-percentage-${d.data.status}`).transition().duration(200)
            .attr('fill', 'red')
                .attr('font-size', '16px');

            tooltip.style('opacity', 1)
                // .html(`${matchLabel(d.data.status)}: ${d.data.percentage}%`)
                .html(`<div class="status">${matchStatus(d.data.status)}</div><div class="total">${d.data.total.toLocaleString()}</div><div class="percentage">${d.data.percentage}%</div>`)
                .style('left', `${event.pageX + 10}px`)
                .style('top', `${event.pageY - 20}px`);
            })
            .on('mousemove', (event) => {
                tooltip.style('left', `${event.pageX + 10}px`)
                    .style('top', `${event.pageY - 20}px`);
            })
            .on('mouseleave', function (_, d) {
                d3.select(this).select('path').transition().duration(200)
                    .attr('transform', 'scale(1)'); // Reset slice size

                d3.select(`#pie-label-${d.data.status}`).transition().duration(200)
                .attr('fill', 'black')
                    .attr('font-size', '14px');
                d3.select(`#pie-percentage-${d.data.status}`).transition().duration(200)
                .attr('fill', 'black')
                    .attr('font-size', '14px');

                // outerArcs.style('opacity', 0); // Hide outer arcs
                d3.select(`#outerArc-${d.data.status}`).style('opacity', 0);

                // backgroundArcs.style('opacity', 0); // Hide background arcs
                d3.select(`#backgroundArc-${d.data.status}`).style('opacity', 0);

                tooltip.style('opacity', 0);
            });

    }, [data, width, height]);

    return (
        <CardContent sx={{ display: "flex", flexDirection: "column", justifyContent: "space-around", height: height*2 }}>
            <Box margin={"auto"}>
                <svg ref={ref} />
            </Box>
            <TableContainer component={Paper} sx={{ alignSelf: 'center', borderRadius: "0px", width: "90%" }}>
                <Table>
                    {/* <TableHead>
                      <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell align="right">Percentage</TableCell>
                        <TableCell align="right">Total</TableCell>
                      </TableRow>
                    </TableHead> */}
                    <TableBody sx={{ backgroundColor: "#F7F8F8" }}>
                        {data?.map((row) => (
                            <TableRow key={row.status}>
                                <TableCell component="th" scope="row">
                                    {matchLabel(row.status ?? "")}
                                </TableCell>
                                <TableCell align="center"><span style={{ width: '50px', height: '20px', borderRadius: '5px', display: 'block', backgroundColor: `${color(row.status ?? "")}` }}></span></TableCell>
                                <TableCell align="right">{row.percentage}%</TableCell>
                                <TableCell align="right">{row.total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </CardContent>);
};

export default PieChart;
