import "./App.css";
import RequireAuth from "./layouts/RequireAuth";
import HasAuth from "./layouts/HasAuth";
import { ObserveState } from "./StateManagement/ObserveState";
import { Global } from "./Global";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    document.title = "Reply.ai";
  }, []);

  return (
    <div>
      <ObserveState
        listenTo={Global.getGlobal()}
        // properties={["jwtToken"]}
        control={() => {
          if (!Global.getJwtToken()) {
            return <HasAuth />;
          } else {
            return <RequireAuth />;
          }
        }}
      />
    </div>
  );
}

export default App;
