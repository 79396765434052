import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import { Company, User } from "../../services/models";
import { Services } from "../../Services";
import { Global } from "../../Global";

interface NewUserModalProps {
  open: boolean;
  onClose: () => void;
  user?: User;
  isEdit: boolean;
}

export function NewUserModal(props: NewUserModalProps): JSX.Element {
  const [user, setUser] = useState<User>({});

  const [userCompanies, setUserCompanies] = useState<string[]>([]);
  const [allCompanies, setAllCompanies] = useState<Company[]>([]);

  useEffect(() => {
    if (props.user) {
      setUser(props.user);
      fetchCompanies();
    } else
      Services.userService
        .createUserData(Global.getJwtToken()!)
        .then((data) => {
          setUser(data);
        });
  }, [props.user]);

  const fetchCompanies = () => {
    if (user.emailAddress) {
      Services.companyService
        .getCompaniesForUser(Global.getJwtToken()!, user.emailAddress!)
        .then((companies) => {
          if (companies) setUserCompanies(companies);
        });
    }

    Services.companyService
      .getAllCompanies(Global.getJwtToken()!)
      .then((companies) => {
        if (companies) setAllCompanies(companies);
      });
  };

  useEffect(() => {
    fetchCompanies();
  }, [props.user]);

  const handleSubmit = () => {
    Services.userService.saveUser(Global.getJwtToken()!, user);
    handleClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleClose = () => {
    Services.userService.createUserData(Global.getJwtToken()!).then((data) => {
      setUser(data);
    });

    props.onClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="new-user-modal-title"
      aria-describedby="new-user-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className="new-user-modal">
        <Box className="new-user-modal__header">
          <h2 id="new-user-modal-title" className="new-user-modal__title">
            New User
          </h2>
          <Button
            variant="text"
            onClick={handleClose}
            className="new-user-modal__close-button"
          >
            <Close />
          </Button>
        </Box>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="new-user-modal__form"
        >
          <Box className="new-user-modal__form-inner">
            <TextField
              fullWidth
              margin="normal"
              variant="filled"
              id="emailAddress"
              name="emailAddress"
              label="E-Mail"
              placeholder="-"
              value={user.emailAddress}
              onChange={props.isEdit ? () => {} : handleChange}
              required
              type="email"
              className="new-user-modal__input"
            />
            <TextField
              fullWidth
              margin="normal"
              variant="filled"
              id="firstName"
              name="firstName"
              label="First Name"
              placeholder="-"
              value={user.firstName}
              onChange={handleChange}
              className="new-user-modal__input"
            />
            <TextField
              fullWidth
              margin="normal"
              variant="filled"
              id="lastName"
              name="lastName"
              label="Last Name"
              placeholder="-"
              value={user.lastName}
              onChange={handleChange}
              className="new-user-modal__input"
            />
            <TextField
              fullWidth
              margin="normal"
              variant="filled"
              id="password"
              name="password"
              label={
                props.isEdit ? "Set New User Password" : "Password (Required)"
              }
              placeholder="-"
              value={user.password}
              onChange={handleChange}
              required={!props.isEdit}
              type="password"
              className="new-user-modal__input"
            />

            <Box className="new-user-modal__form-group">
              <FormControl
                component="fieldset"
                margin="normal"
                className="new-user-modal__form-control"
              >
                <Typography className="new-user-modal__input-label">
                  Active
                </Typography>
                <ToggleButtonGroup
                  value={user.isActive ? "yes" : "no"}
                  exclusive
                  onChange={(e, value) => {
                    if (value !== null) {
                      setUser({ ...user, isActive: value === "yes" });
                    }
                  }}
                  className="new-user-modal__toggle-button-group"
                >
                  <ToggleButton
                    value="yes"
                    className="new-user-modal__toggle-button"
                  >
                    Yes
                  </ToggleButton>
                  <ToggleButton
                    value="no"
                    className="new-user-modal__toggle-button"
                  >
                    No
                  </ToggleButton>
                </ToggleButtonGroup>
              </FormControl>
            </Box>
            <Box className="new-user-modal__form-group">
              <FormControl
                component="fieldset"
                fullWidth
                margin="normal"
                className="new-user-modal__form-control"
              >
                <Typography className="new-user-modal__input-label">
                  Permissions
                </Typography>
                <FormControlLabel
                  control={
                    <ToggleButtonGroup
                      value={user.canAdministerUsers ? "yes" : "no"}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setUser({
                            ...user,
                            canAdministerUsers: value === "yes",
                          });
                        }
                      }}
                      className="new-user-modal__toggle-button-group"
                    >
                      <ToggleButton
                        value="yes"
                        className="new-user-modal__toggle-button"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value="no"
                        className="new-user-modal__toggle-button"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  }
                  label="Can Administer Users"
                  className="new-user-modal__checkbox-label"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <ToggleButtonGroup
                      value={user.canAdministerCompanies ? "yes" : "no"}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setUser({
                            ...user,
                            canAdministerCompanies: value === "yes",
                          });
                        }
                      }}
                      className="new-user-modal__toggle-button-group"
                    >
                      <ToggleButton
                        value="yes"
                        className="new-user-modal__toggle-button"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value="no"
                        className="new-user-modal__toggle-button"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  }
                  label="Can Administer Companies"
                  className="new-user-modal__checkbox-label"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <ToggleButtonGroup
                      value={user.canAdministerMailBoxes ? "yes" : "no"}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setUser({
                            ...user,
                            canAdministerMailBoxes: value === "yes",
                          });
                        }
                      }}
                      className="new-user-modal__toggle-button-group"
                    >
                      <ToggleButton
                        value="yes"
                        className="new-user-modal__toggle-button"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value="no"
                        className="new-user-modal__toggle-button"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  }
                  label="Can Administer Mailboxes"
                  className="new-user-modal__checkbox-label"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <ToggleButtonGroup
                      value={user.canRespondToEmails ? "yes" : "no"}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setUser({
                            ...user,
                            canRespondToEmails: value === "yes",
                          });
                        }
                      }}
                      className="new-user-modal__toggle-button-group"
                    >
                      <ToggleButton
                        value="yes"
                        className="new-user-modal__toggle-button"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value="no"
                        className="new-user-modal__toggle-button"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  }
                  label="Can Respond To Emails"
                  className="new-user-modal__checkbox-label"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <ToggleButtonGroup
                      value={user.canAdminsterEmailRules ? "yes" : "no"}
                      exclusive
                      onChange={(e, value) => {
                        if (value !== null) {
                          setUser({
                            ...user,
                            canAdminsterEmailRules: value === "yes",
                          });
                        }
                      }}
                      className="new-user-modal__toggle-button-group"
                    >
                      <ToggleButton
                        value="yes"
                        className="new-user-modal__toggle-button"
                      >
                        Yes
                      </ToggleButton>
                      <ToggleButton
                        value="no"
                        className="new-user-modal__toggle-button"
                      >
                        No
                      </ToggleButton>
                    </ToggleButtonGroup>
                  }
                  label="Can Administer Email Rules"
                  className="new-user-modal__checkbox-label"
                  labelPlacement="start"
                />
              </FormControl>
            </Box>
            {props.isEdit && (
              <Box className="user-companies-list">
                <Typography variant="h6">User Companies</Typography>
                {userCompanies && userCompanies.length > 0 ? (
                  userCompanies.map((userCompany) => (
                    <Box
                      key={userCompany}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginBottom: 1,
                      }}
                    >
                      <Typography>{userCompany}</Typography>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => {
                          Services.companyService
                            .removeUserFromCompany(
                              Global.getJwtToken()!,
                              user.emailAddress!,
                              userCompany,
                            )
                            .then(() => {
                              setUserCompanies((prevCompanies) =>
                                prevCompanies.filter(
                                  (company) => company !== userCompany,
                                ),
                              );
                              setUser((prevUser) => ({
                                ...prevUser,
                              }));
                            });
                        }}
                      >
                        Delete
                      </Button>
                    </Box>
                  ))
                ) : (
                  <Typography>No companies assigned</Typography>
                )}

                <TextField
                  select
                  fullWidth
                  margin="normal"
                  variant="filled"
                  id="all-companies"
                  name="all-companies"
                  label="Add Company"
                  className="new-user-modal__select"
                  value=""
                  onChange={(e) => {
                    const selectedCompany = allCompanies.find(
                      (c) => c.id === e.target.value,
                    );
                    if (selectedCompany) {
                      Services.companyService
                        .addUserToCompany(
                          Global.getJwtToken()!,
                          user.emailAddress!,
                          selectedCompany.companyName!,
                          selectedCompany.id,
                        )
                        .then(() => {
                          setUserCompanies((prevCompanies) => [
                            ...prevCompanies,
                            selectedCompany.companyName!,
                          ]);
                        });
                    }
                  }}
                >
                  {allCompanies
                    .filter(
                      (company) =>
                        !userCompanies.includes(company.companyName!),
                    )
                    .map((company) => (
                      <MenuItem key={company.id} value={company.id}>
                        {company.companyName}
                      </MenuItem>
                    ))}
                </TextField>
              </Box>
            )}
          </Box>

          {props.isEdit ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  className="new-user-modal__submit-button"
                  type="submit"
                >
                  <Typography className="new-user-modal__submit-button-text">
                    Submit
                  </Typography>
                </Button>
              </Box>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className="new-user-modal__submit-button"
              type="submit"
            >
              <Typography className="new-user-modal__submit-button-text">
                Submit
              </Typography>
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
