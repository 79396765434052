import React from "react";
import { Email } from "../../../services/models/Email";
import { Box, Typography } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import "./layers.css";

interface ManuallyRespondedProps {
  emailData: Email;
  onClose: () => void;
}

function ManuallyResponded(props: ManuallyRespondedProps) {
  return (
    <Box className="layers-container">
      <EmailMainPart email={props.emailData} onClose={props.onClose} />
    </Box>
  );
}

export default ManuallyResponded;
