/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailHandleType = {
    ReplyAndForwardUnhandled: 'Reply and Forward Unhandled',
    ReplyAndSaveToDraft: 'Reply and Save to Draft',
    ForwardAll: 'Forward All',
    ForwardAllWithDiagnostic: 'Forward All with Diagnostic',
    ReplyAllWithDiagnostic: 'Reply All with Diagnostic',
    DoNotSend: 'Do Not Send',
    SaveAllToDraft: 'Save All to Draft'
} as const;
export type EmailHandleType = typeof EmailHandleType[keyof typeof EmailHandleType];


export function instanceOfEmailHandleType(value: any): boolean {
    for (const key in EmailHandleType) {
        if (Object.prototype.hasOwnProperty.call(EmailHandleType, key)) {
            if (EmailHandleType[key as keyof typeof EmailHandleType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EmailHandleTypeFromJSON(json: any): EmailHandleType {
    return EmailHandleTypeFromJSONTyped(json, false);
}

export function EmailHandleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailHandleType {
    return json as EmailHandleType;
}

export function EmailHandleTypeToJSON(value?: EmailHandleType | null): any {
    return value as any;
}

