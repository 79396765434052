/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailType } from './EmailType';
import {
    EmailTypeFromJSON,
    EmailTypeFromJSONTyped,
    EmailTypeToJSON,
} from './EmailType';
import type { DynamicSetting } from './DynamicSetting';
import {
    DynamicSettingFromJSON,
    DynamicSettingFromJSONTyped,
    DynamicSettingToJSON,
} from './DynamicSetting';
import type { EmailHandleType } from './EmailHandleType';
import {
    EmailHandleTypeFromJSON,
    EmailHandleTypeFromJSONTyped,
    EmailHandleTypeToJSON,
} from './EmailHandleType';

/**
 * 
 * @export
 * @interface MailBox
 */
export interface MailBox {
    /**
     * 
     * @type {any}
     * @memberof MailBox
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof MailBox
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof MailBox
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    emailAddress?: string;
    /**
     * 
     * @type {EmailType}
     * @memberof MailBox
     */
    emailType?: EmailType;
    /**
     * 
     * @type {boolean}
     * @memberof MailBox
     */
    autoCheck?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    emailGeneratorPlugin?: string;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    emailParserPlugin?: string;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    emailInterfacePlugin?: string;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    erpInterfacePlugin?: string;
    /**
     * 
     * @type {Array<DynamicSetting>}
     * @memberof MailBox
     */
    mailSettings?: Array<DynamicSetting>;
    /**
     * 
     * @type {Array<DynamicSetting>}
     * @memberof MailBox
     */
    erpSettings?: Array<DynamicSetting>;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    currentPrompt?: string;
    /**
     * 
     * @type {number}
     * @memberof MailBox
     */
    targetPriceAutoReplyThreshold?: number;
    /**
     * 
     * @type {number}
     * @memberof MailBox
     */
    autoReplyThreshold?: number;
    /**
     * 
     * @type {string}
     * @memberof MailBox
     */
    forwardEmail?: string;
    /**
     * 
     * @type {EmailHandleType}
     * @memberof MailBox
     */
    emailHandleStrategy?: EmailHandleType;
    /**
     * 
     * @type {boolean}
     * @memberof MailBox
     */
    needsAuthorization?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof MailBox
     */
    tokenExpiration?: Date;
    /**
     * 
     * @type {Date}
     * @memberof MailBox
     */
    lastCheck?: Date;
}



/**
 * Check if a given object implements the MailBox interface.
 */
export function instanceOfMailBox(value: object): value is MailBox {
    return true;
}

export function MailBoxFromJSON(json: any): MailBox {
    return MailBoxFromJSONTyped(json, false);
}

export function MailBoxFromJSONTyped(json: any, ignoreDiscriminator: boolean): MailBox {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'emailAddress': json['emailAddress'] == null ? undefined : json['emailAddress'],
        'emailType': json['emailType'] == null ? undefined : EmailTypeFromJSON(json['emailType']),
        'autoCheck': json['autoCheck'] == null ? undefined : json['autoCheck'],
        'emailGeneratorPlugin': json['emailGeneratorPlugin'] == null ? undefined : json['emailGeneratorPlugin'],
        'emailParserPlugin': json['emailParserPlugin'] == null ? undefined : json['emailParserPlugin'],
        'emailInterfacePlugin': json['emailInterfacePlugin'] == null ? undefined : json['emailInterfacePlugin'],
        'erpInterfacePlugin': json['erpInterfacePlugin'] == null ? undefined : json['erpInterfacePlugin'],
        'mailSettings': json['mailSettings'] == null ? undefined : ((json['mailSettings'] as Array<any>).map(DynamicSettingFromJSON)),
        'erpSettings': json['erpSettings'] == null ? undefined : ((json['erpSettings'] as Array<any>).map(DynamicSettingFromJSON)),
        'currentPrompt': json['currentPrompt'] == null ? undefined : json['currentPrompt'],
        'targetPriceAutoReplyThreshold': json['targetPriceAutoReplyThreshold'] == null ? undefined : json['targetPriceAutoReplyThreshold'],
        'autoReplyThreshold': json['autoReplyThreshold'] == null ? undefined : json['autoReplyThreshold'],
        'forwardEmail': json['forwardEmail'] == null ? undefined : json['forwardEmail'],
        'emailHandleStrategy': json['emailHandleStrategy'] == null ? undefined : EmailHandleTypeFromJSON(json['emailHandleStrategy']),
        'needsAuthorization': json['needsAuthorization'] == null ? undefined : json['needsAuthorization'],
        'tokenExpiration': json['tokenExpiration'] == null ? undefined : (new Date(json['tokenExpiration'])),
        'lastCheck': json['lastCheck'] == null ? undefined : (new Date(json['lastCheck'])),
    };
}

export function MailBoxToJSON(value?: MailBox | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedBy': value['modifiedBy'],
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'companyName': value['companyName'],
        'emailAddress': value['emailAddress'],
        'emailType': EmailTypeToJSON(value['emailType']),
        'autoCheck': value['autoCheck'],
        'emailGeneratorPlugin': value['emailGeneratorPlugin'],
        'emailParserPlugin': value['emailParserPlugin'],
        'emailInterfacePlugin': value['emailInterfacePlugin'],
        'erpInterfacePlugin': value['erpInterfacePlugin'],
        'mailSettings': value['mailSettings'] == null ? undefined : ((value['mailSettings'] as Array<any>).map(DynamicSettingToJSON)),
        'erpSettings': value['erpSettings'] == null ? undefined : ((value['erpSettings'] as Array<any>).map(DynamicSettingToJSON)),
        'currentPrompt': value['currentPrompt'],
        'targetPriceAutoReplyThreshold': value['targetPriceAutoReplyThreshold'],
        'autoReplyThreshold': value['autoReplyThreshold'],
        'forwardEmail': value['forwardEmail'],
        'emailHandleStrategy': EmailHandleTypeToJSON(value['emailHandleStrategy']),
        'needsAuthorization': value['needsAuthorization'],
        'tokenExpiration': value['tokenExpiration'] == null ? undefined : ((value['tokenExpiration']).toISOString()),
        'lastCheck': value['lastCheck'] == null ? undefined : ((value['lastCheck']).toISOString()),
    };
}

