/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DashboardResult,
  TimePeriod,
} from '../models/index';
import {
    DashboardResultFromJSON,
    DashboardResultToJSON,
    TimePeriodFromJSON,
    TimePeriodToJSON,
} from '../models/index';

export interface GetDashboardDataRequest {
    authorization: string;
    timePeriod: TimePeriod;
    mailboxyName?: string;
}

/**
 * 
 */
export class DashboardApi extends runtime.BaseAPI {

    /**
     */
    async getDashboardDataRaw(requestParameters: GetDashboardDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DashboardResult>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getDashboardData().'
            );
        }

        if (requestParameters['timePeriod'] == null) {
            throw new runtime.RequiredError(
                'timePeriod',
                'Required parameter "timePeriod" was null or undefined when calling getDashboardData().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['timePeriod'] != null) {
            queryParameters['timePeriod'] = requestParameters['timePeriod'];
        }

        if (requestParameters['mailboxyName'] != null) {
            queryParameters['mailboxyName'] = requestParameters['mailboxyName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/dashboard/getDashboardData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DashboardResultFromJSON(jsonValue));
    }

    /**
     */
    async getDashboardData(authorization: string, timePeriod: TimePeriod, mailboxyName?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DashboardResult> {
        const response = await this.getDashboardDataRaw({ authorization: authorization, timePeriod: timePeriod, mailboxyName: mailboxyName }, initOverrides);
        return await response.value();
    }

}
