import { useEffect, useState } from "react";
import { EmailAddressRules } from "../services/models";
import { Services } from "../Services";
import { EmailAddressRulesItem } from "./emaliAddressRules/EmailAddressRulesItem";
import { EmailAddressRulesModal } from "./emaliAddressRules/EmailAddressRulesModal";
import "./BlockedList.css";
import { Box, Button, Typography } from "@mui/material";
import { Global } from "../Global";

export function EmailAddressRulesList() {
  const [emailAddressRulees, setEmailAddressRulees] = useState<
    EmailAddressRules[]
  >([]);
  const [open, setOpen] = useState(false);
  const [editEmailAddressRule, setEditEmailAddressRule] =
    useState<EmailAddressRules>({
      addressWithWildcard: "",
      blockedReason: "",
    });

  const fetchEmailAddressRulees = () => {
    Services.emailAddressRuleService
      .getEmailAddressRules(Global.getJwtToken()!)
      .then((emails) => {
        setEmailAddressRulees(emails);
      });
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchEmailAddressRulees();
  }, [token]);

  const handleDeleteEmailAddressRule = (
    emailAddressRule: EmailAddressRules,
  ) => {
    Services.emailAddressRuleService
      .deleteEmailAddressRules(Global.getJwtToken()!, emailAddressRule.id)
      .then(() => {
        setEmailAddressRulees(
          emailAddressRulees.filter(
            (email) => email.id !== emailAddressRule.id,
          ),
        );
      });
  };

  const handleEditEmailAddressRule = (emailAddressRule: EmailAddressRules) => {
    setEditEmailAddressRule(emailAddressRule);
    setOpen(true);
  };

  const handleOpen = () => {
    setEditEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    setTimeout(() => {
      fetchEmailAddressRulees();
    }, 200);
  };

  return (
    <div>
      <Box className="blocked-container">
        <Box className="blocked-actions">
          <Button
            variant="contained"
            color="primary"
            className="blocked-list__new-blocked-button"
            onClick={handleOpen}
          >
            <Typography className="blocked-list__new-blocked-text">
              Add Rule
            </Typography>
          </Button>
        </Box>
        <Box className="blocked-list">
          {emailAddressRulees.map((emailAddressRule) => (
            <EmailAddressRulesItem
              key={emailAddressRule.id}
              emailAddressRule={emailAddressRule}
              onDelete={handleDeleteEmailAddressRule}
              openEditModal={handleEditEmailAddressRule}
            />
          ))}
        </Box>

        {emailAddressRulees.length === 0 && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"300px"}
          >
            <p>No Email Rules</p>
          </Box>
        )}
      </Box>
      <EmailAddressRulesModal
        open={open}
        onClose={() => handleClose()}
        emailAddressRule={editEmailAddressRule}
      />
    </div>
  );
}
