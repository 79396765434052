/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailStatus } from './EmailStatus';
import {
    EmailStatusFromJSON,
    EmailStatusFromJSONTyped,
    EmailStatusToJSON,
} from './EmailStatus';

/**
 * 
 * @export
 * @interface EmailSummary
 */
export interface EmailSummary {
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    from?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    fromAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    to?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    subject?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailSummary
     */
    emailDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    partialBody?: string;
    /**
     * 
     * @type {EmailStatus}
     * @memberof EmailSummary
     */
    emailStatus?: EmailStatus;
    /**
     * 
     * @type {string}
     * @memberof EmailSummary
     */
    cannotReplyReason?: string;
}



/**
 * Check if a given object implements the EmailSummary interface.
 */
export function instanceOfEmailSummary(value: object): value is EmailSummary {
    return true;
}

export function EmailSummaryFromJSON(json: any): EmailSummary {
    return EmailSummaryFromJSONTyped(json, false);
}

export function EmailSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'from': json['from'] == null ? undefined : json['from'],
        'fromAddress': json['fromAddress'] == null ? undefined : json['fromAddress'],
        'to': json['to'] == null ? undefined : json['to'],
        'subject': json['subject'] == null ? undefined : json['subject'],
        'emailDate': json['emailDate'] == null ? undefined : (new Date(json['emailDate'])),
        'partialBody': json['partialBody'] == null ? undefined : json['partialBody'],
        'emailStatus': json['emailStatus'] == null ? undefined : EmailStatusFromJSON(json['emailStatus']),
        'cannotReplyReason': json['cannotReplyReason'] == null ? undefined : json['cannotReplyReason'],
    };
}

export function EmailSummaryToJSON(value?: EmailSummary | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'from': value['from'],
        'fromAddress': value['fromAddress'],
        'to': value['to'],
        'subject': value['subject'],
        'emailDate': value['emailDate'] == null ? undefined : ((value['emailDate']).toISOString()),
        'partialBody': value['partialBody'],
        'emailStatus': EmailStatusToJSON(value['emailStatus']),
        'cannotReplyReason': value['cannotReplyReason'],
    };
}

