/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface EmailFile
 */
export interface EmailFile {
    /**
     * 
     * @type {string}
     * @memberof EmailFile
     */
    filename: string;
    /**
     * 
     * @type {string}
     * @memberof EmailFile
     */
    content: string;
}

/**
 * Check if a given object implements the EmailFile interface.
 */
export function instanceOfEmailFile(value: object): value is EmailFile {
    if (!('filename' in value) || value['filename'] === undefined) return false;
    if (!('content' in value) || value['content'] === undefined) return false;
    return true;
}

export function EmailFileFromJSON(json: any): EmailFile {
    return EmailFileFromJSONTyped(json, false);
}

export function EmailFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailFile {
    if (json == null) {
        return json;
    }
    return {
        
        'filename': json['filename'],
        'content': json['content'],
    };
}

export function EmailFileToJSON(value?: EmailFile | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'filename': value['filename'],
        'content': value['content'],
    };
}

