import React from "react";
import { Email } from "../../../services/models/Email";
import { Box, Stack, Typography } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import "./layers.css";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { Services } from "../../../Services";
import { Global } from "../../../Global";

interface CouldNotRespondLayerProps {
  emailData: Email;
  onClose: () => void;
}

function CouldNotRespondLayer(props: CouldNotRespondLayerProps) {
  const handleSendDraft = () => {
    Services.emailService.modifyDraft(
      Global.getJwtToken()!,
      Global.getGlobal().CurrentMailbox!,
      props.emailData.id,
      props.emailData.reply!.subject!,
      reply,
      true,
    );
    props.onClose();
  };
  const handleSaveDraft = () => {
    Services.emailService.modifyDraft(
      Global.getJwtToken()!,
      Global.getGlobal().CurrentMailbox!,
      props.emailData.id,
      props.emailData.reply!.subject!,
      reply,
      false,
    );
    props.onClose();
  };
  const [reply, setReply] = React.useState(
    props.emailData.reply?.htmlBody || props.emailData.reply?.body || "",
  );

  return (
    <Box className="layers-container">
      <EmailMainPart
        email={props.emailData}
        onClose={props.onClose}
        addedTabTitle="Response"
        addedTab={() => {
          console.log("rendering editor");
          return (
            <>
              {Global.getGlobal().user &&
                Global.getGlobal().user?.canRespondToEmails && (
                  <Box paddingBottom={"10px"}>
                    <Typography className="bottom-title">
                      Type And Send Response
                    </Typography>

                    <Box height={"400px"}>
                      <ReactQuill
                        theme="snow"
                        className="quill"
                        placeholder="Type your response here..."
                        value={reply}
                        onChange={setReply}
                        style={{ height: "80%" }}
                        modules={{
                          toolbar: [
                            [{ header: "1" }, { header: "2" }, { font: [] }],
                            [{ size: [] }],
                            [
                              "bold",
                              "italic",
                              "underline",
                              "strike",
                              "blockquote",
                            ],
                            [
                              { list: "ordered" },
                              { list: "bullet" },
                              { indent: "-1" },
                              { indent: "+1" },
                            ],
                            ["link", "image", "video"],
                            ["clean"],
                          ],
                        }}
                      />
                    </Box>
                    <Stack
                      direction="row"
                      spacing={2}
                      className="bottom-btn-container"
                    >
                      <Box className="bottom-button" onClick={handleSendDraft}>
                        Send Draft
                      </Box>
                      <Box className="bottom-button" onClick={handleSaveDraft}>
                        Save Draft
                      </Box>
                    </Stack>
                  </Box>
                )}
            </>
          );
        }}
      />
    </Box>
  );
}

export default CouldNotRespondLayer;
