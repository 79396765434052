import { useEffect, useState } from "react";
import { MailBox } from "../services/models";
import { Services } from "../Services";
import MailboxModal from "./mailbox/MailboxModal";
import { Box, Button, Typography } from "@mui/material";
import { MailboxListItem } from "./mailbox/MailboxListItem";
import "./MailboxList.css";
import { Global } from "../Global";

export function MailboxList(): JSX.Element {
  const [mailboxes, setMailboxes] = useState<MailBox[]>([]);
  const [editMailbox, setEditMailbox] = useState<MailBox>({});
  const [mailboxModalOpen, setMailboxModalOpen] = useState<boolean>(false);

  const fetchMailboxes = () => {
    Services.mailBoxService
      .getMailBoxes(Global.getJwtToken()!)
      .then((mailboxes: MailBox[]) => {
        setMailboxes(mailboxes);
      });
  };

  const token = Global.getJwtToken();
  useEffect(() => {
    fetchMailboxes();
  }, [token]);

  const handleDeleteMailbox = (emailAddress: string) => {
    Services.mailBoxService
      .deleteMailBox(Global.getJwtToken()!, emailAddress)
      .then(() => {
        setMailboxes(
          mailboxes.filter((mailbox) => mailbox.emailAddress !== emailAddress),
        );
      });
  };

  const handleEditMailbox = (mailbox: MailBox) => {
    setEditMailbox(mailbox);
    setMailboxModalOpen(true);
  };

  const handleMailboxClick = () => {
    Services.mailBoxService
      .createMailboxData(Global.getJwtToken()!)
      .then((mb) => {
        setEditMailbox(mb);
        setMailboxModalOpen(true);
      });
  };

  const handleClose = () => {
    Services.mailBoxService
      .createMailboxData(Global.getJwtToken()!)
      .then((mb) => {
        setEditMailbox(mb);
      });
    setTimeout(() => {
      fetchMailboxes();
    }, 200);
    setMailboxModalOpen(false);
  };

  return (
    <div>
      <Box className="mailboxes-container">
        <Box className="mailboxes-actions">
          <Button
            variant="contained"
            color="primary"
            className="mailboxes-list__new-mailbox-button"
            onClick={handleMailboxClick}
          >
            <Typography className="mailboxes-list__new-mailbox-text">
              New Mailbox
            </Typography>
          </Button>
        </Box>
        <Box className="mailboxes-list">
          {mailboxes.map((mailbox: MailBox) => (
            <MailboxListItem
              onDelete={handleDeleteMailbox}
              openEditModal={handleEditMailbox}
              key={mailbox.id}
              mailbox={mailbox}
            />
          ))}
        </Box>
        {mailboxes.length === 0 && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignContent={"center"}
            alignItems={"center"}
            width={"100%"}
            height={"300px"}
          >
            <p>No Mailboxes</p>
          </Box>
        )}
      </Box>
      <MailboxModal
        open={mailboxModalOpen}
        onClose={() => handleClose()}
        pmailbox={editMailbox}
        isNew={!editMailbox.id}
      />
    </div>
  );
}
