/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmailParseRequest,
  EmailParseResult,
} from '../models/index';
import {
    EmailParseRequestFromJSON,
    EmailParseRequestToJSON,
    EmailParseResultFromJSON,
    EmailParseResultToJSON,
} from '../models/index';

export interface GetRandomProductNamesRequest {
    authorization: string;
    mailbox: string;
}

export interface ParseEmailRequest {
    authorization: string;
    emailParseRequest: EmailParseRequest;
}

/**
 * 
 */
export class TestServiceApi extends runtime.BaseAPI {

    /**
     */
    async getRandomProductNamesRaw(requestParameters: GetRandomProductNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getRandomProductNames().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getRandomProductNames().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/TestService/randomProductNames`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getRandomProductNames(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getRandomProductNamesRaw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     */
    async parseEmailRaw(requestParameters: ParseEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailParseResult>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling parseEmail().'
            );
        }

        if (requestParameters['emailParseRequest'] == null) {
            throw new runtime.RequiredError(
                'emailParseRequest',
                'Required parameter "emailParseRequest" was null or undefined when calling parseEmail().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/TestService/parseEmail`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailParseRequestToJSON(requestParameters['emailParseRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailParseResultFromJSON(jsonValue));
    }

    /**
     */
    async parseEmail(authorization: string, emailParseRequest: EmailParseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailParseResult> {
        const response = await this.parseEmailRaw({ authorization: authorization, emailParseRequest: emailParseRequest }, initOverrides);
        return await response.value();
    }

}
