/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RuleType = {
    Block: 'Block',
    ForwardAll: 'Forward All',
    ForwardOverLimit: 'Forward Over Limit'
} as const;
export type RuleType = typeof RuleType[keyof typeof RuleType];


export function instanceOfRuleType(value: any): boolean {
    for (const key in RuleType) {
        if (Object.prototype.hasOwnProperty.call(RuleType, key)) {
            if (RuleType[key as keyof typeof RuleType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function RuleTypeFromJSON(json: any): RuleType {
    return RuleTypeFromJSONTyped(json, false);
}

export function RuleTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RuleType {
    return json as RuleType;
}

export function RuleTypeToJSON(value?: RuleType | null): any {
    return value as any;
}

