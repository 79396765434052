import React from "react";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/material";
import { Email, EmailStatus } from "../../../services/models";
import IssueReported from "./IssueReported";
import AutoResponded from "./AutoResponded";
import Ignored from "./Ignored";
import ManuallyResponded from "./ManuallyResponded";
import CouldNotRespondLayer from "./CouldNotRespondLayer";

export function EmailEditLayer(props: {
  email: Email;
  onClose: () => void;
}): JSX.Element {
  return (
    <Box>
      <ClickAwayListener onClickAway={() => props.onClose()}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80%",
            maxWidth: "800px",
            height: "90%",
            padding: "20px",
            bgcolor: "background.paper",
            zIndex: 1000,
            boxShadow: 24, // Increased shadow
          }}
        >
          {props.email.emailStatus === EmailStatus.IssueReported && (
            <IssueReported emailData={props.email} onClose={props.onClose} />
          )}
          {props.email.emailStatus === EmailStatus.AutoProcessed && (
            <AutoResponded emailData={props.email} onClose={props.onClose} />
          )}
          {props.email.emailStatus === EmailStatus.Ignored && (
            <Ignored emailData={props.email} onClose={props.onClose} />
          )}
          {props.email.emailStatus === EmailStatus.ManualProcessed && (
            <ManuallyResponded
              emailData={props.email}
              onClose={props.onClose}
            />
          )}
          {props.email.emailStatus === EmailStatus.NotProcessed && (
            <CouldNotRespondLayer
              emailData={props.email}
              onClose={props.onClose}
            />
          )}
        </Paper>
      </ClickAwayListener>
    </Box>
  );
}

export default EmailEditLayer;
