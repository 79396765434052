import React, { useState, useEffect, ChangeEvent } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Tabs,
  Tab,
  FormControl,
} from "@mui/material";

import {
  DynamicSetting,
  EmailHandleType,
  MailBox,
  PluginConfig,
  PluginConfigDataDef,
} from "../../services/models";

import { Services } from "../../Services";
import { Close } from "@mui/icons-material";
import { Global } from "../../Global";

export enum MailboxSubPage {
  General = "General",
  Rules = "Rules",
  EmailServer = "Email Server",
  ERP = "ERP Setup",
  Prompt = "Prompt",
}

interface MailboxModalProps {
  open: boolean;
  onClose: () => void;
  pmailbox?: MailBox;
  isNew: boolean;
}

const MailboxModal: React.FC<MailboxModalProps> = ({
  open,
  onClose,
  pmailbox,
  isNew,
}) => {
  const [mailbox, setMailbox] = useState<MailBox>({});

  const [subpage, setSubpage] = useState<MailboxSubPage>(
    MailboxSubPage.General,
  );

  const [erpMetaData, setErpMetaData] = useState<PluginConfig>({});
  const [emailMetaData, setEmailMetaData] = useState<PluginConfig>({});
  const [url, setUrl] = useState<string>("");

  const [mailboxTest, setMailboxTest] = useState<string>("");

  useEffect(() => {
    if (mailbox.erpInterfacePlugin)
      Services.mailBoxService
        .getERPSytemInterfacePluginConfigDefinition(
          Global.getJwtToken()!,
          mailbox.erpInterfacePlugin,
        )
        .then((data) => {
          setErpMetaData(data);
        });
  }, [mailbox.erpInterfacePlugin]);

  useEffect(() => {
    if (mailbox.emailInterfacePlugin)
      Services.mailBoxService
        .getEmailInterfaceConfigDefinition(
          Global.getJwtToken()!,
          mailbox.emailInterfacePlugin,
        )
        .then((data) => {
          setEmailMetaData(data);
        });
  }, [mailbox.emailInterfacePlugin]);

  useEffect(() => {
    if (pmailbox) {
      setMailbox(pmailbox);
    } else {
      Services.mailBoxService
        .createMailboxData(Global.getJwtToken()!)
        .then((data) => {
          setMailbox(data);
        });
    }
  }, [pmailbox]);

  const handleClose = () => {
    setUrl("");
    onClose();
  };

  const handleSubmit = () => {
    setUrl("");
    Services.mailBoxService.saveMailBox(Global.getJwtToken()!, mailbox!);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="edit-mailbox-modal"
      aria-describedby="edit-mailbox-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: 0,
        }}
        display={"flex"}
        flexDirection={"column"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ borderBottom: "5px solid #3A4188" }}
        >
          <Typography variant="h6" sx={{ m: 2 }}>
            {mailbox ? "Edit Mailbox" : "New Mailbox"}
          </Typography>
          <Button variant="text" onClick={handleClose}>
            <Close />
          </Button>
        </Box>
        <Box
          sx={{
            p: 2,
            bgcolor: "#f8d7da",
            color: "#721c24",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderRadius: 1,
          }}
        >
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="body2">
              {mailbox.needsAuthorization && !url && (
                <p>Authorization Token mail server needs to be refreshed</p>
              )}
              {!mailbox.needsAuthorization && !url && (
                <p>Click to refresh token</p>
              )}
              {url && <p>Click to authorize mailbox</p>}
            </Typography>
            {mailboxTest && (
              <Typography color="red" style={{ wordWrap: "break-word" }}>
                Test Result: {mailboxTest}
              </Typography>
            )}
          </Box>
          <Box display={"flex"} flexDirection={"column"}>
            {!url && (
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  setMailboxTest("");
                  Services.mailBoxService
                    .beginAuthorization(
                      Global.getJwtToken()!,
                      mailbox.emailAddress!,
                    )
                    .then((url) => {
                      setUrl(url.url);
                    });
                }}
              >
                Refresh Access Token
              </Button>
            )}
            {url && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.open(url, "_blank");
                  setUrl("");
                  setMailboxTest("");
                  // close this so we do not oversave the mailbox data
                  onClose();
                }}
              >
                Open Authorization URL
              </Button>
            )}

            {!mailbox.needsAuthorization && (
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => {
                  setUrl("");
                  setMailboxTest("Testing...");
                  Services.mailBoxService
                    .testEmail(Global.getJwtToken()!, mailbox.emailAddress!)
                    .then((data) => {
                      setMailboxTest(data);
                    });
                }}
              >
                Test Mail Server
              </Button>
            )}
          </Box>
        </Box>
        <Box sx={{ pl: 4, pr: 4, pt: 2 }}>
          <Typography variant="subtitle2">Edit Mailbox</Typography>
          <TextField
            id="emailAddress"
            variant="filled"
            fullWidth
            hiddenLabel
            type="email"
            value={mailbox.emailAddress ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              if (isNew) {
                setMailbox({
                  ...mailbox,
                  emailAddress: e.target.value,
                });
              }
            }}
          />

          <Tabs
            value={subpage}
            onChange={(event, value) => {
              setSubpage(value);
            }}
            sx={{ borderColor: "divider" }}
          >
            <Tab label="General" value={MailboxSubPage.General} />
            <Tab label="Rules" value={MailboxSubPage.Rules} />
            <Tab label="Email Server" value={MailboxSubPage.EmailServer} />
            <Tab label="ERP Setup" value={MailboxSubPage.ERP} />
            <Tab label="Prompt" value={MailboxSubPage.Prompt} />
          </Tabs>

          {subpage === MailboxSubPage.General && (
            <Box padding={2} border={1} margin={0} borderColor={"#C1C4C9"}>
              <Typography variant="subtitle2">Email Type</Typography>
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                id="emailType"
                name="emailType"
                select
                value={mailbox!.emailType || "sales"}
                onChange={(e) => {
                  const updatedMailbox = {
                    ...mailbox,
                    emailType: e.target.value,
                  };
                  // @ts-ignore
                  setMailbox(updatedMailbox);
                }}
              >
                <MenuItem value="sales">Sales</MenuItem>
                <MenuItem value="support">Support</MenuItem>
              </TextField>
              <Box height={"10px"} />

              <FormControl
                component="fieldset"
                fullWidth
                margin="none"
                // className="mailbox-modal__form-control"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "baseline",
                  justifyContent: "start",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={mailbox!.autoCheck || false}
                      onChange={(e) =>
                        setMailbox({
                          ...mailbox,
                          autoCheck: e.target.checked,
                        })
                      }
                      className="mailbox-modal__checkbox"
                    />
                  }
                  label="Auto Check"
                  className="mailbox-modal__checkbox-label"
                  labelPlacement="end"
                />
              </FormControl>

              <Box height={"10px"} />
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                id="emailHandleStrategy"
                name="emailHandleStrategy"
                label="Email Handle Strategy"
                select
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
                value={mailbox!.emailHandleStrategy || ""}
                onChange={(e) =>
                  setMailbox({
                    ...mailbox,
                    // @ts-ignore
                    emailHandleStrategy: e.target.value,
                  })
                }
              >
                {Object.values(EmailHandleType).map((strategy) => (
                  <MenuItem key={strategy} value={strategy}>
                    {strategy}
                  </MenuItem>
                ))}
              </TextField>
              <Box height={"10px"} />
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                type="email"
                id="forwardEmail"
                name="forwardEmail"
                label="Forward Email"
                value={mailbox!.forwardEmail}
                required={
                  mailbox.emailHandleStrategy
                    ?.toLowerCase()
                    .indexOf("Forward") !== -1
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const updatedMailbox = {
                    ...mailbox,
                    forwardEmail: e.target.value,
                  };
                  setMailbox(updatedMailbox);
                }}
              />

              {/* <TextField
              fullWidth
              margin="none"
              variant="outlined"
              hiddenLabel
              type="email"
              id="responsibleContactEmail"
              name="responsibleContactEmail"
              value={mailbox!.responsibleContactEmail}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                const updatedMailbox = {
                  ...mailbox,
                  responsibleContactEmail: e.target.value,
                };
                setMailbox(updatedMailbox);
              }}
            /> */}
            </Box>
          )}

          {subpage === MailboxSubPage.Rules && (
            <Box padding={2} border={1} margin={0} borderColor={"#C1C4C9"}>
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                type="number"
                id="autoReplyThreshold"
                name="autoReplyThreshold"
                label="Auto Reply Threshold"
                value={mailbox!.autoReplyThreshold}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const updatedMailbox = {
                    ...mailbox,
                    autoReplyThreshold: parseInt(e.target.value),
                  };
                  setMailbox(updatedMailbox);
                }}
                sx={{
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                }}
              />

              <Box height={"10px"} />

              <TextField
                fullWidth
                margin="none"
                variant="filled"
                type="number"
                id="targetPriceAutoReplyThreshold"
                name="targetPriceAutoReplyThreshold"
                label="Target Price Auto Reply Threshold"
                value={mailbox!.targetPriceAutoReplyThreshold}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const updatedMailbox = {
                    ...mailbox,
                    targetPriceAutoReplyThreshold: parseInt(e.target.value),
                  };
                  setMailbox(updatedMailbox);
                }}
                sx={{ border: "1px solid #ccc", borderRadius: "4px" }}
              />
            </Box>
          )}

          {subpage === MailboxSubPage.EmailServer && (
            <Box padding={2} border={1} margin={0} borderColor={"#C1C4C9"}>
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                id="emailGeneratorPlugin"
                name="emailGeneratorPlugin"
                label="Email Generator Plugin"
                select
                value={mailbox!.emailGeneratorPlugin || ""}
                onChange={(e) =>
                  setMailbox({
                    ...mailbox,
                    emailGeneratorPlugin: e.target.value,
                  })
                }
              >
                {Global.getGlobal().emailGeneratorPluginList!.map((plugin) => (
                  <MenuItem key={plugin} value={plugin}>
                    {plugin}
                  </MenuItem>
                ))}
              </TextField>
              <Box height={"10px"} />
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                id="emailParserPlugin"
                name="emailParserPlugin"
                label="Email Parser Plugin"
                select
                value={mailbox!.emailParserPlugin || ""}
                onChange={(e) =>
                  setMailbox({
                    ...mailbox,
                    emailParserPlugin: e.target.value,
                  })
                }
              >
                {Global.getGlobal().emailParserPluginList!.map((plugin) => (
                  <MenuItem key={plugin} value={plugin}>
                    {plugin}
                  </MenuItem>
                ))}
              </TextField>
              <Box height={"10px"} />
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                id="emailInterfacePlugin"
                name="emailInterfacePlugin"
                label="Email Interface Plugin"
                select
                value={mailbox!.emailInterfacePlugin || ""}
                onChange={(e) =>
                  setMailbox({
                    ...mailbox,
                    emailInterfacePlugin: e.target.value,
                  })
                }
              >
                {Global.getGlobal().emailInterfacePluginList!.map((plugin) => (
                  <MenuItem key={plugin} value={plugin}>
                    {plugin}
                  </MenuItem>
                ))}
              </TextField>

              <Box height={"10px"} />
              <DynamicEditor
                value={mailbox.mailSettings}
                metaData={emailMetaData}
                onChange={(value) =>
                  setMailbox({
                    ...mailbox,
                    mailSettings: value,
                  })
                }
              />
            </Box>
          )}

          {subpage === MailboxSubPage.ERP && (
            <Box padding={2} border={1} margin={0} borderColor={"#C1C4C9"}>
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                id="erpInterfacePlugin"
                name="erpInterfacePlugin"
                label="ERP Interface Plugin"
                select
                value={mailbox!.erpInterfacePlugin || ""}
                onChange={(e) =>
                  setMailbox({
                    ...mailbox,
                    erpInterfacePlugin: e.target.value,
                  })
                }
              >
                {Global.getGlobal().erpInterfacePluginList!.map((plugin) => (
                  <MenuItem key={plugin} value={plugin}>
                    {plugin}
                  </MenuItem>
                ))}
              </TextField>
              <Box height={"10px"} />
              <DynamicEditor
                value={mailbox.erpSettings}
                metaData={erpMetaData}
                onChange={(value) =>
                  setMailbox({
                    ...mailbox,
                    erpSettings: value,
                  })
                }
              />
            </Box>
          )}
          {subpage === MailboxSubPage.Prompt && (
            <Box padding={2} border={1} margin={0} borderColor={"#C1C4C9"}>
              <TextField
                fullWidth
                margin="none"
                variant="filled"
                type="text"
                id="currentPrompt"
                name="currentPrompt"
                label="Current Prompt"
                multiline
                rows={8}
                value={mailbox!.currentPrompt}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const updatedMailbox = {
                    ...mailbox,
                    currentPrompt: e.target.value,
                  };
                  setMailbox(updatedMailbox);
                }}
              />
            </Box>
          )}
        </Box>

        <Button
          variant="contained"
          sx={{ mt: 2, width: "120px", marginLeft: "auto", mr: 4, mb: 2 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Modal>
  );
};

function DynamicEditor(props: {
  value?: DynamicSetting[];
  metaData: PluginConfig;
  onChange: (value: any) => void;
}): JSX.Element {
  // check to make sure each value in metadata.fields is represented in value
  // if not, add it to value

  let value: DynamicSetting[] = props.value ?? [];

  if (!props.metaData || !props.metaData.fields) {
    return <div>No Settings</div>;
  } else {
    if (!value) value = [];

    for (let i of props.metaData.fields ?? []) {
      if (value.find((v) => v.name === i.name) === undefined) {
        value.push({ name: i.name!, value: "" });
      }
    }
    return (
      <div>
        {props.metaData.fields.map((field) => (
          <>
            <DynamicField
              key={field.name}
              field={field}
              value={value!.find((v) => v.name === field.name)!}
              onChange={() => {
                props.onChange(value);
              }}
            />
            <Box height={"10px"} />
          </>
        ))}
      </div>
    );
  }
}

function DynamicField(props: {
  field: PluginConfigDataDef;
  value: DynamicSetting;
  onChange: (value: any) => void;
}): JSX.Element {
  const field = props.field;
  const value = props.value;
  const onChange = props.onChange;

  return (
    <FormControl fullWidth margin="none" variant="filled">
      <TextField
        variant="filled"
        label={field.description}
        type={field.dataType}
        id={field.name}
        name={field.name}
        value={value.value ?? ""}
        onChange={(e) => {
          value.value = e.target.value;
          onChange(value);
        }}
      />
    </FormControl>
  );
}

export default MailboxModal;
