import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  InputAdornment,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import EmailState from "../components/EmailState";
import "./EmailList.css";
import EmailItem from "../components/EmailItem";
import { Services } from "../Services";
import { EmailStatus } from "../services/models/EmailStatus";
import { EmailSummary } from "../services/models/EmailSummary";
import { Global } from "../Global";
import { Email } from "../services/models";
import EmailEditLayer from "./email/layers/EmailEditLayer";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";

function EmailList(props: { mailboxName: string }): JSX.Element {
  const token = Global.getJwtToken()!;
  const EmailStatusArray = Object.values(EmailStatus);
  const statusConfig = {
    AutoProcessed: {
      displaytitle: "Auto",
      icon: "email-auto-respond-icon.png",
      color: "#00A218",
    },
    ManualProcessed: {
      displaytitle: "Manual",
      icon: "email-manual-respond-icon.png",
      color: "#FF9D00",
    },
    NotProcessed: {
      displaytitle: "Could Not Respond",
      icon: "email-not-respond-icon.png",
      color: "#e2cb00",
    },
    Ignored: {
      displaytitle: "Ignored",
      icon: "email-ignore-icon.png",
      color: "#FF0004",
    },
    IssueReported: {
      displaytitle: "With Issues",
      icon: "email-annotated-icon.png",
      color: "red",
    },
    All: {
      displaytitle: "All E-Mails",
      icon: "",
      color: "#2DC4A9", // Assuming a default color for "All"
    },
  };

  const mailboxName = props.mailboxName;

  const emailStates = EmailStatusArray.map((status, index) => ({
    id: index + 1,
    status: status,
    title: status,
    displaytitle: statusConfig[status].displaytitle,
    icon: statusConfig[status].icon,
    color: statusConfig[status].color,
  }));

  const [page, setPage] = useState<number>(1);
  const pageSize = 10;

  const [filter, setFilter] = useState<EmailStatus>(EmailStatus.All);

  const [emailToEdit, setEmailToEdit] = React.useState<Email | null>(null);
  const [lastEmailToEdit, setLastEmailToEdit] = React.useState<Email | null>(
    null,
  );

  const [filteredEmailList, setFilteredEmailList] = React.useState<
    EmailSummary[]
  >([]);

  const [searchValue, setSearchValue] = React.useState<string>("");

  useEffect(() => {
    if (mailboxName === undefined) {
      setFilteredEmailList([]);
    } else {
      fetchEmails(filter, searchValue, page);
    }
  }, [searchValue, token, mailboxName, page]);

  const handleManualFilter = (status: EmailStatus) => {
    setFilter(status);
    fetchEmails(status, searchValue, page);
  };

  const handleClickItem = (data: EmailSummary) => {
    Services.emailService
      .getEmail(token, mailboxName!, data.id!)
      .then((email: Email) => {
        setEmailToEdit(email);
        setLastEmailToEdit(email);
      });
  };

  function fetchEmails(
    emailStatus: EmailStatus,
    searchValue: string,
    pg: number,
  ) {
    let beginDate: Date | undefined = undefined;
    let endDate: Date | undefined = undefined;

    if (searchValue !== "") {
      Services.emailService
        .searchEmails(
          Global.getJwtToken()!,
          mailboxName,
          searchValue,
          emailStatus,
          beginDate,
          endDate,
          pg,
          pageSize,
        )
        .then((emails: any) => {
          setFilteredEmailList(emails);
        })
        .catch((e: any) => {
          console.error(e);
        });
    } else {
      Services.emailService
        .getEmailsFiltered(
          Global.getJwtToken()!,
          mailboxName,
          emailStatus,
          beginDate,
          endDate,
          pg,
          pageSize,
        )
        .then((emails: any) => {
          setFilteredEmailList(emails);
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    // todo: should be done on server.
    event.preventDefault();
    setSearchValue(event.target.value);
    setPage(1);
  };

  return (
    <Box className="right-container">
      <Box className="email-container">
        <Box display={"flex"} justifyContent="space-between">
          <Box className="email-states-container">
            {emailStates.map((item) => (
              <EmailState
                manualFilter={handleManualFilter}
                activeStatus={filter}
                key={item.id}
                data={item}
              />
            ))}
          </Box>
          <Button
            variant="contained"
            sx={{ bgcolor: "#4C4E8D", height: 45 }}
            onClick={() => {
              Services.emailService.checkEmail(
                Global.getJwtToken()!,
                Global.getGlobal().CurrentMailbox,
              );
            }}
          >
            Check Now
          </Button>
        </Box>

        <Box className="email-list-container">
          <Box className="email-list-top">
            <Box border={"1px solid lightgray"} borderRadius={"4px"}>
              {page > 1 && (
                <Button
                  variant="text"
                  onClick={() => {
                    if (page > 1) {
                      setPage(page - 1);
                    }
                  }}
                >
                  Prev
                </Button>
              )}

              {page === 1 && (
                <Button style={{ color: "lightgray" }} variant="text">
                  Prev
                </Button>
              )}

              {filteredEmailList.length === pageSize && (
                <Button
                  variant="text"
                  onClick={() => {
                    setPage(page + 1);
                  }}
                >
                  Next
                </Button>
              )}

              {filteredEmailList.length !== pageSize && (
                <Button style={{ color: "lightgray" }} variant="text">
                  Next
                </Button>
              )}

              <Typography
                fontSize={"15px"}
                sx={{ color: "gray" }}
                component="span"
                marginRight={1}
              >
                {"  Pg: "}
                {page}
              </Typography>
            </Box>
            <FormControl sx={{ minWidth: 300 }}>
              <TextField
                id="search-listing"
                label=""
                placeholder="Search Listing"
                variant="standard"
                onChange={handleSearch}
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon style={{ color: "#B3C0BC" }} />
                      </InputAdornment>
                    ),
                  },
                }}
                className="search-listing"
              />
            </FormControl>
          </Box>
          <Box className="email-list-header">
            <Box className="email-list-sort" style={{ flex: 2 }}>
              <Typography className="email-list-sort-title">
                Mail Type
              </Typography>
            </Box>
            <Box className="email-list-sort" style={{ flex: 2 }}>
              <Typography className="email-list-sort-title">
                Customer Name
              </Typography>
            </Box>
            <Box style={{ flex: 4 }}>
              <Typography className="email-list-sort-title">Email</Typography>
            </Box>
            <Box
              className="email-list-sort"
              style={{ flex: 1, justifyContent: "end" }}
            >
              <Typography className="email-list-sort-title">
                Received
              </Typography>
            </Box>
          </Box>
          <Box>
            {filteredEmailList.map((item) => (
              <Box key={item.id} onClick={() => handleClickItem(item)}>
                <EmailItem
                  data={item}
                  isSelected={item.id === lastEmailToEdit?.id}
                />
              </Box>
            ))}
            {filteredEmailList.length === 0 && (
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"center"}
                alignContent={"center"}
                alignItems={"center"}
                width={"100%"}
                height={"300px"}
              >
                <p>No Emails</p>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      {emailToEdit && (
        <EmailEditLayer
          email={emailToEdit}
          onClose={() => {
            setEmailToEdit(null);
            fetchEmails(filter, searchValue, page);
          }}
        />
      )}
    </Box>
  );
}

export default EmailList;
