/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  EmailAddressRules,
} from '../models/index';
import {
    EmailAddressRulesFromJSON,
    EmailAddressRulesToJSON,
} from '../models/index';

export interface BlockUserRequest {
    authorization: string;
    emailAddress: string;
}

export interface CreateEmailAddressRulesDataRequest {
    authorization: string;
}

export interface DeleteEmailAddressRulesRequest {
    authorization: string;
    id: string;
}

export interface DeleteEmailAddressRulesByAddressRequest {
    authorization: string;
    emailAddress: string;
}

export interface GetEmailAddressRuleForAddressRequest {
    authorization: string;
    emailAddress: string;
}

export interface GetEmailAddressRulesRequest {
    authorization: string;
}

export interface GetEmailAddressRulesByIdRequest {
    authorization: string;
    id: string;
}

export interface SaveEmailAddressRulesRequest {
    authorization: string;
    emailAddressRules: EmailAddressRules;
}

/**
 * 
 */
export class EmailAddressRulesApi extends runtime.BaseAPI {

    /**
     */
    async blockUserRaw(requestParameters: BlockUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAddressRules>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling blockUser().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling blockUser().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/blockUser`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAddressRulesFromJSON(jsonValue));
    }

    /**
     */
    async blockUser(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAddressRules> {
        const response = await this.blockUserRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createEmailAddressRulesDataRaw(requestParameters: CreateEmailAddressRulesDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAddressRules>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling createEmailAddressRulesData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/createEmailAddressRulesData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAddressRulesFromJSON(jsonValue));
    }

    /**
     */
    async createEmailAddressRulesData(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAddressRules> {
        const response = await this.createEmailAddressRulesDataRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteEmailAddressRulesRaw(requestParameters: DeleteEmailAddressRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling deleteEmailAddressRules().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteEmailAddressRules().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/deleteEmailAddressRules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEmailAddressRules(authorization: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmailAddressRulesRaw({ authorization: authorization, id: id }, initOverrides);
    }

    /**
     */
    async deleteEmailAddressRulesByAddressRaw(requestParameters: DeleteEmailAddressRulesByAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling deleteEmailAddressRulesByAddress().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling deleteEmailAddressRulesByAddress().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/deleteEmailAddressRulesByAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteEmailAddressRulesByAddress(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteEmailAddressRulesByAddressRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
    }

    /**
     */
    async getEmailAddressRuleForAddressRaw(requestParameters: GetEmailAddressRuleForAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAddressRules>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailAddressRuleForAddress().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling getEmailAddressRuleForAddress().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/getEmailAddressRuleForAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAddressRulesFromJSON(jsonValue));
    }

    /**
     */
    async getEmailAddressRuleForAddress(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAddressRules> {
        const response = await this.getEmailAddressRuleForAddressRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailAddressRulesRaw(requestParameters: GetEmailAddressRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmailAddressRules>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailAddressRules().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/getEmailAddressRules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailAddressRulesFromJSON));
    }

    /**
     */
    async getEmailAddressRules(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmailAddressRules>> {
        const response = await this.getEmailAddressRulesRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailAddressRulesByIdRaw(requestParameters: GetEmailAddressRulesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<EmailAddressRules>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailAddressRulesById().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEmailAddressRulesById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/getEmailAddressRulesById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailAddressRulesFromJSON(jsonValue));
    }

    /**
     */
    async getEmailAddressRulesById(authorization: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<EmailAddressRules> {
        const response = await this.getEmailAddressRulesByIdRaw({ authorization: authorization, id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveEmailAddressRulesRaw(requestParameters: SaveEmailAddressRulesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling saveEmailAddressRules().'
            );
        }

        if (requestParameters['emailAddressRules'] == null) {
            throw new runtime.RequiredError(
                'emailAddressRules',
                'Required parameter "emailAddressRules" was null or undefined when calling saveEmailAddressRules().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/EmailAddressRules/saveEmailAddressRules`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: EmailAddressRulesToJSON(requestParameters['emailAddressRules']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveEmailAddressRules(authorization: string, emailAddressRules: EmailAddressRules, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveEmailAddressRulesRaw({ authorization: authorization, emailAddressRules: emailAddressRules }, initOverrides);
    }

}
