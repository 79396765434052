/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailType = {
    Sales: 'sales',
    Support: 'support',
    ManualReplyMonitoring: 'manualReplyMonitoring'
} as const;
export type EmailType = typeof EmailType[keyof typeof EmailType];


export function instanceOfEmailType(value: any): boolean {
    for (const key in EmailType) {
        if (Object.prototype.hasOwnProperty.call(EmailType, key)) {
            if (EmailType[key as keyof typeof EmailType] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EmailTypeFromJSON(json: any): EmailType {
    return EmailTypeFromJSONTyped(json, false);
}

export function EmailTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailType {
    return json as EmailType;
}

export function EmailTypeToJSON(value?: EmailType | null): any {
    return value as any;
}

