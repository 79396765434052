/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationData,
  FinishAuthData,
  MailBox,
  PluginConfig,
} from '../models/index';
import {
    AuthorizationDataFromJSON,
    AuthorizationDataToJSON,
    FinishAuthDataFromJSON,
    FinishAuthDataToJSON,
    MailBoxFromJSON,
    MailBoxToJSON,
    PluginConfigFromJSON,
    PluginConfigToJSON,
} from '../models/index';

export interface AddMailBoxRequest {
    authorization: string;
    emailAddress: string;
}

export interface BeginAuthorizationRequest {
    authorization: string;
    mailbox: string;
}

export interface CreateMailboxDataRequest {
    authorization: string;
}

export interface DeleteMailBoxRequest {
    authorization: string;
    emailAddress: string;
}

export interface FinishAuthorizationRequest {
    authorization: string;
    finishAuthData: FinishAuthData;
}

export interface GetERPSytemInterfacePluginConfigDefinitionRequest {
    authorization: string;
    pluginName: string;
}

export interface GetERPSytemInterfacePluginListRequest {
    authorization: string;
}

export interface GetEmailGeneratorPluginListRequest {
    authorization: string;
}

export interface GetEmailInterfaceConfigDefinitionRequest {
    authorization: string;
    pluginName: string;
}

export interface GetEmailInterfacePluginListRequest {
    authorization: string;
}

export interface GetEmailParserPluginListRequest {
    authorization: string;
}

export interface GetMailBoxByEmailAddressRequest {
    authorization: string;
    emailAddress: string;
}

export interface GetMailBoxByIdRequest {
    authorization: string;
    id: string;
}

export interface GetMailBoxesRequest {
    authorization: string;
}

export interface SaveMailBoxRequest {
    authorization: string;
    mailBox: MailBox;
}

export interface TestEmailRequest {
    authorization: string;
    mailbox: string;
}

/**
 * 
 */
export class MailBoxApi extends runtime.BaseAPI {

    /**
     */
    async addMailBoxRaw(requestParameters: AddMailBoxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling addMailBox().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling addMailBox().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/addMailBox`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async addMailBox(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addMailBoxRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
    }

    /**
     */
    async beginAuthorizationRaw(requestParameters: BeginAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AuthorizationData>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling beginAuthorization().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling beginAuthorization().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/beginAuthorization`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationDataFromJSON(jsonValue));
    }

    /**
     */
    async beginAuthorization(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AuthorizationData> {
        const response = await this.beginAuthorizationRaw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     */
    async createMailboxDataRaw(requestParameters: CreateMailboxDataRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailBox>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling createMailboxData().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/createMailBoxData`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailBoxFromJSON(jsonValue));
    }

    /**
     */
    async createMailboxData(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailBox> {
        const response = await this.createMailboxDataRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteMailBoxRaw(requestParameters: DeleteMailBoxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling deleteMailBox().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling deleteMailBox().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/deleteMailBox`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteMailBox(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteMailBoxRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
    }

    /**
     */
    async finishAuthorizationRaw(requestParameters: FinishAuthorizationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling finishAuthorization().'
            );
        }

        if (requestParameters['finishAuthData'] == null) {
            throw new runtime.RequiredError(
                'finishAuthData',
                'Required parameter "finishAuthData" was null or undefined when calling finishAuthorization().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/finishAuthorization`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: FinishAuthDataToJSON(requestParameters['finishAuthData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async finishAuthorization(authorization: string, finishAuthData: FinishAuthData, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.finishAuthorizationRaw({ authorization: authorization, finishAuthData: finishAuthData }, initOverrides);
    }

    /**
     */
    async getERPSytemInterfacePluginConfigDefinitionRaw(requestParameters: GetERPSytemInterfacePluginConfigDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PluginConfig>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getERPSytemInterfacePluginConfigDefinition().'
            );
        }

        if (requestParameters['pluginName'] == null) {
            throw new runtime.RequiredError(
                'pluginName',
                'Required parameter "pluginName" was null or undefined when calling getERPSytemInterfacePluginConfigDefinition().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pluginName'] != null) {
            queryParameters['pluginName'] = requestParameters['pluginName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getERPSytemInterfacePluginConfigDefinition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PluginConfigFromJSON(jsonValue));
    }

    /**
     */
    async getERPSytemInterfacePluginConfigDefinition(authorization: string, pluginName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PluginConfig> {
        const response = await this.getERPSytemInterfacePluginConfigDefinitionRaw({ authorization: authorization, pluginName: pluginName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getERPSytemInterfacePluginListRaw(requestParameters: GetERPSytemInterfacePluginListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getERPSytemInterfacePluginList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getERPSytemInterfacePluginList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getERPSytemInterfacePluginList(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getERPSytemInterfacePluginListRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailGeneratorPluginListRaw(requestParameters: GetEmailGeneratorPluginListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailGeneratorPluginList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getEmailGeneratorPluginList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getEmailGeneratorPluginList(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getEmailGeneratorPluginListRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailInterfaceConfigDefinitionRaw(requestParameters: GetEmailInterfaceConfigDefinitionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PluginConfig>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailInterfaceConfigDefinition().'
            );
        }

        if (requestParameters['pluginName'] == null) {
            throw new runtime.RequiredError(
                'pluginName',
                'Required parameter "pluginName" was null or undefined when calling getEmailInterfaceConfigDefinition().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pluginName'] != null) {
            queryParameters['pluginName'] = requestParameters['pluginName'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getEmailInterfaceConfigDefinition`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PluginConfigFromJSON(jsonValue));
    }

    /**
     */
    async getEmailInterfaceConfigDefinition(authorization: string, pluginName: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PluginConfig> {
        const response = await this.getEmailInterfaceConfigDefinitionRaw({ authorization: authorization, pluginName: pluginName }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailInterfacePluginListRaw(requestParameters: GetEmailInterfacePluginListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailInterfacePluginList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getEmailInterfacePluginList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getEmailInterfacePluginList(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getEmailInterfacePluginListRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailParserPluginListRaw(requestParameters: GetEmailParserPluginListRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<string>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailParserPluginList().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getEmailParserPluginList`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async getEmailParserPluginList(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<string>> {
        const response = await this.getEmailParserPluginListRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMailBoxByEmailAddressRaw(requestParameters: GetMailBoxByEmailAddressRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailBox>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getMailBoxByEmailAddress().'
            );
        }

        if (requestParameters['emailAddress'] == null) {
            throw new runtime.RequiredError(
                'emailAddress',
                'Required parameter "emailAddress" was null or undefined when calling getMailBoxByEmailAddress().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['emailAddress'] != null) {
            queryParameters['emailAddress'] = requestParameters['emailAddress'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getMailBoxByEmailAddress`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailBoxFromJSON(jsonValue));
    }

    /**
     */
    async getMailBoxByEmailAddress(authorization: string, emailAddress: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailBox> {
        const response = await this.getMailBoxByEmailAddressRaw({ authorization: authorization, emailAddress: emailAddress }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMailBoxByIdRaw(requestParameters: GetMailBoxByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MailBox>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getMailBoxById().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getMailBoxById().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getMailBoxById`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MailBoxFromJSON(jsonValue));
    }

    /**
     */
    async getMailBoxById(authorization: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MailBox> {
        const response = await this.getMailBoxByIdRaw({ authorization: authorization, id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getMailBoxesRaw(requestParameters: GetMailBoxesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<MailBox>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getMailBoxes().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/getMailBoxes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MailBoxFromJSON));
    }

    /**
     */
    async getMailBoxes(authorization: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<MailBox>> {
        const response = await this.getMailBoxesRaw({ authorization: authorization }, initOverrides);
        return await response.value();
    }

    /**
     */
    async saveMailBoxRaw(requestParameters: SaveMailBoxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling saveMailBox().'
            );
        }

        if (requestParameters['mailBox'] == null) {
            throw new runtime.RequiredError(
                'mailBox',
                'Required parameter "mailBox" was null or undefined when calling saveMailBox().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/saveMailBox`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MailBoxToJSON(requestParameters['mailBox']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async saveMailBox(authorization: string, mailBox: MailBox, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.saveMailBoxRaw({ authorization: authorization, mailBox: mailBox }, initOverrides);
    }

    /**
     */
    async testEmailRaw(requestParameters: TestEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling testEmail().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling testEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/MailBox/testEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async testEmail(authorization: string, mailbox: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.testEmailRaw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

}
