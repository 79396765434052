/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailFile } from './EmailFile';
import {
    EmailFileFromJSON,
    EmailFileFromJSONTyped,
    EmailFileToJSON,
} from './EmailFile';
import type { EmailAddress } from './EmailAddress';
import {
    EmailAddressFromJSON,
    EmailAddressFromJSONTyped,
    EmailAddressToJSON,
} from './EmailAddress';

/**
 * 
 * @export
 * @interface EmailData
 */
export interface EmailData {
    /**
     * 
     * @type {string}
     * @memberof EmailData
     */
    id?: string;
    /**
     * 
     * @type {EmailAddress}
     * @memberof EmailData
     */
    from?: EmailAddress;
    /**
     * 
     * @type {Array<EmailAddress>}
     * @memberof EmailData
     */
    to?: Array<EmailAddress>;
    /**
     * 
     * @type {string}
     * @memberof EmailData
     */
    subject?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailData
     */
    date?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailData
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailData
     */
    htmlBody?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailData
     */
    bodyIsHtml?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmailData
     */
    inReplyTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EmailData
     */
    isTestEmail?: boolean;
    /**
     * 
     * @type {Array<EmailFile>}
     * @memberof EmailData
     */
    files?: Array<EmailFile>;
}

/**
 * Check if a given object implements the EmailData interface.
 */
export function instanceOfEmailData(value: object): value is EmailData {
    return true;
}

export function EmailDataFromJSON(json: any): EmailData {
    return EmailDataFromJSONTyped(json, false);
}

export function EmailDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailData {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'from': json['from'] == null ? undefined : EmailAddressFromJSON(json['from']),
        'to': json['to'] == null ? undefined : ((json['to'] as Array<any>).map(EmailAddressFromJSON)),
        'subject': json['subject'] == null ? undefined : json['subject'],
        'date': json['date'] == null ? undefined : (new Date(json['date'])),
        'body': json['body'] == null ? undefined : json['body'],
        'htmlBody': json['htmlBody'] == null ? undefined : json['htmlBody'],
        'bodyIsHtml': json['bodyIsHtml'] == null ? undefined : json['bodyIsHtml'],
        'inReplyTo': json['inReplyTo'] == null ? undefined : json['inReplyTo'],
        'isTestEmail': json['isTestEmail'] == null ? undefined : json['isTestEmail'],
        'files': json['files'] == null ? undefined : ((json['files'] as Array<any>).map(EmailFileFromJSON)),
    };
}

export function EmailDataToJSON(value?: EmailData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'from': EmailAddressToJSON(value['from']),
        'to': value['to'] == null ? undefined : ((value['to'] as Array<any>).map(EmailAddressToJSON)),
        'subject': value['subject'],
        'date': value['date'] == null ? undefined : ((value['date']).toISOString()),
        'body': value['body'],
        'htmlBody': value['htmlBody'],
        'bodyIsHtml': value['bodyIsHtml'],
        'inReplyTo': value['inReplyTo'],
        'isTestEmail': value['isTestEmail'],
        'files': value['files'] == null ? undefined : ((value['files'] as Array<any>).map(EmailFileToJSON)),
    };
}

