import Sidebar from "../frame/Sidebar";
import Header from "../frame/Header";
import { Global, Pages } from "../Global";
import { ObserveState } from "../StateManagement/ObserveState";
import EmailList from "../pages/EmailList";
import { CompaniesList } from "../pages/CompaniesList";
import { MailboxList } from "../pages/MailboxList";
import { EmailAddressRulesList } from "../pages/EmailAddressRulesList";
import { UsersList } from "../pages/UsersList";
import QuoteTool from "../pages/QuoteTool";
import ParseTool from "../pages/ParseTool";
import Dashboard from "../pages/Dashboard";
import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { PortalInfo } from "../services/models/PortalInfo";

function RequireAuth() {
  const [portalInfo, setPortalInfo] = useState<PortalInfo | undefined>(
    undefined,
  );

  let token = Global.getJwtToken();

  useEffect(() => {
    Global.getPortalInfo().then((portalInfo) => {
      setPortalInfo(portalInfo);
    });
  }, [token]);

  return (
    <div className="main-container">
      <Sidebar />
      <div className="main-body">
        {portalInfo && portalInfo.showPortalMessage && (
          <Box
            display={"flex"}
            alignContent={"center"}
            justifyContent={"center"}
            height={"30px"}
            bgcolor={portalInfo.portalMessageColor}
            fontStyle={"bold"}
            fontSize={"20px"}
          >
            {portalInfo.portalMessage} - {portalInfo.version}
          </Box>
        )}
        <Header />
        <ObserveState
          listenTo={Global.getGlobal()}
          control={() => {
            switch (Global.getGlobal().CurrentPage) {
              case Pages.Dashboard:
                return <Dashboard />;
              case Pages.EmailList:
                return (
                  <ObserveState
                    listenTo={Global.getGlobal()}
                    properties={["CurrentMailbox"]}
                    control={() => {
                      return (
                        <EmailList
                          mailboxName={Global.getGlobal().CurrentMailbox!}
                        />
                      );
                    }}
                  />
                );
              case Pages.UserList:
                return <UsersList />;
              case Pages.Companies:
                return <CompaniesList />;
              case Pages.MailBoxList:
                return <MailboxList />;
              case Pages.EmailAddressRules:
                return <EmailAddressRulesList />;
              case Pages.QuoteTool:
                return <QuoteTool />;
              case Pages.EmailParseTest:
                return <ParseTool />;
            }
          }}
        />
      </div>
    </div>
  );
}

export default RequireAuth;
