import React from "react";
import { ReactComponent as WelcomeUp } from "../assets/images/welcome-up.svg";
import { ReactComponent as WelcomeDown } from "../assets/images/welcome-down.svg";
import "./AuthLeft.css";

interface AuthLeftProps {
  title: string;
  description: string;
}

function AuthLeft({ title, description }: AuthLeftProps) {
  return (
    <div className="authleft-container">
      <WelcomeUp className="welcome-up" />
      <h1>{title}</h1>
      <p>{description}</p>
      <WelcomeDown className="welcome-down" />
    </div>
  );
}

export default AuthLeft;
