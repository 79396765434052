/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PluginConfigDataDef } from './PluginConfigDataDef';
import {
    PluginConfigDataDefFromJSON,
    PluginConfigDataDefFromJSONTyped,
    PluginConfigDataDefToJSON,
} from './PluginConfigDataDef';

/**
 * 
 * @export
 * @interface PluginConfig
 */
export interface PluginConfig {
    /**
     * 
     * @type {Array<PluginConfigDataDef>}
     * @memberof PluginConfig
     */
    fields?: Array<PluginConfigDataDef>;
}

/**
 * Check if a given object implements the PluginConfig interface.
 */
export function instanceOfPluginConfig(value: object): value is PluginConfig {
    return true;
}

export function PluginConfigFromJSON(json: any): PluginConfig {
    return PluginConfigFromJSONTyped(json, false);
}

export function PluginConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): PluginConfig {
    if (json == null) {
        return json;
    }
    return {
        
        'fields': json['fields'] == null ? undefined : ((json['fields'] as Array<any>).map(PluginConfigDataDefFromJSON)),
    };
}

export function PluginConfigToJSON(value?: PluginConfig | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'fields': value['fields'] == null ? undefined : ((value['fields'] as Array<any>).map(PluginConfigDataDefToJSON)),
    };
}

