/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AuthorizationData
 */
export interface AuthorizationData {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationData
     */
    url: string;
}

/**
 * Check if a given object implements the AuthorizationData interface.
 */
export function instanceOfAuthorizationData(value: object): value is AuthorizationData {
    if (!('message' in value) || value['message'] === undefined) return false;
    if (!('url' in value) || value['url'] === undefined) return false;
    return true;
}

export function AuthorizationDataFromJSON(json: any): AuthorizationData {
    return AuthorizationDataFromJSONTyped(json, false);
}

export function AuthorizationDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizationData {
    if (json == null) {
        return json;
    }
    return {
        
        'message': json['message'],
        'url': json['url'],
    };
}

export function AuthorizationDataToJSON(value?: AuthorizationData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'message': value['message'],
        'url': value['url'],
    };
}

