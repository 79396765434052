import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import "./UsersList.css";
import { User } from "../services/models";
import { Services } from "../Services";
import { UserListItem } from "./user/UserListItem";
import { NewUserModal } from "./user/NewUserModal";
import { Global } from "../Global";

export function UsersList(): JSX.Element {
  const [userList, setUserList] = useState<User[]>([]);
  const [userModalOpen, setUserModalOpen] = useState<boolean>(false);
  const [editUser, setEditUser] = useState<User>({});
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const fetchUsers = () => {
    Services.userService
      .getUsers(Global.getJwtToken()!, false)
      .then((users: User[]) => {
        setUserList(users);
      });
  };

  const token = Global.getJwtToken();

  useEffect(() => {
    fetchUsers();
  }, [token]);

  const handleUserClick = () => {
    setUserModalOpen(true);
  };

  const handleUserModalClose = () => {
    setUserModalOpen(false);
    setEditUser({});
    setTimeout(() => {
      fetchUsers();
    }, 200);
    Global.resetUser();
  };

  const handleEditUser = (user: User) => {
    setEditUser((prevUser) => ({ ...prevUser, ...user }));
    setUserModalOpen(true);
    setIsEdit(true);
  };

  const handleDeleteUser = (user: User) => {
    Services.userService
      .deleteUser(Global.getJwtToken()!, user.emailAddress!)
      .then(() => {
        fetchUsers();
      });
  };

  return (
    <div>
      <Box className="users-container">
        <Box className="users-actions">
          <Button
            variant="contained"
            color="primary"
            className="users-list__new-user-button"
            onClick={handleUserClick}
          >
            <Typography className="users-list__new-user-text">
              New User
            </Typography>
          </Button>
        </Box>
        <Box className="users-list">
          {userList.map((user: User) => (
            <UserListItem
              user={user}
              key={user.id}
              onDelete={handleDeleteUser}
              openEditModal={handleEditUser}
            />
          ))}
        </Box>
      </Box>
      <NewUserModal
        isEdit={isEdit}
        open={userModalOpen}
        onClose={handleUserModalClose}
        user={editUser}
      />
    </div>
  );
}
