/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProductDataInventoryDetail
 */
export interface ProductDataInventoryDetail {
    /**
     * 
     * @type {string}
     * @memberof ProductDataInventoryDetail
     */
    dateCode?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductDataInventoryDetail
     */
    qty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ProductDataInventoryDetail
     */
    authorizedChannel?: boolean;
}

/**
 * Check if a given object implements the ProductDataInventoryDetail interface.
 */
export function instanceOfProductDataInventoryDetail(value: object): value is ProductDataInventoryDetail {
    return true;
}

export function ProductDataInventoryDetailFromJSON(json: any): ProductDataInventoryDetail {
    return ProductDataInventoryDetailFromJSONTyped(json, false);
}

export function ProductDataInventoryDetailFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductDataInventoryDetail {
    if (json == null) {
        return json;
    }
    return {
        
        'dateCode': json['dateCode'] == null ? undefined : json['dateCode'],
        'qty': json['qty'] == null ? undefined : json['qty'],
        'authorizedChannel': json['authorizedChannel'] == null ? undefined : json['authorizedChannel'],
    };
}

export function ProductDataInventoryDetailToJSON(value?: ProductDataInventoryDetail | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'dateCode': value['dateCode'],
        'qty': value['qty'],
        'authorizedChannel': value['authorizedChannel'],
    };
}

