import { ChangeEvent, useEffect, useState } from "react";
import { EmailAddressRules, RuleType } from "../../services/models";
import { Services } from "../../Services";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Global } from "../../Global";

export type EmailAddressRuleModalProps = {
  open: boolean;
  onClose: () => void;
  emailAddressRule?: EmailAddressRules;
};

export function EmailAddressRulesModal(props: EmailAddressRuleModalProps) {
  const [emailAddressRule, setEmailAddressRule] = useState<EmailAddressRules>(
    {},
  );

  useEffect(() => {
    if (props.emailAddressRule) {
      setEmailAddressRule(props.emailAddressRule);
    } else {
      Services.emailAddressRuleService
        .createEmailAddressRulesData(Global.getJwtToken()!)
        .then((data) => {
          setEmailAddressRule(data);
        });
    }
  }, [props.emailAddressRule]);

  const handleClose = () => {
    setEmailAddressRule({
      addressWithWildcard: "",
      blockedReason: "",
    });
    props.onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEmailAddressRule((prevEmailAddressRule) => ({
      ...prevEmailAddressRule,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    Services.emailAddressRuleService.saveEmailAddressRules(
      Global.getJwtToken()!,
      emailAddressRule,
    );
    handleClose();
  };

  return (
    <Modal
      aria-labelledby="blocked-email-modal"
      aria-describedby="blocked-email-modal-description"
      open={props.open}
      onClose={handleClose}
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
    >
      <Box className="blocked-email-modal">
        <Box className="blocked-email-modal__header">
          <Typography
            component={"h2"}
            id="blocked-email-modal-title"
            className="blocked-email-modal__title"
          >
            {props.emailAddressRule ? "Edit Email Rule" : "Email Rule"}
          </Typography>
          <Button
            variant="text"
            onClick={handleClose}
            className="blocked-email-modal__close-button"
          >
            <Close />
          </Button>
        </Box>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="blocked-email-modal__form"
        >
          <TextField
            fullWidth
            required
            variant="filled"
            margin="none"
            label="Email Address"
            id="addressWithWildcard"
            name="addressWithWildcard"
            value={emailAddressRule.addressWithWildcard}
            onChange={handleChange}
            className="blocked-email-modal__input"
          />
          <TextField
            select
            fullWidth
            required
            variant="filled"
            margin="none"
            label="Rule Type"
            id="ruleType"
            name="ruleType"
            value={emailAddressRule.ruleType}
            onChange={handleChange}
            className="blocked-email-modal__input"
            SelectProps={{
              native: true,
            }}
          >
            <option value={RuleType.Block}>Block</option>
            <option value={RuleType.ForwardAll}>Forward All</option>
            <option value={RuleType.ForwardOverLimit}>
              Forward Over Limit
            </option>
          </TextField>

          {emailAddressRule.ruleType === RuleType.Block && (
            <TextField
              fullWidth
              required
              variant="filled"
              margin="none"
              label="Blocked Reason"
              id="blockedReason"
              name="blockedReason"
              value={emailAddressRule.blockedReason}
              onChange={handleChange}
              className="blocked-email-modal__input"
            />
          )}

          {emailAddressRule.ruleType !== RuleType.Block && (
            <TextField
              fullWidth
              required
              variant="filled"
              margin="none"
              label="Forward To"
              id="forwardTo"
              name="forwardTo"
              value={emailAddressRule.forwardTo}
              onChange={handleChange}
              className="blocked-email-modal__input"
            ></TextField>
          )}

          {emailAddressRule.ruleType === RuleType.ForwardOverLimit && (
            <TextField
              fullWidth
              required
              variant="filled"
              margin="none"
              label="Forward Over Limit"
              id="forwardOverLimit"
              name="forwardOverLimit"
              value={emailAddressRule.forwardOverLimit}
              onChange={handleChange}
              className="blocked-email-modal__input"
            ></TextField>
          )}

          <Button
            variant="contained"
            color="primary"
            className="blocked-email-modal__submit-button"
            type="submit"
          >
            <Typography className="blocked-email-modal__submit-button-text">
              Submit
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
