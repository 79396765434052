import { FC } from "react";
import { Company } from "../../services/models";
import { Box, Button, Typography } from "@mui/material";
import deleteIcon from "../../assets/images/material-symbols_delete.svg"
import editIcon from "../../assets/images/tabler_edit.svg"

type CompanyListItemProps = {   
    company: Company;
    openEditModal: (company: Company) => void;
    onDelete: (company: Company) => void;
}

export const CompanyListItem: FC<CompanyListItemProps> = ({ company, openEditModal, onDelete }) => {
    return (
        <Box className="companies-list-item">
            <Box>
                <Typography className="companies-list__company-name">
                    {company.companyName}
                </Typography>
            </Box>
            <Box>
                <Button variant="text" className="companies-list__action-button" onClick={() => openEditModal(company)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button variant="text" className="companies-list__action-button" onClick={() => onDelete(company)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </Box>
        </Box>
    );
};