/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const EmailStatus = {
    AutoProcessed: 'AutoProcessed',
    ManualProcessed: 'ManualProcessed',
    NotProcessed: 'NotProcessed',
    Ignored: 'Ignored',
    IssueReported: 'IssueReported',
    All: 'All'
} as const;
export type EmailStatus = typeof EmailStatus[keyof typeof EmailStatus];


export function instanceOfEmailStatus(value: any): boolean {
    for (const key in EmailStatus) {
        if (Object.prototype.hasOwnProperty.call(EmailStatus, key)) {
            if (EmailStatus[key as keyof typeof EmailStatus] === value) {
                return true;
            }
        }
    }
    return false;
}

export function EmailStatusFromJSON(json: any): EmailStatus {
    return EmailStatusFromJSONTyped(json, false);
}

export function EmailStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailStatus {
    return json as EmailStatus;
}

export function EmailStatusToJSON(value?: EmailStatus | null): any {
    return value as any;
}

