import { Box, Typography, Link, Button } from "@mui/material"
import { User } from "../../services/models"
import deleteIcon from "../../assets/images/material-symbols_delete.svg"
import editIcon from "../../assets/images/tabler_edit.svg"
import { FC } from "react";

interface UserListItemProps {
    user: User;
    onDelete: (user: User) => void;
    openEditModal: (user: User) => void;
}

export const UserListItem: FC<UserListItemProps> = ({ user, onDelete, openEditModal }) => {
    return (
        <Box className="user-list-item">
            <Box>
                <Typography className="users-list__user-name">
                    {user.firstName} {user.lastName}
                </Typography>
                <Link href={`mailto:${user.emailAddress}`} className="users-list__user-email" underline="hover">
                    {user.emailAddress}
                </Link>
            </Box>
            <Box>
                <Button variant="text" className="users-list__delete-button" onClick={() => openEditModal(user)}>
                    <img src={editIcon} alt="Edit" />
                </Button>
                <Button variant="text" className="users-list__delete-button" onClick={() => onDelete(user)}>
                    <img src={deleteIcon} alt="Delete" />
                </Button>
            </Box>
        </Box>
    )
}