import {
  Box,
  TextField,
  Button,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import React, { useState } from "react";
import { Services } from "../Services";
import { Global } from "../Global";
import { DisplayEmail } from "./DisplayEmail";
import {
  EmailParseRequest,
  EmailData,
  EmailParseResult,
} from "../services/models";

interface Product {
  id: number;
  name: string;
  quantity: number;
}

const QuoteTool: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([
    {
      id: Date.now(),
      name: "",
      quantity: 0,
    },
  ]);

  const [results, setResults] = useState<string | undefined>();
  const [fullResults, setFullResults] = useState<string | undefined>();
  const [resultType, setResultType] = useState<"email" | "data" | "full">(
    "email",
  );
  const [emailReply, setEmailReply] = useState<EmailData | undefined>();

  const [emailParseResults, setEmailParseResults] = useState<
    EmailParseResult | undefined
  >();

  if (
    products.length === 0 ||
    products.every((product) => product.name !== "" && product.quantity !== 0)
  ) {
    setProducts([
      ...products,
      {
        id: Date.now(),
        name: "",
        quantity: 0,
      },
    ]);
  }

  const handleDeleteProduct = (id: number) => {
    setProducts(products.filter((product) => product.id !== id));
  };

  const handleSubmitFull = () => {
    setEmailParseResults(undefined);
    setResults(undefined);
    setFullResults(undefined);
    setEmailReply(undefined);
    let parseRequest: EmailParseRequest = {
      mailboxName: Global.getGlobal().CurrentMailbox!,
      email: {
        subject: "Quote Request",
        body:
          "Please provide quote for following \n\n" +
          products
            .filter((p) => p.quantity !== 0)
            .map((p) => p.name + " qty: " + p.quantity)
            .join("\n"),
        from: { address: "test@test.com", name: "Joe" },
      },
    };

    Services.testService
      .parseEmail(Global.getJwtToken()!, parseRequest)
      .then((res) => {
        setFullResults(JSON.stringify(res, null, 2));
        setResults(JSON.stringify(res.productInfo));
        setEmailReply(res.email?.reply);
        setEmailParseResults(res);
      });
  };

  const handleClear = () => {
    setProducts([]);
    setResults(undefined);
  };

  function addRandom(): void {
    const randomQuantity = Math.floor(Math.random() * 1000) + 1;
    Services.testService
      .getRandomProductNames(
        Global.getJwtToken()!,
        Global.getGlobal().CurrentMailbox!,
      )
      .then((res) => {
        let p = [
          ...products,
          {
            id: Date.now(),
            name: res[Math.floor(Math.random() * res.length)],
            quantity: randomQuantity,
          },
        ];

        p.sort((a, b) => (a.quantity === 0 ? 1 : b.quantity === 0 ? -1 : 0));
        setProducts(p);
      });
  }

  return (
    <Box
      marginLeft={"10px"}
      marginTop={"10px"}
      display={"flex"}
      flexDirection={"row"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        paddingRight={"10px"}
        borderRight={"1px solid black"}
        sx={{ width: "50%" }}
      >
        <Box>
          {products.map((product, index) => (
            <Box key={product.id} marginTop="10px" display={"flex"}>
              <TextField
                label="Product Name"
                variant="filled"
                sx={{ width: "200px", paddingRight: "10px" }}
                value={product.name}
                onChange={(e) => {
                  const newProducts = [...products];
                  newProducts[index].name = e.target.value;
                  setProducts(newProducts);
                }}
              />
              <TextField
                label="Quantity"
                type="number"
                variant="filled"
                sx={{ width: "200px", paddingRight: "10px" }}
                value={product.quantity}
                onChange={(e) => {
                  const newProducts = [...products];
                  newProducts[index].quantity = e.target.value
                    ? Number(e.target.value)
                    : 0;
                  setProducts(newProducts);
                }}
              />
              <Button
                variant="text"
                onClick={() => handleDeleteProduct(product.id)}
              >
                Delete
              </Button>
            </Box>
          ))}
        </Box>
        <Box paddingLeft={"10px"} display="flex" gap="10px" marginTop="20px">
          <Button variant="contained" onClick={handleSubmitFull}>
            Submit
          </Button>
          <Button variant="text" onClick={handleClear}>
            Clear
          </Button>
          <Button variant="text" onClick={addRandom}>
            Add Random
          </Button>
        </Box>
      </Box>

      {results && (
        <Box sx={{ width: "50%" }} paddingLeft={"10px"}>
          <Typography variant="h5">Results:</Typography>
          <ToggleButtonGroup
            value={resultType}
            exclusive
            onChange={(event, newResultType) => {
              if (newResultType !== null) {
                setResultType(newResultType);
              }
            }}
            aria-label="result type"
          >
            <ToggleButton value="email" aria-label="email">
              Email
            </ToggleButton>
            <ToggleButton value="data" aria-label="data">
              Results
            </ToggleButton>
            <ToggleButton value="full" aria-label="full">
              Full Results
            </ToggleButton>
          </ToggleButtonGroup>

          <Box
            component="pre"
            sx={{
              whiteSpace: "pre-wrap",
              wordWrap: "break-word",
              maxHeight: "700px",
              overflowY: "auto",
            }}
          >
            {resultType === "email" && emailReply && (
              <Box display={"flex"} flexDirection={"column"}>
                {emailParseResults && (
                  <>
                    <Typography>
                      Time Taken:{" "}
                      {emailParseResults.email?.timeTakenInSeconds?.toFixed(3)}{" "}
                      seconds
                    </Typography>
                    <Typography>
                      Order Total: ${" "}
                      {emailParseResults.email?.totalValue?.toFixed(2)}
                    </Typography>
                    <Typography>
                      Action Proposed: {emailParseResults.email?.emailStatus}
                    </Typography>
                    {emailParseResults.email?.cannotReplyReason &&
                      emailParseResults.email?.cannotReplyReason?.length >
                        0 && (
                        <>
                          <br></br>
                          <Typography>Issues:</Typography>

                          <Typography
                            sx={{
                              color: "#ff0000",
                              border: "1px solid #ff0000",
                              backgroundColor: "#f8e7e7",
                              textAlign: "left",
                            }}
                          >
                            {emailParseResults.email?.cannotReplyReason?.map(
                              (reason) => (
                                <>
                                  <span key={reason}>- {reason}</span>
                                  <br></br>
                                </>
                              ),
                            )}
                          </Typography>
                        </>
                      )}
                  </>
                )}
                <Box sx={{ mb: 2 }}></Box>

                {emailReply && (
                  <DisplayEmail email={emailReply} isReply={true} />
                )}
              </Box>
            )}
            {resultType === "data" && results}
            {resultType === "full" && fullResults}
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default QuoteTool;
