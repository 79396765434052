import { Box, Button, Typography } from "@mui/material";
import { FC } from "react";
import { MailBox } from "../../services/models";
import deleteIcon from "../../assets/images/material-symbols_delete.svg";
import editIcon from "../../assets/images/tabler_edit.svg";

type MailboxListItemProps = {
  mailbox: MailBox;
  onDelete: (emailAddress: string) => void;
  openEditModal: (mailbox: MailBox) => void;
};

export const MailboxListItem: FC<MailboxListItemProps> = ({
  mailbox,
  onDelete,
  openEditModal,
}) => {
  return (
    <Box className="mailboxes-list-item">
      <Box>
        <Typography className="mailboxes-list__company-name">
          {mailbox.emailAddress}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          className="mailboxes-list__action-button"
          onClick={() => openEditModal(mailbox)}
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          variant="text"
          className="mailboxes-list__action-button"
          onClick={() => onDelete(mailbox.emailAddress ?? "")}
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </Box>
    </Box>
  );
};
