/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Email,
  EmailStatus,
  EmailSummary,
} from '../models/index';
import {
    EmailFromJSON,
    EmailToJSON,
    EmailStatusFromJSON,
    EmailStatusToJSON,
    EmailSummaryFromJSON,
    EmailSummaryToJSON,
} from '../models/index';

export interface CheckEmailRequest {
    authorization: string;
    mailbox?: string;
}

export interface ClearIssueRequest {
    authorization: string;
    mailbox: string;
    id: string;
}

export interface GetAllEmailsRequest {
    authorization: string;
    mailbox: string;
    fromDate?: Date;
    toDate?: Date;
    page?: number;
    pageNumber?: number;
}

export interface GetEmailRequest {
    authorization: string;
    mailbox: string;
    id: string;
}

export interface GetEmailsFilteredRequest {
    authorization: string;
    mailbox: string;
    emailStatus: EmailStatus;
    fromDate?: Date;
    toDate?: Date;
    page?: number;
    pageNumber?: number;
}

export interface IgnoreEmailRequest {
    authorization: string;
    mailbox: string;
    id: string;
}

export interface ModifyDraftRequest {
    authorization: string;
    mailbox: string;
    id: string;
    newSubject: string;
    newBody: string;
    sendEmail: boolean;
}

export interface ReportIssueRequest {
    authorization: string;
    mailbox: string;
    id: string;
    issue: string;
}

export interface SearchEmailsRequest {
    authorization: string;
    mailbox: string;
    searchText: string;
    emailStatus: EmailStatus;
    fromDate?: Date;
    toDate?: Date;
    page?: number;
    pageNumber?: number;
}

export interface SendEmailDraftRequest {
    authorization: string;
    mailboxfalse: string;
    id: string;
}

/**
 * 
 */
export class EmailApi extends runtime.BaseAPI {

    /**
     */
    async checkEmailRaw(requestParameters: CheckEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<number>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling checkEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/checkEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<number>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async checkEmail(authorization: string, mailbox?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<number> {
        const response = await this.checkEmailRaw({ authorization: authorization, mailbox: mailbox }, initOverrides);
        return await response.value();
    }

    /**
     */
    async clearIssueRaw(requestParameters: ClearIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling clearIssue().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling clearIssue().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling clearIssue().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/clearIssue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async clearIssue(authorization: string, mailbox: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.clearIssueRaw({ authorization: authorization, mailbox: mailbox, id: id }, initOverrides);
    }

    /**
     */
    async getAllEmailsRaw(requestParameters: GetAllEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmailSummary>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getAllEmails().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getAllEmails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['fromDate'] != null) {
            queryParameters['fromDate'] = (requestParameters['fromDate'] as any).toISOString();
        }

        if (requestParameters['toDate'] != null) {
            queryParameters['toDate'] = (requestParameters['toDate'] as any).toISOString();
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageNumber'] != null) {
            queryParameters['pageNumber'] = requestParameters['pageNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/getAllEmails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailSummaryFromJSON));
    }

    /**
     */
    async getAllEmails(authorization: string, mailbox: string, fromDate?: Date, toDate?: Date, page?: number, pageNumber?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmailSummary>> {
        const response = await this.getAllEmailsRaw({ authorization: authorization, mailbox: mailbox, fromDate: fromDate, toDate: toDate, page: page, pageNumber: pageNumber }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailRaw(requestParameters: GetEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Email>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmail().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getEmail().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/getEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => EmailFromJSON(jsonValue));
    }

    /**
     */
    async getEmail(authorization: string, mailbox: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Email> {
        const response = await this.getEmailRaw({ authorization: authorization, mailbox: mailbox, id: id }, initOverrides);
        return await response.value();
    }

    /**
     */
    async getEmailsFilteredRaw(requestParameters: GetEmailsFilteredRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmailSummary>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling getEmailsFiltered().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling getEmailsFiltered().'
            );
        }

        if (requestParameters['emailStatus'] == null) {
            throw new runtime.RequiredError(
                'emailStatus',
                'Required parameter "emailStatus" was null or undefined when calling getEmailsFiltered().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['emailStatus'] != null) {
            queryParameters['emailStatus'] = requestParameters['emailStatus'];
        }

        if (requestParameters['fromDate'] != null) {
            queryParameters['fromDate'] = (requestParameters['fromDate'] as any).toISOString();
        }

        if (requestParameters['toDate'] != null) {
            queryParameters['toDate'] = (requestParameters['toDate'] as any).toISOString();
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageNumber'] != null) {
            queryParameters['pageNumber'] = requestParameters['pageNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/getEmailFiltered`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailSummaryFromJSON));
    }

    /**
     */
    async getEmailsFiltered(authorization: string, mailbox: string, emailStatus: EmailStatus, fromDate?: Date, toDate?: Date, page?: number, pageNumber?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmailSummary>> {
        const response = await this.getEmailsFilteredRaw({ authorization: authorization, mailbox: mailbox, emailStatus: emailStatus, fromDate: fromDate, toDate: toDate, page: page, pageNumber: pageNumber }, initOverrides);
        return await response.value();
    }

    /**
     */
    async ignoreEmailRaw(requestParameters: IgnoreEmailRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling ignoreEmail().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling ignoreEmail().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling ignoreEmail().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/ignoreEmail`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async ignoreEmail(authorization: string, mailbox: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.ignoreEmailRaw({ authorization: authorization, mailbox: mailbox, id: id }, initOverrides);
    }

    /**
     */
    async modifyDraftRaw(requestParameters: ModifyDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling modifyDraft().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling modifyDraft().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling modifyDraft().'
            );
        }

        if (requestParameters['newSubject'] == null) {
            throw new runtime.RequiredError(
                'newSubject',
                'Required parameter "newSubject" was null or undefined when calling modifyDraft().'
            );
        }

        if (requestParameters['newBody'] == null) {
            throw new runtime.RequiredError(
                'newBody',
                'Required parameter "newBody" was null or undefined when calling modifyDraft().'
            );
        }

        if (requestParameters['sendEmail'] == null) {
            throw new runtime.RequiredError(
                'sendEmail',
                'Required parameter "sendEmail" was null or undefined when calling modifyDraft().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['newSubject'] != null) {
            queryParameters['newSubject'] = requestParameters['newSubject'];
        }

        if (requestParameters['newBody'] != null) {
            queryParameters['newBody'] = requestParameters['newBody'];
        }

        if (requestParameters['sendEmail'] != null) {
            queryParameters['sendEmail'] = requestParameters['sendEmail'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/modifyDraft`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async modifyDraft(authorization: string, mailbox: string, id: string, newSubject: string, newBody: string, sendEmail: boolean, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.modifyDraftRaw({ authorization: authorization, mailbox: mailbox, id: id, newSubject: newSubject, newBody: newBody, sendEmail: sendEmail }, initOverrides);
    }

    /**
     */
    async reportIssueRaw(requestParameters: ReportIssueRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling reportIssue().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling reportIssue().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling reportIssue().'
            );
        }

        if (requestParameters['issue'] == null) {
            throw new runtime.RequiredError(
                'issue',
                'Required parameter "issue" was null or undefined when calling reportIssue().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        if (requestParameters['issue'] != null) {
            queryParameters['issue'] = requestParameters['issue'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/reportIssue`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async reportIssue(authorization: string, mailbox: string, id: string, issue: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.reportIssueRaw({ authorization: authorization, mailbox: mailbox, id: id, issue: issue }, initOverrides);
    }

    /**
     */
    async searchEmailsRaw(requestParameters: SearchEmailsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<EmailSummary>>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling searchEmails().'
            );
        }

        if (requestParameters['mailbox'] == null) {
            throw new runtime.RequiredError(
                'mailbox',
                'Required parameter "mailbox" was null or undefined when calling searchEmails().'
            );
        }

        if (requestParameters['searchText'] == null) {
            throw new runtime.RequiredError(
                'searchText',
                'Required parameter "searchText" was null or undefined when calling searchEmails().'
            );
        }

        if (requestParameters['emailStatus'] == null) {
            throw new runtime.RequiredError(
                'emailStatus',
                'Required parameter "emailStatus" was null or undefined when calling searchEmails().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailbox'] != null) {
            queryParameters['mailbox'] = requestParameters['mailbox'];
        }

        if (requestParameters['searchText'] != null) {
            queryParameters['searchText'] = requestParameters['searchText'];
        }

        if (requestParameters['emailStatus'] != null) {
            queryParameters['emailStatus'] = requestParameters['emailStatus'];
        }

        if (requestParameters['fromDate'] != null) {
            queryParameters['fromDate'] = (requestParameters['fromDate'] as any).toISOString();
        }

        if (requestParameters['toDate'] != null) {
            queryParameters['toDate'] = (requestParameters['toDate'] as any).toISOString();
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['pageNumber'] != null) {
            queryParameters['pageNumber'] = requestParameters['pageNumber'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/searchEmails`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(EmailSummaryFromJSON));
    }

    /**
     */
    async searchEmails(authorization: string, mailbox: string, searchText: string, emailStatus: EmailStatus, fromDate?: Date, toDate?: Date, page?: number, pageNumber?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<EmailSummary>> {
        const response = await this.searchEmailsRaw({ authorization: authorization, mailbox: mailbox, searchText: searchText, emailStatus: emailStatus, fromDate: fromDate, toDate: toDate, page: page, pageNumber: pageNumber }, initOverrides);
        return await response.value();
    }

    /**
     */
    async sendEmailDraftRaw(requestParameters: SendEmailDraftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['authorization'] == null) {
            throw new runtime.RequiredError(
                'authorization',
                'Required parameter "authorization" was null or undefined when calling sendEmailDraft().'
            );
        }

        if (requestParameters['mailboxfalse'] == null) {
            throw new runtime.RequiredError(
                'mailboxfalse',
                'Required parameter "mailboxfalse" was null or undefined when calling sendEmailDraft().'
            );
        }

        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling sendEmailDraft().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['mailboxfalse'] != null) {
            queryParameters['mailboxfalse'] = requestParameters['mailboxfalse'];
        }

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters['authorization'] != null) {
            headerParameters['Authorization'] = String(requestParameters['authorization']);
        }

        const response = await this.request({
            path: `/email/sendEmailDraft`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async sendEmailDraft(authorization: string, mailboxfalse: string, id: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.sendEmailDraftRaw({ authorization: authorization, mailboxfalse: mailboxfalse, id: id }, initOverrides);
    }

}
