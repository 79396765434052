/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const TimePeriod = {
    Day: 'Day',
    Week: 'Week',
    Month: 'Month',
    Year: 'Year'
} as const;
export type TimePeriod = typeof TimePeriod[keyof typeof TimePeriod];


export function instanceOfTimePeriod(value: any): boolean {
    for (const key in TimePeriod) {
        if (Object.prototype.hasOwnProperty.call(TimePeriod, key)) {
            if (TimePeriod[key as keyof typeof TimePeriod] === value) {
                return true;
            }
        }
    }
    return false;
}

export function TimePeriodFromJSON(json: any): TimePeriod {
    return TimePeriodFromJSONTyped(json, false);
}

export function TimePeriodFromJSONTyped(json: any, ignoreDiscriminator: boolean): TimePeriod {
    return json as TimePeriod;
}

export function TimePeriodToJSON(value?: TimePeriod | null): any {
    return value as any;
}

