import React from "react";
import { Email } from "../../../services/models/Email";
import { Box, Typography } from "@mui/material";
import EmailMainPart from "../../../components/EmailMainPart";
import "./layers.css";

interface IssueReportedProps {
  emailData: Email;
  onClose: () => void;
}

function IssueReported(props: IssueReportedProps) {
  return (
    <Box className="layers-container">
      <EmailMainPart
        email={props.emailData}
        onClose={props.onClose}
        addedTabTitle="Resolve"
        addedTab={() => {
          return (
            <Box className="bottom-layer">
              <Typography className="bottom-title">Notes</Typography>

              {props.emailData.annotations &&
                props.emailData.annotations.split("\n").map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
            </Box>
          );
        }}
      />
    </Box>
  );
}

export default IssueReported;
