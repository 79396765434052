/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FinishAuthData
 */
export interface FinishAuthData {
    /**
     * 
     * @type {any}
     * @memberof FinishAuthData
     */
    data: any | null;
    /**
     * 
     * @type {string}
     * @memberof FinishAuthData
     */
    mailboxName?: string;
}

/**
 * Check if a given object implements the FinishAuthData interface.
 */
export function instanceOfFinishAuthData(value: object): value is FinishAuthData {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function FinishAuthDataFromJSON(json: any): FinishAuthData {
    return FinishAuthDataFromJSONTyped(json, false);
}

export function FinishAuthDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): FinishAuthData {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'mailboxName': json['mailboxName'] == null ? undefined : json['mailboxName'],
    };
}

export function FinishAuthDataToJSON(value?: FinishAuthData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'mailboxName': value['mailboxName'],
    };
}

