import React from "react";
import { StateManager } from "./StateManager";

export class ObserveState extends React.Component<{
  listenTo: object | null | undefined;
  control: () => JSX.Element | React.ReactNode;
  properties?: string[];
  onWillUnmount?: () => void;
}> {
  render() {
    return this.props.control();
  }

  componentDidMount() {
    if (this.props.listenTo)
      StateManager.addObserver(
        this.props.listenTo,
        this,
        this.props.properties || [],
      );
  }

  componentDidUpdate(prevProps: {
    listenTo: object | null | undefined;
    control: () => JSX.Element | React.ReactNode;
    properties?: string[];
    onWillUnmount?: () => void;
  }) {
    if (prevProps.listenTo !== this.props.listenTo) {
      if (prevProps.listenTo)
        StateManager.removeObserver(prevProps.listenTo, this);

      if (this.props.listenTo)
        StateManager.addObserver(
          this.props.listenTo,
          this,
          this.props.properties || [],
        );
    }
  }

  componentWillUnmount() {
    if (this.props.listenTo)
      StateManager.removeObserver(this.props.listenTo, this);

    if (this.props.onWillUnmount) {
      this.props.onWillUnmount();
    }
  }

  // @AutoBind
  // update() {
  //     this.setState({ key: DataUtilities.generateUniqueId() });
  //     this.forceUpdate();
  // }
}
