import { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Company } from "../services/models";
import { CompanyListItem } from "./company/CompanyListItem";
import "./CompaniesList.css";
import { CompanyModal } from "./company/CompanyModal";
import { Global } from "../Global";
import { Services } from "../Services";

export function CompaniesList(): JSX.Element {
  const [companyModalOpen, setCompanyModalOpen] = useState<boolean>(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [refreshCount, setRefreshCount] = useState<number>(0);
  const [editCompany, setEditCompany] = useState<Company>({
    companyName: "",
    companyLogoURL: "",
    maxEmailsPerUserPerDay: 0,
  });

  const token = Global.getJwtToken();

  useEffect(() => {
    refreshCompanies();
  }, [token]);

  const handleNewCompanyClick = () => {
    setCompanyModalOpen(true);
  };

  const handleCompanyModalClose = () => {
    setCompanyModalOpen(false);
    Services.companyService
      .createCompanyData(Global.getJwtToken()!)
      .then((data) => {
        setEditCompany(data);
      });
    setTimeout(() => {
      Global.resetCompanyList();
    }, 200);
  };

  const handleDeleteCompany = (company: Company) => {
    console.log("delete company", company);
    Services.companyService
      .deleteCompany(Global.getJwtToken()!, company.companyName!)
      .then(() => {
        refreshCompanies();
      });
  };

  const handleEditCompany = (company: Company) => {
    setEditCompany((prevCompany) => ({ ...prevCompany, ...company }));
    setCompanyModalOpen(true);
  };

  return (
    <div>
      <Box className="companies-container">
        <Box className="companies-actions">
          <Button
            variant="contained"
            color="primary"
            className="companies-list__new-company-button"
            onClick={handleNewCompanyClick}
          >
            <Typography className="companies-list__new-company-text">
              New Company
            </Typography>
          </Button>
        </Box>
        <Box className="companies-list">
          {companies.map((company: Company) => (
            <CompanyListItem
              onDelete={handleDeleteCompany}
              openEditModal={handleEditCompany}
              key={company.id}
              company={company}
            />
          ))}
        </Box>
      </Box>
      <Box className="companies-actions">
        <Button
          variant="contained"
          color="secondary"
          className="companies-list__refresh-button"
          onClick={() => setRefreshCount((prev) => prev + 1)}
        >
          <Typography className="companies-list__refresh-text">
            Refresh {refreshCount}
          </Typography>
        </Button>
      </Box>
      <CompanyModal
        company={editCompany}
        open={companyModalOpen}
        onClose={handleCompanyModalClose}
      />
    </div>
  );

  function refreshCompanies() {
    Services.companyService
      .getAllCompanies(Global.getJwtToken()!)
      .then((data) => {
        setCompanies(data);
      });
  }
}
