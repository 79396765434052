import {
  Box,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./EmailMainPart.css";
import { EmailStatus } from "../services/models/EmailStatus";
import { Email } from "../services/models/Email";
import { ReactComponent as CloseIcon } from "../assets/images/close-icon.svg";
import { Services } from "../Services";
import { User } from "../services/models/User";
import { Global } from "../Global";
import { DisplayEmail } from "../pages/DisplayEmail";

interface EmailMainPartProps {
  email: Email;
  onClose: () => void;

  addedTab?: (email: Email) => JSX.Element;
  addedTabTitle?: string;
}

function EmailMainPart(props: EmailMainPartProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const [iconUrl, setIconUrl] = useState<string | null>(
    "email-not-respond-icon.png",
  );
  const [colorVal, setColorVal] = useState<string>("#e2cb00"); // default color
  const [emailStatus, setEmailStatus] = useState<string>("");
  const [user, setUser] = useState<User>({}); // User model from services/models/User.ts
  const [issue, setIssue] = useState<string>("");

  const [reportIssue, setReportIssue] = useState<boolean>(false);

  const [showDetails, setShowDetails] = useState<boolean>(false);

  const [page, setPage] = useState<"incoming" | "reply" | "details" | "added">(
    "incoming",
  );

  useEffect(() => {
    Services.userService
      .getMe(Global.getJwtToken()!)
      .then((user: any) => {
        setUser(user);
      })
      .catch((e: any) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setPage("incoming");
  }, [props.email]);

  useEffect(() => {
    import(`../assets/images/${iconUrl}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [iconUrl]);

  useEffect(() => {
    const emailStatus = props.email.emailStatus;
    switch (emailStatus) {
      case EmailStatus.AutoProcessed:
        setIconUrl("email-auto-respond-icon.png");
        setColorVal("#00A218");
        setEmailStatus("Auto Responded");
        break;
      case EmailStatus.ManualProcessed:
        setIconUrl("email-manual-respond-icon.png");
        setColorVal("#FF9D00");
        setEmailStatus("Manually Responded");
        break;
      case EmailStatus.NotProcessed:
        setIconUrl("email-not-respond-icon.png");
        setColorVal("#e2cb00");
        setEmailStatus("Could Not Respond");
        break;
      case EmailStatus.Ignored:
        setIconUrl("email-ignore-icon.png");
        setColorVal("#FF0004");
        setEmailStatus("Ignored/Deleted");
        break;
      case EmailStatus.IssueReported:
        setIconUrl("email-annotated-icon.png");
        setColorVal("red");
        setEmailStatus("Issue Reported");
        break;
      default:
        setIconUrl("email-not-respond-icon.png");
        setColorVal("#e2cb00");
        setEmailStatus("Could Not Respond");
    }
  }, [props.email.emailStatus]);

  const handleClose = () => {
    setShowDetails(false);
    props.onClose();
  };

  let showTotalValue: boolean =
    props.email.totalValue !== undefined && props.email.totalValue !== 0;
  let showTimeTaken: boolean =
    props.email.timeTakenInSeconds !== undefined &&
    props.email.timeTakenInSeconds !== 0;

  return (
    <Box className="email-main-part">
      <Box
        className="email-main-part-header"
        paddingBottom={"10px"}
        borderBottom={"4px solid #4C4E8D"}
      >
        <Box className="header-user-container">
          <img src={require("../assets/images/avatar.png")} alt="avatar" />
          <Box className="user-information">
            <Typography className="user-name">{`${user.firstName} ${user.lastName}`}</Typography>
            <Typography className="email-inform">
              From: <span>{props.email.inboundEmail!.from?.address}</span> • To:{" "}
              <span>Me</span>
            </Typography>
          </Box>
        </Box>
        <Box className="header-date-container">
          <Typography className="date-text">
            {props.email.emailDate?.toLocaleString()}
          </Typography>
          <CloseIcon className="close-icon" onClick={handleClose} />
        </Box>
      </Box>
      <Box className="email-main-part-type">
        {iconSrc && <img src={iconSrc} alt={props.email.emailStatus}></img>}
        <Typography
          className="email-main-part-title"
          style={{ color: colorVal || "#000000" }} // fallback color
        >
          {emailStatus}
        </Typography>
      </Box>
      <Typography className="email-edit-inform">
        By: <span>{props.email.modifiedBy}</span> • Date:{" "}
        <span>{props.email.modifiedDate?.toLocaleString()}</span>
      </Typography>
      <hr />

      {showTotalValue && (
        <Typography variant="subtitle1" fontWeight={"bold"}>
          Total Value: ${props.email.totalValue!.toLocaleString()}
        </Typography>
      )}

      {showTimeTaken && (
        <Typography variant="subtitle1">
          Time in Seconds:{" "}
          <span>{props.email.timeTakenInSeconds?.toFixed(3)}</span>
        </Typography>
      )}

      {props.email.cannotReplyReason &&
        props.email.cannotReplyReason.length !== 0 && (
          <Box>
            <hr />
            <Typography>Cannot Process Reasons:</Typography>
            <Typography className="bottom-response-content">
              <span>
                {props.email.cannotReplyReason.map((line, index) => (
                  <React.Fragment key={index}>
                    {line}
                    <br />
                  </React.Fragment>
                ))}
              </span>
            </Typography>
          </Box>
        )}

      {props.email.emailStatus === EmailStatus.IssueReported && (
        <Box marginTop={"5px"}>
          <Typography>Reported Issue:</Typography>
          <Box className="bottom-response-content">
            <div>
              <Typography color="red">{props.email.issuesNotes}</Typography>
            </div>
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="center" paddingTop="10px">
        <ToggleButtonGroup
          value={page}
          exclusive
          onChange={(event, newValue) => {
            setPage(newValue);
          }}
          fullWidth
        >
          <ToggleButton
            sx={{
              minWidth: 150,
              border: "none",
              backgroundColor: "lightgray",
              "&.Mui-selected": {
                backgroundColor: "#3A4188", // Custom selected background color
                color: "#fff", // White text when selected
                "&:hover": {
                  backgroundColor: "#3A2178", // Darker blue on hover
                },
              },
            }}
            value="incoming"
          >
            Incoming Mail
          </ToggleButton>
          <ToggleButton
            sx={{
              minWidth: 150,
              border: "none",
              backgroundColor: "#DCDCDC",
              "&.Mui-selected": {
                backgroundColor: "#3A4188", // Custom selected background color
                color: "#fff", // White text when selected
                "&:hover": {
                  backgroundColor: "#3A2178", // Darker blue on hover
                },
              },
            }}
            value="reply"
          >
            Reply Mail
          </ToggleButton>
          <ToggleButton
            sx={{
              minWidth: 150,
              border: "none",
              backgroundColor: "#DCDCDC",
              "&.Mui-selected": {
                backgroundColor: "#3A4188", // Custom selected background color
                color: "#fff", // White text when selected
                "&:hover": {
                  backgroundColor: "#3A2178", // Darker blue on hover
                },
              },
            }}
            value="details"
          >
            Details
          </ToggleButton>
          {props.addedTab && (
            <ToggleButton
              sx={{
                minWidth: 150,
                border: "none",
                backgroundColor: "#DCDCDC",
                marginLeft: "2px",
                "&.Mui-selected": {
                  backgroundColor: "#3A4188", // Custom selected background color
                  color: "#fff", // White text when selected
                  "&:hover": {
                    backgroundColor: "#3A2178", // Darker blue on hover
                  },
                },
              }}
              value="added"
            >
              {props.addedTabTitle ?? "Extra"}
            </ToggleButton>
          )}
        </ToggleButtonGroup>
      </Box>
      {page === "incoming" && (
        <Box height={"500px"} overflow={"auto"} paddingTop={"10px"}>
          <DisplayEmail email={props.email.inboundEmail!} isReply={false} />
        </Box>
      )}

      {page === "reply" && (
        <Box height={"500px"} overflow={"auto"} paddingTop={"10px"}>
          <DisplayEmail email={props.email.reply!} isReply={true} />
        </Box>
      )}

      {page === "details" && (
        <>
          {props.email.debugInfo && (
            <Box
              marginTop={"10px"}
              className="extra-data-container"
              border={"1px solid gray"}
              padding={"10px"}
              height={"500px"}
              overflow={"auto"}
            >
              <Typography variant="subtitle1" fontWeight={"bold"}>
                Details:
              </Typography>
              <hr />
              <pre style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                {JSON.stringify(props.email.debugInfo, null, 2)}
              </pre>
            </Box>
          )}
        </>
      )}

      {props.addedTab && page === "added" && (
        <>{props.addedTab!(props.email)}</>
      )}

      {!reportIssue && (
        <Box display={"flex"} paddingTop={"10px"} justifyContent={"center"}>
          <Box
            marginRight={"10px"}
            className="bottom-button"
            onClick={() => {
              Services.emailAddressRuleService.blockUser(
                Global.getJwtToken()!,
                props.email.inboundEmail!.from!.address!,
              );
              props.onClose();
            }}
          >
            Block User
          </Box>

          {props.email.emailStatus !== EmailStatus.Ignored && (
            <Box
              marginRight={"10px"}
              className="bottom-button"
              onClick={() => {
                Services.emailService.ignoreEmail(
                  Global.getJwtToken()!,
                  Global.getGlobal().CurrentMailbox!,
                  props.email.id,
                );
                props.onClose();
              }}
            >
              Ignore Email
            </Box>
          )}

          {props.email.emailStatus === EmailStatus.IssueReported && (
            <Box
              className="bottom-button"
              onClick={() => {
                Services.emailService.clearIssue(
                  Global.getJwtToken()!,
                  Global.getGlobal().CurrentMailbox!,
                  props.email.id,
                );
                props.onClose();
              }}
            >
              Mark Resolved
            </Box>
          )}

          {props.email.emailStatus !== EmailStatus.IssueReported && (
            <Box
              className="bottom-button"
              onClick={() => {
                setReportIssue(true);
              }}
            >
              Report Issue
            </Box>
          )}

          {props.email.debugInfo && !showDetails && (
            <Box
              className="bottom-button"
              marginLeft={"10px"}
              onClick={() => {
                setShowDetails(true);
              }}
            >
              Show Details
            </Box>
          )}
        </Box>
      )}

      {reportIssue && (
        <Box className="report-issue-container" paddingTop={"10px"}>
          <TextField
            className="report-issue-textfield"
            placeholder="Describe the issue here..."
            multiline
            rows={4}
            value={issue}
            onChange={(e) => setIssue(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Box
            className="report-issue-actions"
            paddingTop={"10px"}
            display={"flex"}
            justifyContent={"right"}
          >
            <Box
              marginLeft={"10px"}
              className="bottom-button"
              onClick={() => {
                // Handle report issue logic here

                Services.emailService.reportIssue(
                  Global.getJwtToken()!,
                  Global.getGlobal().CurrentMailbox!,
                  props.email.id,
                  issue,
                );
                setIssue("");
                setReportIssue(false);
                props.onClose();
              }}
            >
              Report
            </Box>
            <Box
              marginLeft={"10px"}
              className="bottom-button"
              onClick={() => {
                setIssue("");
                setReportIssue(false);
              }}
            >
              Cancel
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default EmailMainPart;
