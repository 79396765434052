import { FC } from "react";
import { EmailAddressRules } from "../../services/models";
import { Box, Button, Typography } from "@mui/material";
import deleteIcon from "../../assets/images/material-symbols_delete.svg";
import editIcon from "../../assets/images/tabler_edit.svg";

export type EmailAddressRuleItemProps = {
  emailAddressRule: EmailAddressRules;
  onDelete: (emailAddressRule: EmailAddressRules) => void;
  openEditModal: (emailAddressRule: EmailAddressRules) => void;
};

export const EmailAddressRulesItem: FC<EmailAddressRuleItemProps> = ({
  emailAddressRule,
  onDelete,
  openEditModal,
}) => {
  return (
    <Box className="blocked-list-item">
      <Box>
        <Typography className="blocked-list__blocked-address">
          {emailAddressRule.addressWithWildcard}
        </Typography>
      </Box>
      <Box>
        <Button
          variant="text"
          className="blocked-list__action-button"
          onClick={() => openEditModal(emailAddressRule)}
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          variant="text"
          className="blocked-list__action-button"
          onClick={() => onDelete(emailAddressRule)}
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </Box>
    </Box>
  );
};
