import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./EmailItem.css";
import { EmailSummary } from "../services/models/EmailSummary";
import { EmailStatus } from "../services/models/EmailStatus";

interface EmailItemProps {
  data: EmailSummary;
  isSelected: boolean;
}

function EmailItem({ data, isSelected }: EmailItemProps) {
  const [iconSrc, setIconSrc] = useState<string | null>(null);
  const [iconUrl, setIconUrl] = useState<string | null>(
    "email-not-respond-icon.png",
  );
  const [colorVal, setColorVal] = useState<string>("#e2cb00"); // default color
  const [emailStatus, setEmailStatus] = useState<string>("");

  useEffect(() => {
    import(`../assets/images/${iconUrl}`)
      .then((module) => setIconSrc(module.default))
      .catch((err) => console.error("Error loading icon:", err));
  }, [iconUrl]);

  useEffect(() => {
    const emailStatus = data.emailStatus;
    switch (emailStatus) {
      case EmailStatus.AutoProcessed:
        setIconUrl("email-auto-respond-icon.png");
        setColorVal("#00A218");
        setEmailStatus("Auto Responded");
        break;
      case EmailStatus.ManualProcessed:
        setIconUrl("email-manual-respond-icon.png");
        setColorVal("#FF9D00");
        setEmailStatus("Manually Responded");
        break;
      case EmailStatus.NotProcessed:
        setIconUrl("email-not-respond-icon.png");
        setColorVal("#e2cb00");
        setEmailStatus("Could Not Respond");
        break;
      case EmailStatus.Ignored:
        setIconUrl("email-ignore-icon.png");
        setColorVal("#FF0004");
        setEmailStatus("Ignored/Deleted");
        break;
      case EmailStatus.IssueReported:
        setIconUrl("email-annotated-icon.png");
        setColorVal("red");
        setEmailStatus("Issue Reported");
        break;
      default:
        setIconUrl("email-not-respond-icon.png");
        setColorVal("#e2cb00");
        setEmailStatus("Could Not Respond");
    }
  }, [data.emailStatus]);

  return (
    <Box
      className="email-list-item"
      bgcolor={isSelected ? "#E5E4E2" : undefined}
      style={{ borderLeftColor: colorVal }}
    >
      <Box className="email-list-item-type">
        {iconSrc && <img src={iconSrc} alt={data.emailStatus}></img>}
        <Typography
          className="email-list-item-title"
          style={{ color: colorVal || "#000000" }} // fallback color
        >
          {emailStatus}
        </Typography>
      </Box>
      <Box className="email-list-item-customer">
        {/* <Typography className="email-list-item-name">
          {data.customer.name}
        </Typography> */}
        <Typography className="email-list-item-email">{data.from}</Typography>
      </Box>
      <Box className="email-list-item-reason">
        {/* {data.reason.specific && (
          <Typography className="email-list-item-reason-specific">
            {data.reason.specific}
          </Typography>
        )} */}
        <Typography className="email-list-item-reason-title">
          {data.subject}
        </Typography>
        <Typography className="email-list-item-reason-text">
          {data.partialBody}
        </Typography>
      </Box>
      <Box className="email-list-item-status">
        <Typography className="email-list-item-status-title">
          {data.emailDate?.toLocaleString("en-US", {
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </Typography>
      </Box>
    </Box>
  );
}

export default EmailItem;
