/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {any}
     * @memberof Company
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyLogoURL?: string;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    maxEmailsPerUserPerDay?: number;
}

/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): value is Company {
    return true;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'companyLogoURL': json['companyLogoURL'] == null ? undefined : json['companyLogoURL'],
        'maxEmailsPerUserPerDay': json['maxEmailsPerUserPerDay'] == null ? undefined : json['maxEmailsPerUserPerDay'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedBy': value['modifiedBy'],
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'companyName': value['companyName'],
        'companyLogoURL': value['companyLogoURL'],
        'maxEmailsPerUserPerDay': value['maxEmailsPerUserPerDay'],
    };
}

