import { Fragment, useEffect, useState } from "react";
import { ReactComponent as DownIcon } from "../assets/images/down-icon.svg";
import "./Header.css";
import { Box, Typography, Menu, MenuItem } from "@mui/material";
import { ReactComponent as Logout } from "../assets/images/log-out.svg";
import { UpdateUserModal } from "../pages/user/UpdateUserModal";
import { Global } from "../Global";
import { User } from "../services/models/User";
import { PortalInfo } from "../services/models/PortalInfo";

function Header() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [updateUserModal, setUpdateUserModal] = useState(false);
  const [modalTitle, setModalTitle] = useState<string>("");
  const [currentlyChanged, setCurrentlyChanged] = useState<
    "password" | "company"
  >("password");

  let token = Global.getJwtToken();

  const [portalInfo, setPortalInfo] = useState<PortalInfo | undefined>(
    undefined,
  );

  useEffect(() => {
    Global.getPortalInfo().then((portalInfo) => {
      setPortalInfo(portalInfo);
    });
  }, [token]);

  const title = Global.getGlobal().getBreadcrumbTitle();

  const handleUserInfo = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleUpdateUserModal = () => {
    setUpdateUserModal(true);
  };

  const handleCloseUpdateUserModal = () => {
    setUpdateUserModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    Global.setJwtToken(undefined);
  };

  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    Global.getUser().then((user) => {
      setUser(user);
    });
  }, [token]);

  return (
    <Fragment>
      <Box className="header-container">
        <Typography sx={{ fontSize: "32px", fontWeight: "bold" }}>
          {title.split(/(?=[A-Z])/).join(" ")}
        </Typography>
        <Box
          width={"250px"}
          className="header-user-container"
          onClick={handleUserInfo}
        >
          <img src={require("../assets/images/avatar.png")} alt="avatar" />
          <div className="user-information">
            <h2>{`${user?.firstName} ${user?.lastName}`}</h2>
            <p>
              {"["}
              {Global.getGlobal().company}
              {"]"}
            </p>
          </div>
          <DownIcon />
        </Box>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        >
          <Typography sx={{ marginLeft: "20px", fontSize: "16px" }}>
            Version: <br /> {portalInfo?.version}
          </Typography>
          <hr />
          <MenuItem
            onClick={() => {
              handleUpdateUserModal();
              setModalTitle("Change Password");
              setCurrentlyChanged("password");
            }}
          >
            <p>Change Password</p>
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleUpdateUserModal();
              setModalTitle("Change Company");
              setCurrentlyChanged("company");
            }}
          >
            <p>Change Company</p>
          </MenuItem>
          <MenuItem onClick={handleLogout}>
            <Logout />
            <p>Log out</p>
          </MenuItem>
        </Menu>
      </Box>
      {user && (
        <UpdateUserModal
          currentlyChanged={currentlyChanged}
          open={updateUserModal}
          onClose={handleCloseUpdateUserModal}
          user={user!}
          title={modalTitle}
        />
      )}
    </Fragment>
  );
}

export default Header;
