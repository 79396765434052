import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/images/Logo.svg";
import { ReactComponent as UserIcon } from "../assets/images/user-icon.svg";
import { ReactComponent as PasswordIcon } from "../assets/images/password-icon.svg";
import "./LoginPage.css";
import AuthLeft from "./AuthLeft";
import { Services } from "../Services";
import { Global } from "../Global";

function LoginPage() {
  const [error, setError] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setPassword(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let userService = Services.userService;

    console.log("Logging in");

    userService
      .login(username, password)
      .then((token: string) => {
        console.log("Setting token");
        Global.setJwtToken(token);
      })
      .catch((e: any) => {
        console.error(e);
        setError("Invalid username or password " + e.message);
        Global.setJwtToken(undefined);
      });
  };

  return (
    <div className="container">
      <div className="container-left">
        <AuthLeft
          title="Welcome!"
          description="Log in to unlock AI-powered and personalization of E-Mails."
        />
      </div>
      <div className="container-right">
        <div className="login-container">
          <Logo className="logo" />
          <h1>Log in</h1>
          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <UserIcon className="input-icon" />
              <input
                type="text"
                placeholder="Type Your User ID Here"
                id="username"
                name="username"
                value={username}
                onChange={handleUsernameChange}
              />
            </div>
            <div className="input-container">
              <PasswordIcon className="input-icon" />
              <input
                type="password"
                placeholder="Type Your Password Here"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
              />
            </div>
            {error && <p className="error">{error}</p>}
            <div className="remember-container">
              <div className="remember-left">
                <input type="checkbox" id="remember" name="remember" />
                <label htmlFor="remember">Keep me logged in</label>
              </div>
              {/* <Link to="/forgot-password">Forgot your password?</Link> */}
            </div>
            <button type="submit">Log in</button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
