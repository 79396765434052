/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { RuleType } from './RuleType';
import {
    RuleTypeFromJSON,
    RuleTypeFromJSONTyped,
    RuleTypeToJSON,
} from './RuleType';

/**
 * 
 * @export
 * @interface EmailAddressRules
 */
export interface EmailAddressRules {
    /**
     * 
     * @type {any}
     * @memberof EmailAddressRules
     */
    id?: any | null;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressRules
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailAddressRules
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressRules
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof EmailAddressRules
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressRules
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressRules
     */
    addressWithWildcard?: string;
    /**
     * 
     * @type {RuleType}
     * @memberof EmailAddressRules
     */
    ruleType?: RuleType;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressRules
     */
    forwardTo?: string;
    /**
     * 
     * @type {number}
     * @memberof EmailAddressRules
     */
    forwardOverLimit?: number;
    /**
     * 
     * @type {string}
     * @memberof EmailAddressRules
     */
    blockedReason?: string;
}



/**
 * Check if a given object implements the EmailAddressRules interface.
 */
export function instanceOfEmailAddressRules(value: object): value is EmailAddressRules {
    return true;
}

export function EmailAddressRulesFromJSON(json: any): EmailAddressRules {
    return EmailAddressRulesFromJSONTyped(json, false);
}

export function EmailAddressRulesFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailAddressRules {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'createdBy': json['createdBy'] == null ? undefined : json['createdBy'],
        'createdDate': json['createdDate'] == null ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': json['modifiedBy'] == null ? undefined : json['modifiedBy'],
        'modifiedDate': json['modifiedDate'] == null ? undefined : (new Date(json['modifiedDate'])),
        'companyName': json['companyName'] == null ? undefined : json['companyName'],
        'addressWithWildcard': json['addressWithWildcard'] == null ? undefined : json['addressWithWildcard'],
        'ruleType': json['ruleType'] == null ? undefined : RuleTypeFromJSON(json['ruleType']),
        'forwardTo': json['forwardTo'] == null ? undefined : json['forwardTo'],
        'forwardOverLimit': json['forwardOverLimit'] == null ? undefined : json['forwardOverLimit'],
        'blockedReason': json['blockedReason'] == null ? undefined : json['blockedReason'],
    };
}

export function EmailAddressRulesToJSON(value?: EmailAddressRules | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'createdBy': value['createdBy'],
        'createdDate': value['createdDate'] == null ? undefined : ((value['createdDate']).toISOString()),
        'modifiedBy': value['modifiedBy'],
        'modifiedDate': value['modifiedDate'] == null ? undefined : ((value['modifiedDate']).toISOString()),
        'companyName': value['companyName'],
        'addressWithWildcard': value['addressWithWildcard'],
        'ruleType': RuleTypeToJSON(value['ruleType']),
        'forwardTo': value['forwardTo'],
        'forwardOverLimit': value['forwardOverLimit'],
        'blockedReason': value['blockedReason'],
    };
}

