/* tslint:disable */
/* eslint-disable */
/**
 * aln-backend
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmailData } from './EmailData';
import {
    EmailDataFromJSON,
    EmailDataFromJSONTyped,
    EmailDataToJSON,
} from './EmailData';

/**
 * 
 * @export
 * @interface EmailParseRequest
 */
export interface EmailParseRequest {
    /**
     * 
     * @type {string}
     * @memberof EmailParseRequest
     */
    mailboxName: string;
    /**
     * 
     * @type {EmailData}
     * @memberof EmailParseRequest
     */
    email: EmailData;
}

/**
 * Check if a given object implements the EmailParseRequest interface.
 */
export function instanceOfEmailParseRequest(value: object): value is EmailParseRequest {
    if (!('mailboxName' in value) || value['mailboxName'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    return true;
}

export function EmailParseRequestFromJSON(json: any): EmailParseRequest {
    return EmailParseRequestFromJSONTyped(json, false);
}

export function EmailParseRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmailParseRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'mailboxName': json['mailboxName'],
        'email': EmailDataFromJSON(json['email']),
    };
}

export function EmailParseRequestToJSON(value?: EmailParseRequest | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'mailboxName': value['mailboxName'],
        'email': EmailDataToJSON(value['email']),
    };
}

