import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import { useState, ChangeEvent, useEffect } from "react";
import { Company } from "../../services/models";
import { Close } from "@mui/icons-material";
import { Services } from "../../Services";
import { Global } from "../../Global";

interface CompanyModalProps {
  open: boolean;
  onClose: () => void;
  company?: Company;
}

export function CompanyModal(props: CompanyModalProps): JSX.Element {
  const [company, setCompany] = useState<Company>({});

  useEffect(() => {
    if (props.company) {
      setCompany(props.company);
    } else {
      Services.companyService
        .createCompanyData(Global.getJwtToken()!)
        .then((data) => {
          setCompany(data);
        });
    }
  }, [props.company]);

  const handleClose = () => {
    setCompany({
      companyName: "",
      companyLogoURL: "",
      maxEmailsPerUserPerDay: 0,
    });
    props.onClose();
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCompany((prevCompany) => ({
      ...prevCompany,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    Services.companyService.saveCompany(Global.getJwtToken()!, company);
    handleClose();
  };

  return (
    <Modal
      open={props.open}
      onClose={handleClose}
      aria-labelledby="company-modal-title"
      aria-describedby="company-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box className="company-modal">
        <Box className="company-modal__header">
          <Typography
            component={"h2"}
            id="company-modal-title"
            className="company-modal__title"
          >
            {props.company ? "Edit Company" : "New Company"}
          </Typography>
          <Button
            variant="text"
            onClick={handleClose}
            className="company-modal__close-button"
          >
            <Close />
          </Button>
        </Box>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="company-modal__form"
        >
          <TextField
            fullWidth
            margin="none"
            variant="filled"
            required
            id="companyName"
            name="companyName"
            label="Company Name"
            placeholder="-"
            value={company.companyName}
            onChange={handleChange}
            className="company-modal__input"
          />
          <TextField
            fullWidth
            margin="none"
            variant="filled"
            required
            id="companyLogoURL"
            name="companyLogoURL"
            label="Company Logo URL"
            placeholder="-"
            value={company.companyLogoURL}
            onChange={handleChange}
            className="company-modal__input"
          />
          <TextField
            fullWidth
            margin="none"
            variant="filled"
            required
            id="maxEmailsPerUserPerDay"
            name="maxEmailsPerUserPerDay"
            label="Max Emails Per User Per Day"
            placeholder="-"
            type="number"
            value={company.maxEmailsPerUserPerDay}
            onChange={handleChange}
            className="company-modal__input"
          />
          <Button
            variant="contained"
            color="primary"
            className="company-modal__submit-button"
            type="submit"
          >
            <Typography className="company-modal__submit-button-text">
              Submit
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
